import { createSlice } from '@reduxjs/toolkit';

const editUserSlice = createSlice({
  name: 'edit',
  initialState : {
    step: 0,
    trigger: 0, 
  },
  reducers: {
    setEditStep: (state, action) => {
      state.step = action.payload;
      state.trigger += 1;
    },
    resetEditStep: (state) => {
      state.step = 1;
      state.trigger += 1;
    }
  }
});

// Actions export
export const { setEditStep,resetEditStep } = editUserSlice.actions;

// Reducer export
export default editUserSlice.reducer;
