
import React, { useRef, useState, useEffect } from "react";
import {
  firebaseApp,
  db,
  auth,
  analytics,
  storage,
  functions,
  collection, 
  query, 
  where, 
  orderBy, 
  doc, 
  getDoc, 
  getDocs
} from './firebaseConfig';

import { useNavigate } from "react-router-dom";
import ChatContent from './ChatContent';
import { getMediaType, isImageURL, isVideoURL, MEDIA_TYPES } from './Utils/FileHelper';

import './css/chatview.css'

export default function ChatMessage({ key, message }) {
  // ImageViewer의 표시 여부를 결정하는 state
  const [isImageViewerVisible, setImageViewerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const { type, content, date, fileUrls, uid, photoURL } = message;


  const currentUserId = auth.currentUser?.uid;
  const currentUserName = auth.currentUser?.displayName;

  let messageClass = uid === currentUserId ? 'sent' : 'received';
  if(type === 'appointments') {
    messageClass = 'appointments';
  }
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetFiles() {
      let i = 0;

      // Get the displayName for the provided uid
      const displayName = await getDisplayNameByUID(uid);

      const newFiles = fileUrls.map(url => {
        const currentFile = {
          index: i,
          fileUrl: url,
          type: getMediaType(url),
          senderName: displayName,  // Use the fetched displayName
          date,
        };

        i++;
        return currentFile;
      });

      setFiles(newFiles);
    }

    fetchAndSetFiles();

  }, [fileUrls, uid]);  // Added uid to dependency array


  async function getDisplayNameByUID(uid) {
    const userDocRef = doc(db, 'users', uid);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
        return docSnapshot.data().name;
    } else {
        console.log("No such document!");
        return null;
    }
}

  const handleFileClick = (url) => {

    const selectedFile = ({
      fileUrl: url,
      type: getMediaType(url),
      senderName: currentUserName,
      date,
    });
    setSelectedFile(selectedFile);
    const idx = files.findIndex(file => file.fileUrl === selectedFile.fileUrl);

    navigate('/imageviewer', { state: { files: files, index: idx } });
  }

  return (
    <div className="chatview">
      <div className={`message ${messageClass}`}>
      {
          type !== 'appointments' &&
          <img src={photoURL || 'https://via.placeholder.com/150'} alt="User Profile" />
      }
        <div className="content-section">
          <div>
            {type === 'appointments' ? (
              <div>
                {content &&
                  <div>
                    <p>약속 시간이 정해졌습니다.서로 매너를 지키며 즐거운 거래 되세요.약속 시간:{content}</p>
                  </div>
                }
              </div>
            ) : (
              <div>{content && <p>{content}</p>}</div>
            )}
          </div>

          <span>{date && date.toDate ? date.toDate().toLocaleTimeString() : ""}</span>
          {fileUrls && fileUrls.length > 0 && (
            <div className="media-previews">
              {fileUrls.map((url, index) => (
                <ChatContent
                  key={index}
                  url={url}
                  isImage={isImageURL(url)}
                  isVideo={isVideoURL(url)}
                  onFileClick={handleFileClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

