import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

function PalletUpdateDialog({ open, onClose, palletId }) {
  const [pallet, setPallet] = useState({
    length: '',
    width: '',
    height: '',
    weightCapacity: '',
    material: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      const palletRef = doc(db, 'pallets', palletId);
      getDoc(palletRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            setPallet(docSnap.data());
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.error('Error fetching document:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [palletId, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPallet((prevPallet) => ({
      ...prevPallet,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const palletRef = doc(db, 'pallets', palletId);
    try {
      await updateDoc(palletRef, { ...pallet });
      onClose(); // Close dialog on successful save
      alert('Pallet updated successfully.');
    } catch (error) {
      console.error('Error updating document:', error);
      alert('Failed to update pallet.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Pallet</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <TextField
              margin="dense"
              label="Length"
              type="text"
              fullWidth
              name="length"
              value={pallet.length}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Width"
              type="text"
              fullWidth
              name="width"
              value={pallet.width}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Height"
              type="text"
              fullWidth
              name="height"
              value={pallet.height}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Weight Capacity"
              type="text"
              fullWidth
              name="weightCapacity"
              value={pallet.weightCapacity}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Material"
              type="text"
              fullWidth
              name="material"
              value={pallet.material}
              onChange={handleChange}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PalletUpdateDialog;
