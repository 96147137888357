import React, { createContext, useContext, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { Button, TextField, Snackbar, Alert, Box } from '@mui/material';

const CustomizationContext = createContext();

export const useCustomization = () => useContext(CustomizationContext);

export const CustomizationProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [logo, setLogo] = useState('');

  const updateTheme = (newTheme) => setTheme(newTheme);
  const updateLogo = (newLogo) => setLogo(newLogo);

  const addNewServiceType = async (serviceName) => {
    try {
      await addDoc(collection(db, 'serviceTypes'), { name: serviceName });
      return { success: true, message: 'New service type added successfully' };
    } catch (error) {
      return { success: false, message: 'Error adding new service type' };
    }
  };

  const value = { theme, logo, updateTheme, updateLogo, addNewServiceType };

  return <CustomizationContext.Provider value={value}>{children}</CustomizationContext.Provider>;
};

export const CustomizationAndScalability = () => {
  const { addNewServiceType } = useCustomization();
  const [serviceName, setServiceName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await addNewServiceType(serviceName);
    setSnackbarMessage(result.message);
    setSnackbarSeverity(result.success ? 'success' : 'error');
    setSnackbarOpen(true);
    if (result.success) setServiceName(''); // 성공 시 입력 필드 초기화
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        label="New Service Type Name"
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Add New Service Type
      </Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};


