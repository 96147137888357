import { useState, useEffect } from "react";
import DaumPostCode from 'react-daum-postcode';
import './css/address.css';

function UserAddress({ user, onUpdate, onAddressSelect }) {
    const initialState = {
        zoneCode: user?.zoneCode || '',
        address: user?.address || '',
        extraAddress: user?.extraAddress || '',
        additionalAddresses: user?.additionalAddresses || []
    };
    
    const [state, setState] = useState(initialState);
    const [selectedAddressType, setSelectedAddressType] = useState('default');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [activePostIdx, setActivePostIdx] = useState(null);
    const [visibleDaumPost, setVisibleDaumPost] = useState(false);

    useEffect(() => {
        setState({
            zoneCode: user?.zoneCode || '',
            address: user?.address || '',
            extraAddress: user?.extraAddress || '',
            additionalAddresses: user?.additionalAddresses || []
        });

        if (user?.address && user?.extraAddress && user?.zoneCode && selectedAddressType !== 'additional') {
            onAddressSelect({
                address: user.address,
                extraAddress: user.extraAddress,
                zoneCode: user.zoneCode
            });
        }
    }, [user]);

    // useEffect(() => {
    //     const changes = {};
    //     if (user?.zoneCode !== state.zoneCode) changes.zoneCode = user?.zoneCode;
    //     if (user?.address !== state.address) changes.address = user?.address;
    //     if (user?.extraAddress !== state.extraAddress) changes.extraAddress = user?.extraAddress;
    //     if (JSON.stringify(user?.additionalAddresses) !== JSON.stringify(state.additionalAddresses)) changes.additionalAddresses = user?.additionalAddresses;

    //     if (Object.keys(changes).length) setState(prevState => ({ ...prevState, ...changes }));

    //     if (user?.address && user?.extraAddress && user?.zoneCode && selectedAddressType !== 'additional') {
    //         onAddressSelect({
    //             address: user.address,
    //             extraAddress: user.extraAddress,
    //             zoneCode: user.zoneCode
    //         });
    //     }
    // }, [user, onAddressSelect]);

    // useEffect(() => {
    //     if(user?.zoneCode !== zoneCode) {
    //        setZoneCode(user?.zoneCode);
    //     }
    //     if(user?.address !== address) {
    //        setAddress(user?.address);
    //     }
    //     if(user?.extraAddress !== extraAddress) {
    //        setExtraAddress(user?.extraAddress);
    //     }
    //     if(JSON.stringify(user?.additionalAddresses) !== JSON.stringify(additionalAddresses)) {
    //        setAdditionalAddresses(user?.additionalAddresses);
    //     }
    //  }, [user]);

    const handleAddressSelection = (type, index) => {
        setSelectedAddressType(type);
        setSelectedAddressIndex(index);
        const selectedAddress = type === 'default' ? { address: state.address, extraAddress: state.extraAddress, zoneCode: state.zoneCode } : state.additionalAddresses[index];
        onAddressSelect(selectedAddress);
    };
    const handleRadioClick = (type, index) => {
        // 이미 선택된 라디오 버튼을 다시 클릭하는 경우, handleAddressSelection 함수를 호출하여 처리
        if ((type === 'default' && selectedAddressType === 'default') ||
            (type === 'additional' && selectedAddressType === 'additional' && index === selectedAddressIndex)) {
            handleAddressSelection(type, index);
        }
    }

    const handleAddAdditionalAddress = () => {
        const addresses = Array.isArray(state.additionalAddresses) ? state.additionalAddresses : [];
        const updatedAddresses = [...addresses, { address: "", extraAddress: "", zoneCode: "" }];
        setState(prevState => ({ ...prevState, additionalAddresses: updatedAddresses }));
        console.log('Updated Addresses:', updatedAddresses); 
    };
    
    const handleUpdateAddress = (index, key, value) => {
        const updatedAddresses = [...state.additionalAddresses];
        updatedAddresses[index][key] = value;
        setState(prevState => ({ ...prevState, additionalAddresses: updatedAddresses }));
    };
    
    const handleSetAsDefault = (index) => {
        const selectedAddress = state.additionalAddresses[index];
    
        const newAdditionalAddresses = [
            ...state.additionalAddresses.slice(0, index),
            {
                address: state.address,
                extraAddress: state.extraAddress,
                zoneCode: state.zoneCode
            },
            ...state.additionalAddresses.slice(index + 1)
        ];
    
        setState({
            ...state,
            address: selectedAddress.address,
            extraAddress: selectedAddress.extraAddress,
            zoneCode: selectedAddress.zoneCode,
            additionalAddresses: newAdditionalAddresses
        });
    
        const updatedUser = {
            ...user,
            address: selectedAddress.address,
            extraAddress: selectedAddress.extraAddress,
            zoneCode: selectedAddress.zoneCode,
            additionalAddresses: newAdditionalAddresses
        };
    
        onUpdate(updatedUser);
    };
    
    const handleSubmit = () => {
        const updatedUser = {
            ...user,
            zoneCode: state.zoneCode,
            address: state.address,
            extraAddress: state.extraAddress,
            additionalAddresses: state.additionalAddresses
        };
        onUpdate(updatedUser);
    };
    
    const handleOpenPost = (idx = null) => {
        setActivePostIdx(idx);
        setVisibleDaumPost(true);
    };
    
    const handleAddress = (data, idx = null) => {
        const { addressType, bname, buildingName, address, zonecode } = data;
        let fullAddress = address;
        let extraAddress = '';
    
        if (addressType === 'R') {
            if (bname) extraAddress += bname;
            if (buildingName) extraAddress += (extraAddress ? `, ${buildingName}` : buildingName);
            fullAddress += (extraAddress ? ` (${extraAddress})` : '');
        }
    
        if (idx === null) { 
            setState({
                ...state,
                address: fullAddress,
                extraAddress: extraAddress,
                zoneCode: zonecode
            });
        } else { 
            const updatedAddresses = [...state.additionalAddresses];
            updatedAddresses[idx] = {
                ...updatedAddresses[idx],
                address: fullAddress,
                extraAddress: extraAddress,
                zoneCode: zonecode
            };
            setState(prevState => ({ ...prevState, additionalAddresses: updatedAddresses }));
        }
    }
    

    return (
        <div className="address-container">
            <h2>기본 배송지</h2>
            <div className="address-card">
    
                <label className={`address-selection ${selectedAddressType === 'default' ? 'selected' : ''}`}>
                    <input
                        type="radio"
                        name="selectedAddress"
                        checked={selectedAddressType === 'default'}
                        onChange={() => handleAddressSelection('default', null)}
                        onClick={() => handleRadioClick('default', null)}
                    />
                    이번 배송 주소로 선택
                </label>
                <div className="cellFirst">
                    <button type="button" onClick={() => handleOpenPost(null)} className="find-postcode-btn">
                        우편번호 찾기
                    </button>
                </div>
    
                {visibleDaumPost && activePostIdx === null &&
                    <DaumPostCode
                        onComplete={(data) => handleAddress(data)}
                        autoClose
                        width="595px"
                        height="450px"
                    />
                }
                <div className="zipCode">우편번호: {state.zoneCode}</div>
                <div className="flex-container">
                    <label>기본 주소: </label>
                    <input className="flex-input" value={state.address} onChange={(e) => setState(prevState => ({ ...prevState, address: e.target.value }))} />
                </div>
                <div className="flex-container">
                    <label>상세 주소: </label>
                    <input className="flex-input" value={state.extraAddress} onChange={(e) => setState(prevState => ({ ...prevState, extraAddress: e.target.value }))} />
                </div>
            </div>
    
            <div className="modalCell">
                <h2>추가 배송지</h2>
                {state.additionalAddresses && state.additionalAddresses.map((addr, index) => (
                    <div key={index} className="address-card">
                        <label className={`address-selection ${selectedAddressType === 'additional' && selectedAddressIndex === index ? 'selected' : ''}`}>
                            <input
                                type="radio"
                                name="selectedAddress"
                                checked={selectedAddressType === 'additional' && selectedAddressIndex === index}
                                onChange={() => handleAddressSelection('additional', index)}
                                onClick={() => handleRadioClick('additional', index)}
                            />
                            이번 배송 주소로 선택
                        </label>
                        <button onClick={() => handleSetAsDefault(index)} className="set-default-btn">기본 주소로 설정</button>
                        <button type="button" onClick={() => handleOpenPost(index)} className="find-postcode-btn">
                            우편번호 찾기
                        </button>
    
                        {visibleDaumPost && activePostIdx === index &&
                            <DaumPostCode
                                onComplete={(data) => handleAddress(data, index)}
                                autoClose
                                width="595px"
                                height="450px"
                            />
                        }
                        <div className="zipCode">우편번호: {addr.zoneCode}</div>
                        <div className="flex-container">
                            <label>기본 주소: </label>
                            <input className="flex-input" value={addr.address} onChange={(e) => handleUpdateAddress(index, 'address', e.target.value)} />
                        </div>
                        <div className="flex-container">
                            <label>상세 주소: </label>
                            <input className="flex-input" value={addr.extraAddress} onChange={(e) => handleUpdateAddress(index, 'extraAddress', e.target.value)} />
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleAddAdditionalAddress} className="add-address-btn">주소 추가</button>
            <button onClick={handleSubmit} className="save-changes-btn">변경 사항 저장</button>
        </div>
    );
    
}

export default UserAddress;
