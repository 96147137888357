import React, { useEffect, useState } from 'react';
import {
  db,
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from '../firebaseConfig';
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Alert,
  Box,
  Button,
  TextField,
} from '@mui/material';
import InventorySorter from './InventorySorter';
import InventoryAnalytics from './InventoryAnalytics';
import InventoryHistory from './InventoryHistory';
import { useGlobalContext } from '../useGlobalContext';

const InventoryDashboard = () => {
  const { tenantId } = useGlobalContext();
  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [filter, setFilter] = useState('all');
  // 제품별 재고 수량을 저장할 상태
  const [stockInputs, setStockInputs] = useState({});
  const [stockUpdateReason, setStockUpdateReason] = useState({});
  const lowStockThreshold = 0;

  useEffect(() => {
    const fetchVendors = async () => {
      const q = query(
        collection(db, 'vendors'),
        where('tenantId', '==', tenantId)
      );
      const vendorsSnapshot = await getDocs(q);
      setVendors(
        vendorsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    if (tenantId) {
      fetchVendors();
    }
  }, [tenantId]);

  useEffect(() => {
    const fetchInventory = async () => {
      // 'products' 컬렉션 참조를 가져옵니다.
      let inventoryQuery = query(
        collection(db, 'products'),
        where('tenantId', '==', tenantId)
      );

      // selectedVendor가 null 또는 빈 문자열이 아닐 때만 벤더에 따라 쿼리를 설정합니다.
      if (selectedVendor) {
        inventoryQuery = query(
          inventoryQuery,
          where('vendorId', '==', selectedVendor)
        );
      }

      try {
        const inventorySnapshot = await getDocs(inventoryQuery);
        const inventoryList = inventorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(inventoryList);
      } catch (error) {
        console.error('Error fetching inventory:', error);
        // 에러 처리 로직
      }
    };

    // 함수를 호출하여 인벤토리 데이터를 가져옵니다.
    fetchInventory();
  }, [selectedVendor, tenantId]); // selectedVendor가 변경될 때마다 useEffect를 다시 실행합니다.

  // 필터링 및 정렬 로직
  const filteredProducts = products.filter((product) => {
    if (filter === 'lowStock') {
      return product.stock <= lowStockThreshold;
    }
    return true;
  });

  const sortedProducts = React.useMemo(() => {
    const filteredByVendor = products.filter((product) => {
      return selectedVendor === '' || product.vendorId === selectedVendor;
    });

    return filteredByVendor.sort((a, b) => {
      if (sortBy === 'name') {
        const nameA = typeof a.name === 'string' ? a.name : '';
        const nameB = typeof b.name === 'string' ? b.name : '';

        return nameA.localeCompare(nameB);
      } else if (sortBy === 'stockAsc') {
        return a.stock - b.stock;
      } else if (sortBy === 'stockDesc') {
        return b.stock - a.stock;
      }
      return 0;
    });
  }, [products, sortBy, selectedVendor]);
  // const sortedProducts = React.useMemo(() => {
  //   return filteredProducts.sort((a, b) => {
  //     if (sortBy === 'name') {
  //       return a.name.localeCompare(b.name);
  //     } else if (sortBy === 'stockAsc') {
  //       return a.stock - b.stock;
  //     } else if (sortBy === 'stockDesc') {
  //       return b.stock - a.stock;
  //     }
  //     return 0;
  //   });
  // }, [filteredProducts, sortBy]);

  const onChangeSelectedVendor = async (e) => {
    e.preventDefault();
    setSelectedVendor(e.target.value);
  };

  const handleStockChange = async (productId, newStock) => {
    setStockInputs((prev) => ({
      ...prev,
      [productId]: newStock, // 특정 제품 ID의 재고 수량 업데이트
    }));
  };
  const handleStockReasonChange = async (productId, reason) => {
    setStockUpdateReason((prev) => ({
      ...prev,
      [productId]: reason,
    }));
  };

  // 재고 수량 업데이트 핸들러

  const handleStockUpdate = async (productId) => {
    const productDocRef = doc(db, 'products', productId);
    const newStock = Number(stockInputs[productId]);
    const reason = stockUpdateReason[productId] || '사유 미제공'; // 기본값 설정

    try {
      await updateDoc(productDocRef, {
        stock: newStock,
      });

      // inventoryHistory에 기록 추가
      const historyCollectionRef = collection(db, 'inventoryHistory');
      await addDoc(historyCollectionRef, {
        productId,
        newStock,
        reason,
        timestamp: serverTimestamp(), // 현재 시간 기록
      });

      alert('재고 수량과 변경 사유가 업데이트되었습니다.');
    } catch (error) {
      console.error('Error updating stock and adding history:', error);
      alert('재고 업데이트 및 이력 추가에 실패했습니다.');
    }
  };

  return (
    <Paper sx={{ margin: '20px', overflow: 'hidden' }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        재고 대시보드
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX={2}
      >
        <InventorySorter sortBy={sortBy} setSortBy={setSortBy} />
        <Select
          value={selectedVendor}
          onChange={(e) => onChangeSelectedVendor(e)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>모든 거래처</em>
          </MenuItem>
          {vendors.map((vendor) => (
            <MenuItem key={vendor.id} value={vendor.id}>
              {vendor.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <InventoryAnalytics
        products={sortedProducts}
        selectedVendor={selectedVendor}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>상품명</TableCell>
              <TableCell align="center">재고 수량</TableCell>
              <TableCell align="center">작업</TableCell>
              <TableCell align="center">히스토리</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProducts.map((product) => (
              <TableRow key={product.id} hover>
                <TableCell>{product.name}</TableCell>
                <TableCell align="right">{product.stock}</TableCell>
                <TableCell align="right">
                  <TextField
                    size="small"
                    type="number"
                    defaultValue={product.stock}
                    onChange={(e) =>
                      handleStockChange(product.id, e.target.value)
                    }
                  />
                  <TextField
                    size="small"
                    label="변경 사유"
                    defaultValue=""
                    onChange={(e) =>
                      handleStockReasonChange(product.id, e.target.value)
                    }
                    style={{ marginLeft: '10px' }}
                  />
                  <Button
                    onClick={() => handleStockUpdate(product.id)}
                    style={{ marginLeft: '10px' }}
                  >
                    업데이트
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <InventoryHistory productId={product.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default InventoryDashboard;
