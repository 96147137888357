import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  db,
  auth,
  storage,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  query,
  orderBy,
  where,
} from './firebaseConfig';

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './css/chatview.css';
import { useParams } from 'react-router-dom';
import ChatHeader from './ChatHeader';
import ChatMessage from './ChatMessage';
import moment from 'moment';
import Modal from 'react-modal';
import ScheduleAppointment from './ScheduleAppointment';
import { RADIUS, CIRCUMFERENCE } from './Common/Constants';
import useMessageBox from './Common/useMessageBox';
import ModalStyles from './Common/ModalStyles';

Modal.setAppElement('#root');

function ChatView({chatRoomId, tenantId}) {
  const [MessageBoxInform, MessageBoxConfirm, MessageBoxRender] = useMessageBox();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const chatBoxRef = useRef();
  const [chatMessages, setChatMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [appointments, setAppointments] = useState([]);
  // const { id: chatRoomId } = useParams();
  // const { chatRoomId } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalFileSize, setTotalFileSize] = useState(uploadingFiles.length);
  const [uploadedFileSize, setUploadedFileSize] = useState(0);



  useEffect(() => {
    const chatroomRef = doc(db, 'chatroom', chatRoomId);
    const messagesQuery = query(
      collection(chatroomRef, 'messages'),
      where("tenantId", "==", tenantId), // Use tenantId in your query
      orderBy('date', 'asc')
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setChatMessages(messagesData);

      const appointmentMessages = messagesData.filter(message => message.type === 'appointments' && message.content !== '');
      const allAppointments = appointmentMessages.map(message => message.content);
      setAppointments(allAppointments);
    });

    return () => unsubscribe();
  }, [chatRoomId, tenantId]); 

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [chatMessages, chatMessages.length]);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!chatRoomId || (!inputValue && !selectedFiles.length)) return;

    const currentUserId = auth.currentUser.uid;
    const photoURL = auth.currentUser.photoURL;
    let fileUrls = [];

    // If there are selected files, upload them and retrieve the URLs
    if (selectedFiles.length > 0) {
        fileUrls = await uploadFiles();
        setFileUrls(fileUrls);
    }

    // Construct the base message object
    const baseMessageData = {
        type: selectedFiles.length > 0 ? "text|image" : "text",
        content: inputValue,
        date: new Date(),
        uid: currentUserId,
        photoURL,
        fileUrls: fileUrls.length ? fileUrls : []  // Only include fileUrls if there are any
    };

    const messagesCollectionRef = collection(doc(db, 'chatroom', chatRoomId), 'messages');
    const messageDataWithTenantId = { ...baseMessageData, tenantId }; // Include tenantId in your message data

    await addDoc(messagesCollectionRef, messageDataWithTenantId);

    resetChatInput();
};


    const resetChatInput = () => {

      setInputValue('');
      setSelectedFiles([]);

      if (chatBoxRef.current) {
        chatBoxRef.current.focus();
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }
    };
    // const uploadSingleFile = async (file) => {
    //   const fileRef = ref(storage, `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`);
    //   const uploadTask = uploadBytesResumable(fileRef, file);

    //   uploadTask.on('state_changed', (snapshot) => {
    //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

    //     // 여기서 전체 업로드된 바이트를 누적합니다.
    //     setUploadedFileSize(prev => prev + snapshot.bytesTransferred);

    //     setUploadingFiles((prev) => {
    //       return prev.map((item) =>
    //         item.file.name === file.name ? { ...item, progress } : item
    //       );
    //     });

    //     setUploadProgress(oldProgress => [...oldProgress, progress]);
    //   });

    //   await new Promise((resolve, reject) => {
    //     uploadTask.then(() => {
    //       // 업로드가 완료된 후에만 완료된 파일 수를 업데이트합니다.
    //       setCompletedCount(prev => prev + 1);
    //       resolve();
    //     }).catch(reject);
    //   });

    //   return await getDownloadURL(fileRef);
    // };

    const uploadSingleFile = async (file) => {
      const fileRef = ref(storage, `${process.env.REACT_APP_CHAT_FILE_PATH}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on('state_changed', (snapshot) => {
        const fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // 업로드 진행률 계산
        setUploadingFiles(prev => {
          return prev.map(item => {
            if (item.file.name === file.name) {
              return {
                ...item,
                progress: fileProgress
              };
            }
            return item;
          });
        });
      });

      await new Promise((resolve, reject) => {
        uploadTask.then(() => {
          setCompletedCount(prev => prev + 1);
          setUploadedFileSize(prev => prev + file.size); // 여기서 전체 업로드된 바이트를 누적합니다.
          resolve();
        }).catch(reject);
      });

      return await getDownloadURL(fileRef);
    };


    const uploadFiles = async () => {
      const urls = [];
      setUploadProgress([]); // 업로드 시작 전에 진행 상황 초기화

      const totalSize = Array.from(selectedFiles).reduce((acc, file) => acc + file.size, 0);
      setTotalFileSize(totalSize);

      for (const selectedFile of selectedFiles) {
        const uploadStatus = {
          file: selectedFile,
          progress: 0,
        };

        setUploadingFiles((prev) => [...prev, uploadStatus]);

        const url = await uploadSingleFile(selectedFile);
        urls.push(url);
      }
      await MessageBoxInform("파일전송이 완료되었습니다.")

      setUploadingFiles([]);
      setSelectedFiles([]);  // 파일들이 모두 업로드되면 상태 초기화
      setUploadProgress([]);  // Reset progress for all files
      setTotalFileSize(0);
      setUploadedFileSize(0);
      setFileUrls([]);
      return urls;
    };

    const handleFilesUpload = async (files) => {

      const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
      setTotalFileSize(totalSize);

      const newFileUrls = [];

      for (let file of files) {
        const uploadStatus = {
          file,
          progress: 0,
        };

        setUploadingFiles((prev) => [...prev, uploadStatus]);

        const fileRef = ref(storage, `${process.env.REACT_APP_CHAT_FILE_PATH}/${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setUploadingFiles((prev) => {
            setCompletedCount(prev => prev + 1);
            return prev.map((item) =>
              item.file.name === file.name ? { ...item, progress } : item
            );
          });
        });

        await uploadTask;

        const fileURL = await getDownloadURL(fileRef);
        newFileUrls.push(fileURL);

        setUploadingFiles((prev) => prev.filter((item) => item.file.name !== file.name));

        if (newFileUrls.length === files.length) {
          const messageData = {
            content: '',
            fileUrls: newFileUrls,
            date: new Date(),
            uid: auth.currentUser.uid,
          };

          const messagesCollectionRef = collection(db, 'chatroom', chatRoomId, 'messages');
          await addDoc(messagesCollectionRef, messageData);

          setFileUrls([]);
        } else {
          setFileUrls(prev => [...prev, ...newFileUrls]);
        }
      }
    };

    const onScheduleClick = async () => {
      openModal();
    }


    const onPhoneCallClick = () => {
      MessageBoxInform("구현중입니다.")
    }

    const updateProductState = async (productId, currentUserId, stateValue) => {
      const productRef = doc(collection(db, 'product'), productId);

      try {
        const docSnapshot = await getDoc(productRef);

        if (docSnapshot.exists()) {
          await updateDoc(productRef, {
            state: stateValue,
            endUsers: { give: docSnapshot.data().uid, take: currentUserId }
          });
          console.log("updateProductState successfully updated!");
        } else {
          console.log("updateProductState : No such document!");
        }
      } catch (error) {
        console.error("Error updating updateProductState: ", error);
      }
    }
    // 2. promise 로 처리하는 방법
    // const onWorkDoneClick = () => {
    //   updateProductState(productId, currentUserId, 2)
    //     .then(() => {
    //       alert("거래완료 되었습니다.");
    //     })
    //     .catch((error) => {
    //       console.error("Error updating document: ", error);
    //     });
    // };

    // const onWorkDoneClick = () => {
    //   updateProductState(productId, currentUserId, 2, () => {
    //     alert("거래완료 되었습니다.");
    //   });
    // };

    // 3. callback 으로 하는 방법
    // const updateProductState = async (productId, currentUserId, stateValue, callback) => {
    //   const productRef = collection(db,'product').doc(productId);

    //   try {
    //     const doc = await productRef.get();
    //     if (doc.exists) {
    //       await productRef.update({
    //         state: stateValue,
    //         endUsers: { give: doc.data().uid, take: currentUserId }
    //       });
    //       if (callback) callback();
    //     } else {
    //       console.log("No such document!");
    //     }
    //   } catch (error) {
    //     console.error("Error updating document: ", error);
    //   }
    // };
    const openModal = () => {
      setModalIsOpen(true);
    };

    const closeModal = () => {
      setModalIsOpen(false);
    };

    const handleAppointmentSet = async (appointments) => {

      const formatDateAndTime = (date) => {
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hour = ("0" + date.getHours()).slice(-2);
        const minute = ("0" + date.getMinutes()).slice(-2);

        return `${year}-${month}-${day} ${hour}:${minute}`;
      };


      const selectedDateTime = new Date(`${appointments.date}T${appointments.time}`);
      const formattedDateTime = formatDateAndTime(selectedDateTime);

      const messageData = {
        type: "appointments",
        content: formattedDateTime || "",
        date: new Date(),
        uid: currentUser.uid,
        fileUrls: fileUrls,
        photoURL: "",
        appointments: appointments
      };

      await addDoc(collection(db, 'chatroom', chatRoomId, 'messages'), messageData);
      resetChatInput();
      //setAppointments([...appointments, formattedDateTime]);
      setChatMessages([...chatMessages, messageData]);
      closeModal();
    };

    const removeFileFromPreview = (indexToRemove) => {
      const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
      setSelectedFiles(updatedFiles);
    };
    const clearAllPreviews = () => {
      setSelectedFiles([]);
    };

    return (
      <div className="chatview">
        <div className="container">

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Schedule Appointment"
            style={ModalStyles}
          >
            <ScheduleAppointment onAppointmentSet={handleAppointmentSet} onClose={closeModal} />
          </Modal>

          <ChatHeader
            isCurrentUserOwner={currentUser?.uid === chatRoomId}
            imageUrl={''}
            title={''}
            state={''}
            onScheduleClick={onScheduleClick}
            onPhoneCallClick={onPhoneCallClick}
            appointments={appointments.length > 0 ? appointments[appointments.length - 1] : null} // pass the last element
          />



          <div className="chat-box" ref={chatBoxRef}>
            {chatMessages && chatMessages.map((msg, index) =>
              <ChatMessage key={index} message={msg} />)}
          </div>

          {
            selectedFiles && selectedFiles.length > 0 &&
            <div className="file-previews">
              <button className="close-all-button" onClick={clearAllPreviews}>X</button>
              {selectedFiles.map((file, index) => (
                <div key={index} className="file-preview">
                  {file.type.startsWith('image/') ? (
                    <img className="file-preview" src={URL.createObjectURL(file)} alt="File Preview" />
                  ) : (
                    <div className="file-preview">📄</div>  // 이 부분을 원하는 아이콘 또는 텍스트로 교체할 수 있습니다.
                  )}
                  <button className="file-preview" onClick={() => removeFileFromPreview(index)}>x</button>
                </div>
              ))}
            </div>
          }
          {MessageBoxRender()}
          <form onSubmit={sendMessage}>
            <button type="button" onClick={() => document.getElementById("fileInput").click()}>+</button>
            <input
              id="fileInput"
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={e => setSelectedFiles([...e.target.files])}
            />

            <input
              type="text"
              value={inputValue}
              onChange={e => {
                if (e.target.value.length <= process.env.REACT_APP_SEND_TEXT_MAX_LEN) {
                  setInputValue(e.target.value);
                } else {
                  MessageBoxInform(`메시지는 ${process.env.REACT_APP_SEND_TEXT_MAX_LEN} 자를 초과할 수 없습니다!`);
                }
              }}
              placeholder="메시지 입력"
            />
            <button type="submit" disabled={!inputValue && selectedFiles.length === 0}>보내기</button>
          </form>

          {uploadingFiles && uploadingFiles.length > 0 && (
            <div className="progress-container">
              {/* 전체 진행률 표시 */}
              <div className="circular-progress">
                <svg viewBox="0 0 100 100">
                  <circle className="circle-bg" cx="50" cy="50" r={RADIUS}></circle>
                  <circle
                    className="circle"
                    cx="50"
                    cy="50"
                    r={RADIUS}
                    strokeDasharray={`${(uploadedFileSize / totalFileSize) * CIRCUMFERENCE}, ${CIRCUMFERENCE}`}
                    strokeDashoffset={`${CIRCUMFERENCE - (uploadedFileSize / totalFileSize) * CIRCUMFERENCE}`}
                  ></circle>
                </svg>
                {/* <div className="progress-text">{`${uploadedFileSize}/${totalFileSize} bytes`}</div> */}
                <div className="progress-text">{`${Math.round((uploadedFileSize / totalFileSize) * 100)}%`}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }


  export default ChatView;


