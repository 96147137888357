import React, { Component } from 'react';
import DaumPostCode from 'react-daum-postcode';

class AddressModal extends Component {
  state = {
    name: "",
    phone: "",
    address: "",
    zoneCode: "",
    fullAddress: "",
    isDaumPost: false,
    isRegister: false,
    register: [],
  }

  handleOpenPost = () => {
    this.setState({ isDaumPost: true });
  }

  handleAddress = (data) => {
    const { addressType, bname, buildingName, address, zonecode } = data;
    
    let fullAddress = address;
    let extraAddress = '';
    if (addressType === 'R') {
      if (bname) {
        extraAddress += bname;
      }
      if (buildingName) {
        extraAddress += (extraAddress ? `, ${buildingName}` : buildingName);
      }
      fullAddress += (extraAddress ? ` (${extraAddress})` : '');
    }

    this.setState({
      fullAddress: fullAddress,
      zoneCode: zonecode
    });
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { isModalShow, isModalClose } = this.props;
    const { name, phone, address, isDaumPost, fullAddress, zoneCode } = this.state;
    
    const modalStyle = {
      position: "absolute",
      top: "100px",
      left: "10px",
      zIndex: "100",
      border: "1px solid #000000",
      overflow: "hidden"
    }

    return (
      <div className="modalRow">
        <div className="modalCell cellTit">
          <div>
            <span><b>*</b>주소</span>
          </div>
        </div>
        <div className="modalCell">
          <div className="cellFirst">
            <div className="zipCode">{zoneCode}</div>
            <button type="button" onClick={this.handleOpenPost}>
              <span>우편번호 찾기</span>
            </button>
          </div>
          {isDaumPost &&
            <DaumPostCode
              onComplete={this.handleAddress}
              autoClose
              width="595px"
              height="450px"
              
            />
          }
          <div className="address">{fullAddress}</div>
          <div className="addressBox">
            <input type="text" value={address} name="address" onChange={this.handleInput} />
          </div>
        </div>
      </div>
    );
  }
}

export default AddressModal;
