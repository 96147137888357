import React, { useState } from 'react';

import {
  db,
  auth,
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  functions,
} from './firebaseConfig';

import {
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  deleteField,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';

import BusinessInfoVerification from './SignUp/BusinessInfoVerification';
import PhoneNumberVerificationFirebase from './SignUp/PhoneNumberVerificationFirebase';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useGlobalContext } from './useGlobalContext';
import { ToastContainer, toast } from 'react-toastify';
import PhoneNumberVerificationTwilio from './SignUp/PhoneNumberVerificationTwilio';
// import 'react-toastify/dist/ReactToastify.css';

const Join = () => {
  const { sidebarWidth } = useGlobalContext();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [userType, setUserType] = useState(null);
  const [role, setRole] = useState('');
  const [businessNumber, setBusinessNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [isBusinessVerified, setIsBusinessVerified] = useState(false);
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTenantIdCreation = (businessNumber, businessName) => {
    // 사업자 정보를 바탕으로 tenantId 생성 로직 구현
    // 예제에서는 단순화를 위해 임시로 생성

    const newTenantId = `tenant_${businessNumber}_${Date.now()}`;
    setTenantId(newTenantId);
    setBusinessNumber(businessNumber);
    setBusinessName(businessName);
    toast.success('Tenant ID가 생성되었습니다.');
    handleCloseDialog();
  };
  // Utility function to handle errors
  const handleError = (errorMessage, logMessage) => {
    const message = logMessage
      ? `${errorMessage}, ${logMessage}`
      : errorMessage;
    setError(message);
    console.error(message);
  };
  function validatePassword(password) {
    const minLength = 7;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[@#$%&]/.test(password);

    if (
      password.length < minLength ||
      // || !hasUpperCase
      !hasLowerCase ||
      !hasNumbers
      // || !hasSpecialChars
    ) {
      return false;
    }

    return true;
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const handleRegister = async () => {
    console.log('handleRegister called');
    setLoading(true);
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }
    if (!validatePassword(password)) {
      setError(
        '비밀번호가 조건을 충족하지 않습니다. 비밀번호는 8자 이상이어야 하며, 대문자, 소문자, 숫자, 특수 문자(@#$%&)를 포함해야 합니다.'
      );
      setLoading(false);
      return;
    }
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(user);
      setIsVerificationSent(true);
      toast.success('Verification email sent. Please verify to continue.');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        // Assume we have a users collection where each document has an email field
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', email));
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            // Found user with the email, now check their step
            const userData = querySnapshot.docs[0].data(); // Assuming email is unique and only fetches one doc

            if (userData.status === 'active') {
              handleError('이미 가입된 유저입니다.');
            } else {
              handleError('해당 계정으로 가입중인 기록이 있습니다.');
              const userResponse = window.confirm(
                '해당 계정으로 가입을 이어서 진행할까요?'
              );
              if (userResponse) {
                handleLogin(); // Proceed with login or account upgrade
              }
            }
          } else {
            // No user found with this email, handle accordingly
            handleError(
              '가입이 완료되지 않았습니다. 기존 정보는 삭제됩니다. 처음부터 다시시작해주세요.'
            );
            const currentUser = auth.currentUser;
            if (currentUser) {
              // 현재 로그인된 사용자가 있고, 사용자 삭제를 진행
              //await deleteUser(currentUser.uid);
            }
          }
        } catch (error) {
          handleError("Error checking user's account type:", error);
        }
      } else if (error.code === 'auth/weak-password') {
        handleError('비밀번호가 너무 약합니다.');
      } else if (error.code === 'auth/wrong-password') {
        handleError('잘못된 비밀번호입니다.');
      } else if (error.code === 'auth/user-not-found') {
        handleError('사용자를 찾을 수 없습니다.');
      } else if (error.code === 'auth/invalid-email') {
        handleError('잘못된 이메일 형식입니다.');
      } else if (error.code === 'auth/too-many-requests') {
        handleError(
          '너무 많은 요청이 감지되었습니다. 나중에 다시 시도해주세요.'
        );
      } else {
        handleError('회원가입 중에 오류가 발생했습니다: ', error.message);
      }
    }
    setLoading(false);
  };

  // Cloud Function 호출을 위한 함수
  async function deleteUser(userUid) {
    try {
      const accountHandler = httpsCallable(functions, 'accountHandler');
      const result = await accountHandler({ uid: userUid });

      // 함수 호출 성공
      console.log(result.data.message);
    } catch (error) {
      // 함수 호출 실패
      console.error('Error:', error.message);
    }
  }
  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setIsVerificationSent(true);
      handleError(
        '인증메일을 보냈습니다. 이메일을 확인하고 해당 링크를 클릭해 주세요..'
      );
      //toast.success('Verification email sent. Please verify to continue.');
    } catch (error) {
      if (error.code === 'auth/too-many-requests') {
        // 특정 오류에 대한 사용자 친화적인 메시지 처리
        handleError('잠시만 기다린 후 다시 시도해 주세요.');
      } else {
        // 일반 오류 메시지 처리
        handleError('인증메일을 보내지 못했습니다 : ', error.message);
      }
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleVerificationComplete();
    } catch (error) {
      handleError('Failed to login.', error.message);
    }
  };

  const handleVerificationComplete = async () => {
    try {
      const user = auth.currentUser;
      await user.reload();
      if (user.emailVerified) {
        const userInfo = {
          email: user.email,
          name: name,
          tenantId: tenantId,
          userType: userType,
          role: role,
          step: 'signupCompleted',
          status: process.env.REACT_APP_CREATE_ACCOUNT_VERIFIED,
        };
        await upsertUserData(user, userInfo);
        await updatePendingRegistrations(user, userInfo);
        await updateProfile(user, { displayName: name });
        toast.success('Email verified successfully.');
        handleError('이메일 인증이 완료되었습니다. 가입이 완료되었습니다.');
        await signUpComplete();
        const userResponse = window.confirm(
          '가입이 완료되었습니다. 로그인 화면으로 이동합니다.'
        );
        if (userResponse) {
          navigate('/login');
        }
        // onNext(userInfo);
      } else {
        await sendVerificationEmail();
      }
    } catch (error) {
      // user-token-expired 예외 처리 추가
      if (
        error.code === 'auth/token-expired' ||
        error.message.includes('user-token-expired')
      ) {
        // 사용자에게 로그인이 필요함을 알리고, 로그인 화면으로 이동
        toast.error('세션이 만료되었습니다. 다시 로그인 해주세요.');
        navigate('/login');
      } else {
        const user = auth.currentUser;
        if (user && !user.emailVerified) {
          // 여기에 이메일 미인증 사용자 처리 로직 추가
        }
        // 다른 오류 처리
        handleError('인증 과정 중 오류가 발생했습니다.', error.message);
        throw error;
      }
    }
  };

  const upsertUserData = async (user, userInfo) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        {
          ...userInfo,
          tenants: arrayUnion(userInfo.tenantId),
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
        { merge: true }
      );
    } catch (error) {
      // handleError('Failed to update user data.', error.message);
      throw error;
    }
  };

  const updatePendingRegistrations = async (user, userInfo) => {
    try {
      // Check if userInfo.step is undefined and set a default value or handle accordingly
      if (userInfo.step === undefined) {
        console.error('userInfo.step is undefined');
        return; // Exit the function or handle the case as needed
        // Alternatively, set a default value for userInfo.step
        // userInfo.step = yourDefaultValueHere;
      }

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        pendingRegistrations: {
          ...userInfo,
          step: userInfo.step, // This should now be guaranteed to not be undefined
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
      });
    } catch (error) {
      throw error;
      // handleError('Failed to update pending registrations.', error.message);
    }
  };
  const signUpComplete = async () => {
    // setIsLoading(true);
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const { pendingRegistrations } = userDoc.data();
        if (pendingRegistrations) {
          await updateDoc(userRef, {
            status: 'active',
            'pendingRegistrations.step': 'signupCompleted',
          });
          // pendingRegistrations 필드 삭제
          await deletePendingRegistrations(currentUser.uid);
          //'stores' 에 저장
          // await saveStoreUser({
          //   ...pendingRegistrations,
          // });
          await saveTenants({ tenantId, name });
        }
      }
    } catch (error) {
      console.error('Error fetching and updating user:', error);
    } finally {
      // setIsLoading(false);
    }
  };
  const saveTenants = async (tenants) => {
    try {
      const userStoreRef = doc(db, 'tenants', currentUser.uid);
      await setDoc(
        userStoreRef,
        { ...tenants, storeId: currentUser.uid },
        { merge: true }
      );
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };

  const saveStoreUser = async (pendingData) => {
    try {
      const userStoreRef = doc(db, 'stores', currentUser.uid);
      await setDoc(
        userStoreRef,
        { ...pendingData, storeId: currentUser.uid },
        { merge: true }
      );
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const deletePendingRegistrations = async (userUid) => {
    const userRef = doc(db, 'users', userUid);

    try {
      await updateDoc(userRef, {
        pendingRegistrations: deleteField(),
      });
      console.log('All pendingRegistrations documents deleted successfully');
    } catch (error) {
      console.error('Error deleting pendingRegistrations documents:', error);
    }
  };
  const handleBusinessVerificationResult = (isValid, data) => {
    if (isValid) {
      // 검증 성공: 사용자에게 성공 메시지 표시 및 추가 로직 처리
      console.log('검증 성공:', data);
      setIsBusinessVerified(true);
      setSuccess('사업자번호가 인증되었습니다.');
      handleTenantIdCreation(data.data[0].b_no, '');
      // 예: 성공적으로 검증된 사업자 번호와 이름을 상태에 저장
    } else {
      // 검증 실패: 사용자에게 오류 메시지 표시
      console.error('검증 실패:', data.error);
      setIsBusinessVerified(false);
      setError('사업자번호를 확인할 수 없습니다.');
    }
  };
  const handlePhoneNumberVerificationResult = (type, isValid, data) => {
    if (type === 1) {
      if (isValid) {
        // 검증 성공: 사용자에게 성공 메시지 표시 및 추가 로직 처리
        console.log('전화번호 인증코드 전송 성공:', data);
        setSuccess('입력한 번호로 인증번호가 전송되었습니다.');
      } else {
        // 검증 실패: 사용자에게 오류 메시지 표시
        console.error('전화번호 인증코드 전송 실패:', data);

        setSuccess('입력한 번호로 인증번호가 전송되지 못했습니다.');
      }
    } else if (type === 2) {
      if (isValid) {
        // 검증 성공: 사용자에게 성공 메시지 표시 및 추가 로직 처리
        console.log('전화번호 인증완료:', data);
        setIsPhoneNumberVerified(true);
        setSuccess('전화번호가 인증되었습니다.');
      } else {
        // 검증 실패: 사용자에게 오류 메시지 표시
        console.error('전화번호 인증실패:', data);
        setIsPhoneNumberVerified(false); // 검증 실패 처리
        setSuccess('입력한 번호로 인증 실패하였습니다.');
      }
    }
  };

  // TenantId 생성 다이얼로그 컴포넌트
  const TenantIdCreationDialog = ({ open, onClose, onCreate }) => {
    const [businessNumber, setBusinessNumber] = useState('');
    const [businessName, setBusinessName] = useState('');

    const handleCreateClick = () => {
      onCreate(businessNumber, businessName);
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Tenant ID 생성</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="사업자등록번호"
            type="text"
            fullWidth
            variant="outlined"
            value={businessNumber}
            onChange={(e) => setBusinessNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="사업자명"
            type="text"
            fullWidth
            variant="outlined"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          {/* 추가 정보 필드 구현 */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>취소</Button>
          <Button onClick={handleCreateClick}>인증 및 생성</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const isRegisterButtonEnabled = isBusinessVerified && isPhoneNumberVerified;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          ml: `${sidebarWidth}px`, // sidebarWidth 만큼 왼쪽 마진을 추가
          overflow: 'auto',
        }}
      >
        <Container component="main" maxWidth="xs">
          <Typography variant="h4" gutterBottom>
            회원가입
          </Typography>
          <ToastContainer />
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
            sx={{ mt: 1 }}
          >
            {/* <TextField
                label="Tenant ID"
                value={tenantId}
                onChange={(e) => setTenantId(e.target.value)}
                required
            /> */}
            <BusinessInfoVerification
              onVerificationResult={handleBusinessVerificationResult}
            />
            {/* <PhoneNumberVerificationFirebase
              onVerificationResult={handlePhoneNumberVerificationResult}
            /> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="usertype-select-label">
                    사업자 유형
                  </InputLabel>
                  <Select
                    labelId="usertype-select-label"
                    id="usertype-select"
                    value={userType}
                    label="사업자 유형"
                    onChange={handleUserTypeChange}
                  >
                    <MenuItem value="0">관리자</MenuItem>
                    <MenuItem value="1">판매자</MenuItem>
                    <MenuItem value="2">물류사</MenuItem>
                    <MenuItem value="3">화주사</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="role-select-label">역할</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={role}
                    label="역할"
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="0">주관리자</MenuItem>
                    <MenuItem value="1">서브관리자</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Tenant ID"
              placeholder="Enter your Tenant ID"
              value={tenantId}
              onChange={(e) => setTenantId(e.target.value)}
              required
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              placeholder="Enter your password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Confirm Password"
              placeholder="Confirm your password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <PhoneNumberVerificationTwilio
              onVerificationResult={handlePhoneNumberVerificationResult}
            />
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleRegister}
              disabled={!isRegisterButtonEnabled}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : '가입하기'}
            </Button>
            {isVerificationSent && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleVerificationComplete}
                sx={{ mt: 2 }}
              >
                인증 완료
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Join;
