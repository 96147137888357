import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  deleteDoc,
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const ProductList = ({ onSelect }) => {
  const { tenantId } = useGlobalContext();
  const [vendors, setVendors] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState('');
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Adjust this function to include tenantId in the query
    const fetchCategories = async () => {
      const q = query(
        collection(db, 'categories'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      setCategories(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchCategories();
  }, [tenantId]);

  useEffect(() => {
    const fetchVendors = async () => {
      const q = query(
        collection(db, 'vendors'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      setVendors(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchVendors();
  }, [tenantId]);

  useEffect(() => {
    // Adjust the query to include tenantId for filtering products
    const q = query(
      collection(db, 'products'),
      where('tenantId', '==', tenantId) // Filter by tenantId
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const itemsWithVendorName = await Promise.all(
        snapshot.docs.map(async (product) => {
          const productData = product.data();
          // Ensure that the vendor belongs to the same tenant if necessary
          // This might require adjusting your vendor data model to include tenantId
          const vendorRef = doc(db, 'vendors', productData.vendorId);
          const vendorSnap = await getDoc(vendorRef);
          const vendorName =
            vendorSnap.exists() && vendorSnap.data().tenantId === tenantId
              ? vendorSnap.data().name
              : 'Unknown Vendor';
          return {
            id: product.id, // Ensure this uses product.id, not doc.id
            ...productData,
            vendorName, // Add vendor name to the product details
          };
        })
      );
      setProducts(itemsWithVendorName);
    });

    return () => unsubscribe();
  }, [tenantId]); // Dependency array includes tenantId to re-run the effect when it changes

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let q = selectedVendorId
          ? query(
              collection(db, 'products'),
              where('vendorId', '==', selectedVendorId),
              where('tenantId', '==', tenantId)
            )
          : query(
              collection(db, 'products'),
              where('tenantId', '==', tenantId)
            );

        const querySnapshot = await getDocs(q);
        const vendorIds = [
          ...new Set(querySnapshot.docs.map((doc) => doc.data().vendorId)),
        ];

        // 비동기로 여러 문서를 가져오는 과정에서 발생할 수 있는 예외 처리

        const vendorPromises = vendorIds
          .filter((id) => id)
          .map((id) => getDoc(doc(db, 'vendors', id)));

        const vendorDocs = await Promise.all(vendorPromises);
        const vendors = vendorDocs.reduce((acc, docSnap) => {
          if (docSnap.exists()) {
            const vendorData = docSnap.data();
            acc[docSnap.id] = vendorData.name;
          }
          return acc;
        }, {});

        const productsData = querySnapshot.docs.map((doc) => {
          const product = doc.data();
          const categoryName =
            categories.find((category) => category.id === product.categoryId)
              ?.name || 'Unknown Category';
          const vendorName = vendors[product.vendorId] || 'Unknown Vendor';
          return { ...product, categoryName, vendorName, id: doc.id };
        });

        setProducts(productsData);
      } catch (error) {
        console.error('Failed to fetch products:', error);
        // 여기서 사용자에게 오류 메시지를 보여주는 등의 처리를 할 수 있습니다.
        // 예: setError("Failed to load products.");
      }
    };

    if (categories.length > 0) {
      fetchProducts();
    }
  }, [selectedVendorId, categories, tenantId]); // 의존성 배열에 포함된 상태가 변경될 때마다 useEffect 내부의 로직이 실행됩니다.
  // categories가 외부 상태나 prop으로 주어진 경우 의존성 배열에 추가

  // 모든 상품에서 고유한 속성 키들을 추출
  const attributeKeys = products.reduce((acc, product) => {
    const productAttributes = product.attributes || [];
    productAttributes.forEach((attr) => {
      if (!acc.includes(attr.key)) acc.push(attr.key);
    });
    return acc;
  }, []);

  const handleVendorChange = (event) => {
    setSelectedVendorId(event.target.value);
  };
  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(db, 'products', productId));
        setProducts(products.filter((product) => product.id !== productId));
        alert('Product deleted successfully.');
      } catch (error) {
        console.error('Error deleting product: ', error);
        alert('Failed to delete the product.');
      }
    }
  };
  return (
    <Paper sx={{ margin: '20px', overflow: 'hidden' }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        상품 목록
      </Typography>

      <FormControl fullWidth sx={{ margin: '20px' }}>
        <InputLabel id="vendor-select-label">거래처 선택</InputLabel>
        <Select
          labelId="vendor-select-label"
          value={selectedVendorId}
          label="거래처 선택"
          onChange={handleVendorChange}
        >
          <MenuItem value="">모든 거래처</MenuItem>
          {vendors.map((vendor) => (
            <MenuItem key={vendor.id} value={vendor.id}>
              {vendor.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>거래처 ID</TableCell>
              <TableCell>거래처 이름</TableCell>
              <TableCell>상품 코드</TableCell>
              <TableCell>상품명</TableCell>
              <TableCell>카테고리</TableCell>
              <TableCell>Attributes</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .filter(
                (product) =>
                  selectedVendorId === '' ||
                  product.vendorId === selectedVendorId
              )
              .map((product) => (
                <TableRow key={product.id} hover>
                  <TableCell>{product.vendorId}</TableCell>
                  <TableCell>{product.vendorName}</TableCell>
                  <TableCell>{product.productCode}</TableCell>
                  <TableCell>{product.productName}</TableCell>
                  <TableCell>{product.categoryName}</TableCell>
                  <TableCell>
                    {product.attributes.map((attr, index) => (
                      <div key={index}>
                        {attr.key}: {attr.value}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering row click
                        onSelect(product);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering row click
                        handleDelete(product.id);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductList;

// const ProductList = () => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       const productsCollectionRef = collection(db, 'products');
//       const productsSnapshot = await getDocs(productsCollectionRef);
//       const productsList = productsSnapshot.docs.map(doc => {
//         const data = doc.data();
//         // Assuming 'attributes' is already in the desired format or transforming as needed
//         return { id: doc.id, ...data };
//       });
//       setProducts(productsList);
//     };

//     fetchProducts();
//   }, []);

//   return (
//     <Paper sx={{ margin: '20px', overflow: 'hidden' }}>
//       <Typography variant="h6" sx={{ margin: '20px' }}>상품 목록</Typography>
//       <TableContainer sx={{ maxHeight: 440 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell>Category</TableCell>
//               <TableCell>Attributes</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {products.map((product) => (
//               <TableRow key={product.id}>
//                 <TableCell>{product.name}</TableCell>
//                 <TableCell>{product.category}</TableCell>
//                 <TableCell>
//                   {product.attributes.map((attr, index) => (
//                     <div key={index}>
//                       {attr.key}: {attr.value}
//                     </div>
//                   ))}
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//   );
// };

// export default ProductList;

// import React, { useEffect, useState } from 'react';
// import { db } from './firebaseConfig';
// import { collection, getDocs } from 'firebase/firestore';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

// const ProductList = () => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       const productsCollectionRef = collection(db, 'products');
//       const productsSnapshot = await getDocs(productsCollectionRef);
//       // Transform the attributes array into an object with keys as attribute names
//       const productsList = productsSnapshot.docs.map(doc => {
//         const productData = doc.data();
//         const attributesObject = productData.attributes.reduce((acc, attr) => {
//           acc[attr.key] = attr.value;
//           return acc;
//         }, {});
//         return { id: doc.id, ...productData, ...attributesObject };
//       });
//       setProducts(productsList);
//     };

//     fetchProducts();
//   }, []);

//   // Extract all unique attribute keys from transformed products
//   const attributeKeys = products.reduce((keys, product) => {
//     Object.keys(product).forEach(key => {
//       if (!['id', 'name', 'category'].includes(key) && !keys.includes(key)) {
//         keys.push(key);
//       }
//     });
//     return keys;
//   }, []);

//   const tableHeaders = ['Category', 'Name', ...attributeKeys];

//   return (
//     <Paper sx={{ margin: '20px', overflow: 'hidden' }}>
//       <Typography variant="h6" sx={{ margin: '20px' }}>상품 목록</Typography>
//       <TableContainer sx={{ maxHeight: 440 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {tableHeaders.map(header => (
//                 <TableCell key={header}>{header}</TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {products.map((product) => (
//               <TableRow key={product.id} hover>
//                 <TableCell>{product.category}</TableCell>
//                 <TableCell>{product.name}</TableCell>

//                 {attributeKeys.map(key => {
//                   // Ensure you're rendering a value, not an object.

//                   return (
//                     <TableCell key={key}>{product[key] ? product[key].value : '—'}</TableCell>
//                   );
//                 })}
//               </TableRow>
//             ))}
//           </TableBody>

//         </Table>
//       </TableContainer>
//     </Paper>
//   );
// };

// export default ProductList;
