import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, ListGroup, ListGroupItem, ButtonGroup, UncontrolledTooltip
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

// Actions import example (you should replace with your actual action creators)
import {
  setBackgroundColor,
  setColorScheme,
  setBackgroundImage,
  setBackgroundImageOpacity,
  setEnableBackgroundImage,
  setEnableFixedHeader,
  setEnableHeaderShadow,
  setEnableSidebarShadow,
  setEnableFixedSidebar,
  setEnableFixedFooter,
  setHeaderBackgroundColor,
  setEnablePageTitleSubheading,
  setEnablePageTabsAlt,
  setEnablePageTitleIcon,
} from '../../Redux/reducers/ThemeOptionsSlice';

// Asset imports
import sideBar1 from '../../assets/utils/images/sidebar/abstract1.jpg';
import sideBar2 from '../../assets/utils/images/sidebar/abstract2.jpg';
import sideBar3 from '../../assets/utils/images/sidebar/abstract3.jpg';
import sideBar4 from '../../assets/utils/images/sidebar/abstract4.jpg';
import sideBar5 from '../../assets/utils/images/sidebar/abstract5.jpg';
import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg';
import sideBar7 from '../../assets/utils/images/sidebar/city2.jpg';
import sideBar8 from '../../assets/utils/images/sidebar/city3.jpg';
import sideBar9 from '../../assets/utils/images/sidebar/city4.jpg';
import sideBar10 from '../../assets/utils/images/sidebar/city5.jpg';

// 이미지 경로들을 배열로 구성
const sideBarImages = [
  sideBar1, sideBar2, sideBar3, sideBar4, sideBar5,
  sideBar6, sideBar7, sideBar8, sideBar9, sideBar10
];

const ThemeOptions = () => {
    const [showing, setShowing] = useState(false);
    const dispatch = useDispatch();
    const themeOptions = useSelector((state) => state.themeOptions);
  
    const toggleFeature = (feature) => {
      dispatch(feature(!themeOptions[feature]));
    };
  
    const applyBackgroundImage = (image) => {
      dispatch(setBackgroundImage(image));
    };
        return (
            <div className={`ui-theme-settings ${showing ? 'settings-open' : ''}`}>
      
      {/* <Button className="btn-open-options" id="TooltipDemo" color="warning" onClick={() => this.setState({showing: !showing})}>
        <FontAwesomeIcon icon={faCog} spin  color="#573a04" fixedWidth={false} size="2x"/>
    </Button>
      <UncontrolledTooltip placement="left" target={'TooltipDemo'}>
        Open Layout Configurator
      </UncontrolledTooltip> */}
      <div className="theme-settings__inner">
  
        <PerfectScrollbar>
            <div className="theme-settings__options-wrapper">
                {/* Fixed Header Toggle */}
                <ListGroup>
                <ListGroupItem onClick={() => toggleFeature(setEnableFixedHeader)}>
                    <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                        <div className="widget-heading">Fixed Header</div>
                        <div className="widget-subheading">Makes the header top fixed, always visible!</div>
                        </div>
                        <div className="widget-content-right">
                        {themeOptions.enableFixedHeader ? <FontAwesomeIcon icon={faCheck} /> : null}
                        </div>
                    </div>
                    </div>
                </ListGroupItem>

                {/* Fixed Sidebar Toggle */}
                <ListGroupItem onClick={() => toggleFeature(setEnableFixedSidebar)}>
                    <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                        <div className="widget-heading">Fixed Sidebar</div>
                        <div className="widget-subheading">Makes the sidebar left fixed, always visible!</div>
                        </div>
                        <div className="widget-content-right">
                        {themeOptions.enableFixedSidebar ? <FontAwesomeIcon icon={faCheck} /> : null}
                        </div>
                    </div>
                    </div>
                </ListGroupItem>

                {/* Background Image Toggle */}
                <ListGroupItem onClick={() => toggleFeature(setEnableBackgroundImage)}>
                    <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                        <div className="widget-heading">Background Image</div>
                        <div className="widget-subheading">Enable background images for sidebar!</div>
                        </div>
                        <div className="widget-content-right">
                        {themeOptions.enableBackgroundImage ? <FontAwesomeIcon icon={faCheck} /> : null}
                        </div>
                    </div>
                    </div>
                </ListGroupItem>
                </ListGroup>

                {/* Background Image Selection */}
                {themeOptions.enableBackgroundImage && (
                <div className="background-image-selection">
                    {sideBarImages.map((image, index) => (
                    <div key={index} className="sidebar-image-option" onClick={() => applyBackgroundImage(image)}>
                        <img src={image} alt={`Sidebar background ${index + 1}`} />
                    </div>
                    ))}
                </div>
                )}
            </div>
            </PerfectScrollbar>

        </div>
    </div>
  );
};

export default ThemeOptions;