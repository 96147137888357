import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import Barcode from 'react-jsbarcode';

const BarcodeGenerator = ({ inputValue }) => {
  //const [inputValue, setInputValue] = useState('');
  const [barcodeValue, setBarcodeValue] = useState('');

  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  const generateBarcode = () => {
    setBarcodeValue(inputValue.trim());
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={generateBarcode}
        sx={{ marginTop: 2, marginBottom: 2 }}
      >
        바코드 생성
      </Button>
      {barcodeValue && (
        <Box sx={{ textAlign: 'center' }}>
          <Barcode value={barcodeValue} options={{ format: 'CODE128' }} />
        </Box>
      )}
    </Box>
  );
};

export default BarcodeGenerator;
