import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Typography,
} from '@mui/material';
import CartonBoxUpdateDialog from './CartonBoxUpdateDialog';
import CartonBoxAddDialog from './CartonBoxAddDialog';

const CartonBoxList = () => {
  const [cartonBoxes, setCartonBoxes] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentCartonBoxId, setCurrentCartonBoxId] = useState('');
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const fetchCartonBoxes = async () => {
    const querySnapshot = await getDocs(collection(db, 'cartonBoxes'));
    const cartonBoxList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCartonBoxes(cartonBoxList);
  };

  useEffect(() => {
    fetchCartonBoxes();
  }, []);
  const handleOpenAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const handleDelete = async (boxId) => {
    if (window.confirm('Are you sure you want to delete this carton box?')) {
      try {
        await deleteDoc(doc(db, 'cartonBoxes', boxId));
        // Remove the box from the state to update the UI
        setCartonBoxes((currentBoxes) =>
          currentBoxes.filter((box) => box.id !== boxId)
        );
      } catch (error) {
        console.error('Error deleting carton box: ', error);
        alert('Failed to delete the carton box.');
      }
    }
  };

  const handleCloseAddDialog = () => {
    setIsAddDialogOpen(false);
    fetchCartonBoxes();
  };

  const handleEditClick = (id) => {
    setCurrentCartonBoxId(id);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Paper style={{ margin: 16, padding: 16 }}>
        <Typography variant="h6" sx={{ margin: '20px' }}>
          Carton Box List
        </Typography>
        <Button
          onClick={handleOpenAddDialog}
          variant="contained"
          color="primary"
          style={{ marginBottom: '20px' }}
        >
          Add New Carton Box
        </Button>
        <CartonBoxAddDialog
          open={isAddDialogOpen}
          onClose={handleCloseAddDialog}
        />
        <List>
          {cartonBoxes.map((box) => (
            <ListItem
              key={box.id}
              secondaryAction={
                <>
                  <Button onClick={() => handleEditClick(box.id)}>Edit</Button>
                  <Button onClick={() => handleDelete(box.id)} color="error">
                    Delete
                  </Button>
                </>
              }
            >
              <ListItemText
                primary={`카톤박스: ${box.width}x${box.length}x${box.height}`}
                secondary={`ID: ${box.id}`}
              />
            </ListItem>
          ))}
        </List>
        {isDialogOpen && (
          <CartonBoxUpdateDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            cartonBoxId={currentCartonBoxId}
          />
        )}
      </Paper>
    </>
  );
};

export default CartonBoxList;
