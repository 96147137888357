import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const ResourceAllocation = () => {
  const { tenantId } = useGlobalContext();
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState('');
  const [reservationDate, setReservationDate] = useState(
    new Date().toISOString().slice(0, 10)
  ); // Format the date as YYYY-MM-DD

  useEffect(() => {
    const fetchResources = async () => {
      if (!tenantId) return; // Ensure tenantId is available

      // Adjust the query to fetch only resources for the current tenant
      const resourcesRef = collection(db, 'resources');
      const q = query(resourcesRef, where('tenantId', '==', tenantId));
      const querySnapshot = await getDocs(q);
      setResources(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchResources();
  }, [tenantId]); // Re-fetch when tenantId changes

  const handleResourceChange = (event) => {
    setSelectedResource(event.target.value);
  };

  const handleDateChange = (event) => {
    setReservationDate(event.target.value); // Use a better date picker in a real app
  };

  const handleCreateReservation = async () => {
    if (!tenantId || !selectedResource || !reservationDate) {
      console.log('Missing information for reservation');
      return;
    }
    // Implement the logic to check resource availability and add a reservation
    // Example: Check the selected resource's availability, if available, add reservation info to 'reservations' collection
    console.log('Creating reservation...');
    await addDoc(collection(db, 'reservations'), {
      resourceId: selectedResource,
      reservationDate,
      tenantId, // Include tenantId when creating a new reservation
    });
    console.log('Reservation created successfully');
  };

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Typography variant="h6">Allocate Resources</Typography>
      <TextField
        select
        label="Select Resource"
        value={selectedResource}
        onChange={handleResourceChange}
        fullWidth
      >
        {resources.map((resource) => (
          <MenuItem key={resource.id} value={resource.id}>
            {resource.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Reservation Date"
        type="date"
        value={reservationDate}
        onChange={handleDateChange}
        fullWidth
      />
      <Button onClick={handleCreateReservation} variant="contained">
        Create Reservation
      </Button>
    </Box>
  );
};

export default ResourceAllocation;
