import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const CategoryRegistration = ({ onClose, onCategoryRegistered }) => {
  const [categoryName, setCategoryName] = useState('');
  const { tenantId } = useGlobalContext();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryName.trim()) return;

    try {
      const docRef = await addDoc(collection(db, 'categories'), {
        name: categoryName,
        tenantId: tenantId,
      });
      onCategoryRegistered({ id: docRef.id, name: categoryName });
      setCategoryName(''); // 폼 초기화
    } catch (error) {
      console.error('Error adding category: ', error);
      alert('Failed to add category.');
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>New Category</DialogTitle>
      <DialogContent>
        <Grid>
          <TextField
            fullWidth
            label="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            style={{ marginBottom: 20 }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add Category</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryRegistration;
