import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig'; // Adjust the import path as needed
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const StoreDetailPage = () => {
  const { storeId } = useParams();
  const [salesData, setSalesData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch store's product list
    const fetchProducts = async () => {
      const productsSnapshot = await getDocs(
        collection(db, 'stores', storeId, 'products')
      );
      const productsArray = productsSnapshot.docs.map((doc) => ({
        name: doc.data().name,
        id: doc.id,
      }));
      setProducts([{ id: '', name: 'All Products' }, ...productsArray]); // Include an option to filter by all products
    };

    fetchProducts();
  }, [storeId]);

  useEffect(() => {
    const fetchSalesData = async () => {
      let salesQuery = collection(db, 'stores', storeId, 'sales');

      if (startDate && endDate) {
        // Firestore uses Timestamps, converting dayjs objects to Firestore compatible dates
        salesQuery = query(
          salesQuery,
          where('saleDate', '>=', startDate.toDate()),
          where('saleDate', '<=', endDate.toDate()),
          orderBy('saleDate')
        );
      }

      if (selectedProduct && selectedProduct !== 'All Products') {
        salesQuery = query(
          salesQuery,
          where('productId', '==', selectedProduct.id)
        );
      }

      const querySnapshot = await getDocs(salesQuery);
      const salesData = querySnapshot.docs.map((doc) => ({
        date: doc.data().saleDate.toDate().toISOString().split('T')[0], // Format Firestore Timestamp to YYYY-MM-DD
        sales: doc.data().amount, // Assuming 'amount' represents sales figures
      }));

      setSalesData(salesData);
    };

    fetchSalesData();
  }, [startDate, endDate, selectedProduct, storeId]);

  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>
      <Typography variant="h4">Store Sales Details</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          renderInput={(params) => (
            <TextField {...params} style={{ marginLeft: '10px' }} />
          )}
        />
      </LocalizationProvider>
      <FormControl fullWidth style={{ marginTop: '20px' }}>
        <InputLabel>Product</InputLabel>
        <Select
          value={selectedProduct}
          label="Product"
          onChange={(e) => setSelectedProduct(e.target.value)}
        >
          {products.map((product) => (
            <MenuItem key={product.id} value={product}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={salesData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="sales"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default StoreDetailPage;
