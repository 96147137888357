import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useGlobalContext } from '../useGlobalContext';

const ProductCodeRegistration = ({ onClose, onRegistered }) => {
  const [date, setDate] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const { tenantId } = useGlobalContext();

  const generateProductCode = () => {
    if (!date || !serialNumber) {
      console.error(
        'Date and Serial Number are required to generate a product code.'
      );
      return null;
    }
    const dateCode = date.replace(/-/g, ''); // Assumes date is in "YYYY-MM-DD" format
    return `${dateCode}-${serialNumber}`;
  };

  const handleSubmit = async () => {
    const productCode = generateProductCode();
    if (!productCode) {
      alert('Failed to generate product code. Please check your inputs.');
      return;
    }
    try {
      await addDoc(collection(db, 'productCodes'), {
        code: productCode,
        tenantId: tenantId,
      });
      console.log('Product Code Registered:', productCode);
      onRegistered(productCode);
      onClose(); // Close the dialog upon submission
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Register Product Code</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="date"
              label="Date (YYYY-MM-DD)"
              type="date"
              fullWidth
              variant="standard"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="serialNumber"
              label="Serial Number"
              type="text"
              fullWidth
              variant="standard"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Register</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductCodeRegistration;
