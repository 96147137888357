import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
//이 컴포넌트는 Stripe의 Elements와 CardElement를 사용하여 신용 카드 정보 입력 폼을 제공하고, 
// useStripe와 useElements 훅을 사용하여 결제를 처리합니다. 
// 사용자가 결제 정보를 입력하고 'Pay' 버튼을 클릭하면, handleSubmit 함수가 호출되어 Stripe로 결제 정보를 전송하고 결제를 시도합니다. 
// 결제가 성공하면, 결제 처리 결과를 받아 추가 작업을 수행할 수 있습니다(예: 결제 성공 알림 표시, 예약 정보 업데이트 등).

//PaymentIntegration 컴포넌트를 통해 웹사이트나 애플리케이션에 쉽게 결제 기능을 추가할 수 있으며, 
//Stripe 외에도 PayPal, Square 등 다른 결제 서비스를 통합할 수 있습니다. 
// 각 결제 서비스 제공업체의 API 문서를 참조하여 필요한 설정과 통합 절차를 확인해야 합니다.

// Stripe 공개 키로 Stripe 객체 초기화


// Stripe 공개 키로 Stripe 객체 초기화 (실제 키로 교체 필요)
const stripePromise = loadStripe('YOUR_STRIPE_PUBLIC_KEY');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js가 아직 로드되지 않았을 때의 처리
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // 여기에서 백엔드로 paymentMethod.id를 전송하고 결제를 처리합니다.
      // 결제 성공 시 알림
      setSnackbarMessage('Payment successful!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
      <Button variant="contained" color="primary" type="submit" disabled={!stripe || loading} style={{ marginTop: 20 }}>
        {loading ? <CircularProgress size={24} /> : 'Pay'}
      </Button>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

const PaymentIntegration = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentIntegration;
