// orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    type: null,  // 이 필드 추가
    details: [],
  },
  reducers: {
    placeOrder: (state, action) => {
      state.type = 'PLACE_ORDER'; // 타입 설정
      state.details = action.payload;
    },
    cancelOrder: (state, action) => {
      state.type = 'CANCEL_ORDER'; // 타입 설정
      state.details = action.payload;
    },
    returnOrder: (state, action) => {
      state.type = 'RETURN_ORDER'; // 타입 설정
      state.details = action.payload;
    }
  },
});

export const { placeOrder, cancelOrder, returnOrder } = orderSlice.actions;
export default orderSlice.reducer;
