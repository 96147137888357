import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  addDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
} from 'firebase/firestore';
import {
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
} from '@mui/material';

const PromotionProductRegistration = ({ tenantId }) => {
  const [promotionName, setPromotionName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(
        collection(db, 'products'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllProducts(products);
    };

    fetchProducts();
  }, [tenantId]);

  const handleProductSelect = (event) => {
    setSelectedProducts(event.target.value);
  };

  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities((prev) => ({ ...prev, [productId]: quantity }));
  };

  const handleRegisterPromotion = async () => {
    // 재고 차감 로직
    for (const productId of selectedProducts) {
      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, {
        stock:
          (allProducts.find((p) => p.id === productId).stock || 0) -
          (productQuantities[productId] || 0),
      });
    }

    try {
      const docRef = await addDoc(collection(db, 'promotions'), {
        name: promotionName,
        description,
        startDate,
        endDate,
        products: selectedProducts.map((id) => ({
          id,
          quantity: productQuantities[id] || 0,
        })),
        tenantId,
      });
      console.log('Document written with ID: ', docRef.id);
      alert('Promotion registered successfully!');
      // Reset form fields here...
    } catch (e) {
      console.error('Error adding document: ', e);
      alert('Failed to register promotion.');
    }
  };

  return (
    <div>
      <Typography variant="h6">행사 상품 등록</Typography>
      {/* Promotion Name, Description, StartDate, EndDate, etc. */}
      <FormControl fullWidth>
        <InputLabel>구성 상품</InputLabel>
        <Select
          multiple
          value={selectedProducts}
          onChange={handleProductSelect}
          renderValue={(selected) => (
            <Stack direction="row" spacing={1}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={allProducts.find((p) => p.id === value)?.name || value}
                />
              ))}
            </Stack>
          )}
        >
          {allProducts.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {product.name}
              <TextField
                size="small"
                type="number"
                onChange={(e) =>
                  handleQuantityChange(product.id, Number(e.target.value))
                }
                value={productQuantities[product.id] || ''}
                style={{ marginLeft: '10px' }}
                placeholder="수량"
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleRegisterPromotion}>행사 상품 등록</Button>
    </div>
  );
};

export default PromotionProductRegistration;
