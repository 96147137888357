import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  products: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Async thunk action
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  // Replace with your own logic to fetch data
  const response = await fetch('https://api.example.com/products');
  const data = await response.json();
  return data;
});

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // Define the updateProductDetails reducer
    updateProductDetails: (state, action) => {
      // logic to update product details
      // Example:
      state.details = action.payload;
      // const { id, updates } = action.payload;
      // const product = state.products.find(product => product.id === id);
      // if (product) {
      //   Object.assign(product, updates);
      // }
    },
    // you can add more reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateProductDetails } = productSlice.actions;

export default productSlice.reducer;
