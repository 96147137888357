import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  styled,
} from '@mui/material';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Inbox as InboxIcon, Mail as MailIcon } from '@mui/icons-material';
import { useLocation, Link } from 'react-router-dom';
import bg1 from './assets/utils/images/originals/city.jpg';
import bg2 from './assets/utils/images/originals/citydark.jpg';
import bg3 from './assets/utils/images/originals/citynights.jpg';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const adminMenuItems = [
  { text: '거래처 관리', icon: <PeopleIcon />, path: '/vendor-management' },
  {
    text: '상품 관리',
    icon: <ShoppingBasketIcon />,
    path: '/product-management',
  },
  {
    text: '재고 관리',
    icon: <Inventory2Icon />,
    path: '/inventory-management',
  },
  { text: '가맹점 관리', icon: <StorefrontIcon />, path: '/store-management' },
  {
    text: '예약 관리',
    icon: <AccountCircleIcon />,
    path: '/reservation-management',
  },
  { text: '고객 관리', icon: <PeopleIcon />, path: '/customer-management' },
  { text: '풀필먼트', icon: <StorefrontIcon />, path: '/warehouse-management' },
  { text: '영업관리', icon: <HomeIcon />, path: '/sales-management' },
];

const sellerMenuItems = [
  { text: '거래처 관리', icon: <PeopleIcon />, path: '/vendor-management' },
  {
    text: '상품 관리',
    icon: <ShoppingBasketIcon />,
    path: '/product-management',
  },
  {
    text: '재고 관리',
    icon: <Inventory2Icon />,
    path: '/inventory-management',
  },
  { text: '가맹점 관리', icon: <StorefrontIcon />, path: '/store-management' },
  {
    text: '예약 관리',
    icon: <AccountCircleIcon />,
    path: '/reservation-management',
  },
  { text: '고객 관리', icon: <PeopleIcon />, path: '/customer-management' },
  { text: '풀필먼트', icon: <StorefrontIcon />, path: '/warehouse-management' },
  { text: '영업관리', icon: <HomeIcon />, path: '/sales-management' },
];

const logisticsMenuItems = [
  { text: '풀필먼트', icon: <StorefrontIcon />, path: '/warehouse-management' },
  {
    text: '재고 관리',
    icon: <Inventory2Icon />,
    path: '/inventory-management',
  },
];

const shipperMenuItems = [
  {
    text: '예약 관리',
    icon: <AccountCircleIcon />,
    path: '/reservation-management',
  },
  { text: '고객 관리', icon: <PeopleIcon />, path: '/customer-management' },
];

// Drawer 스타일을 위한 styled 컴포넌트
const CustomDrawer = styled(Drawer)(({ theme, open, sidebarWidth }) => ({
  width: sidebarWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: sidebarWidth,
    boxSizing: 'border-box',
    background: `url(${bg1})`, // 여기에 bg1 이미지를 배경으로 설정
    backgroundSize: 'cover', // 배경 이미지가 Drawer 전체를 커버하도록 설정
  },
}));

const AppSidebar = ({
  setActiveMenu,
  currentUser,
  sidebarWidth,
  toggleSlider,
}) => {
  const location = useLocation();
  const [userStatus, setUserStatus] = useState(null);
  const [userType, setUserType] = useState(null); // 사용자 유형 상태 추가
  const [role, setRole] = useState(null);
  // const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    // currentUser가 있을 때만 Firestore 조회를 진행
    if (currentUser) {
      // 즉시 실행 함수를 사용하여 비동기 로직 처리
      (async () => {
        const userRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserStatus(docSnap.data().status); // Firestore에서 가져온 사용자 상태를 설정
          setUserType(docSnap.data().userType);
          setRole(docSnap.data().role);
        } else {
          setUserStatus('unknown'); // 문서가 없는 경우, 'unknown' 상태로 설정
          setUserType(null);
        }
      })();
    } else {
      setUserStatus(null); // currentUser가 없는 경우, userStatus를 null로 설정
    }
  }, [currentUser]);
  // userType에 따라 메뉴 항목 배열 선택
  let menuItems;
  switch (userType) {
    case 0: //'admin'
      menuItems = adminMenuItems;
      break;
    case 1: //'seller'
      menuItems = sellerMenuItems;
      break;
    case 2: //'logistics'
      menuItems = logisticsMenuItems;
      break;
    case 3: //'shipper'
      menuItems = shipperMenuItems;
      break;
    default:
      menuItems = []; // 기본값은 빈 배열 또는 기본 메뉴
  }
  return (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          backgroundImage: currentUser ? 'none' : `url(${bg3})`, // 로그인 상태에 따라 배경 이미지 설정
          backgroundSize: 'cover',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {currentUser && userStatus ? (
        <>
          <Toolbar />
          <Divider />
          <Avatar
            src={currentUser.photoURL}
            alt={currentUser.uid}
            sx={{ width: 56, height: 56 }} // 예시로 크기를 지정해 봅니다. 필요에 따라 조정해 주세요.
          />
          <List>
            {/* {['거래처 관리', '재고관리', '풀필먼트', '고객관리', '예약관리', '상품관리', '가맹점관리', '영업관리'].map((text, index) => ( */}
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                component={Link}
                to={`${item.path}`}
                onClick={() => {
                  setActiveMenu(item.text);
                  toggleSlider(); // Hide sidebar on menu click
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <CustomDrawer
            variant="permanent"
            anchor="left"
            sidebarWidth={sidebarWidth}
          ></CustomDrawer>
        </Box>
      )}
    </Drawer>
  );
};

export default AppSidebar;
