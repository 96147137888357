// authReducer.js

import { createSlice } from "@reduxjs/toolkit";

const authReducer = createSlice({
  name: "auth",
  initialState: {
    currentUser: null
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    }
  }
});

// Export the action creators
export const { setCurrentUser } = authReducer.actions;

// Export the reducer
export default authReducer.reducer;

