import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../Redux/reducers/ThemeOptionsSlice";

const HeaderLogo = () => {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const { enableClosedSidebar } = useSelector((state) => state.themeOptions);

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  };

  return (
    <Fragment>
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ms-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  );
};

export default HeaderLogo;
