import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const VendorDetailPage = () => {
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const [vendorDetails, setVendorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!vendorId) {
      console.error('vendorId is undefined');
      setIsLoading(false);
      setError('Vendor ID is missing.');
      return;
    }

    const fetchVendorDetails = async () => {
      setIsLoading(true);
      try {
        const docRef = doc(db, 'vendors', vendorId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setVendorDetails(docSnap.data());
        } else {
          console.log('No such document!');
          setError('No such document found.');
        }
      } catch (error) {
        console.error('Error fetching vendor details:', error);
        setError('Failed to fetch vendor details.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page
  };
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!vendorDetails) {
    return <Typography>No details available.</Typography>;
  }

  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        style={{ marginBottom: '20px' }}
      >
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        {vendorDetails.name} Details
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">
          Contact: {vendorDetails.contact}
        </Typography>
        <Typography variant="body1">
          Address: {vendorDetails.address}
        </Typography>
        <Typography variant="body1">Email: {vendorDetails.email}</Typography>
        <Typography variant="body1">
          Conditions: {vendorDetails.conditions}
        </Typography>
        <Typography variant="body1">
          Business Number: {vendorDetails.businessNumber}
        </Typography>
        <Typography variant="body1">
          Representative Name: {vendorDetails.representativeName}
        </Typography>
        <Typography variant="body1">
          Commission Rate: {vendorDetails.commissionRate}%
        </Typography>
      </Box>
      {/* Transactions list can be implemented here */}
    </Paper>
  );
};

export default VendorDetailPage;
