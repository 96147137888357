import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { useGlobalContext } from '../useGlobalContext';

const ProductSKURegistration = ({ onClose, onRegistered }) => {
  const [brand, setBrand] = useState('');
  const [color, setColor] = useState('');
  const [productTypes, setProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [newTypeName, setNewTypeName] = useState('');
  const [material, setMaterial] = useState('');
  const { tenantId } = useGlobalContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchProductTypes = async () => {
      const querySnapshot = await getDocs(collection(db, 'productTypes'));
      setProductTypes(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchProductTypes();
  }, []);

  const addProductType = async () => {
    if (newTypeName.trim() === '') return;
    const docRef = await addDoc(collection(db, 'productTypes'), {
      name: newTypeName,
    });
    setProductTypes([...productTypes, { id: docRef.id, name: newTypeName }]);
    setSelectedType(docRef.id);
    setNewTypeName('');
  };
  const generateSKU = () => {
    const brandCode = brand.toUpperCase().substring(0, 3);
    const colorCode = color.toUpperCase().substring(0, 3);
    const typeCode = selectedType.toUpperCase().substring(0, 3);
    const materialCode = material.toUpperCase().substring(0, 3);
    return `${brandCode}-${colorCode}-${typeCode}-${materialCode}`;
  };
  const handleSubmit = async () => {
    const sku = generateSKU();
    try {
      await addDoc(collection(db, 'productSKUs'), {
        code: sku,
        tenantId: tenantId,
        brand,
        color,
        selectedType,
        material,
      });
      console.log('SKU Registered:', sku);
      onRegistered(sku);
      setSnackbarMessage('SKU Registered Successfully!');
      setOpenSnackbar(true);
      onClose();
    } catch (error) {
      console.error('Error adding document:', error);
      setSnackbarMessage('Error registering SKU.');
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Register SKU</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Brand input */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="brand"
                label="Brand"
                type="text"
                fullWidth
                variant="standard"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
            </Grid>
            {/* Color picker */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="color"
                label="Color"
                type="color"
                fullWidth
                variant="standard"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* Type selector */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  label="Type"
                >
                  {productTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="New Type Name"
                    value={newTypeName}
                    onChange={(e) => setNewTypeName(e.target.value)}
                    margin="dense"
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={addProductType}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {/* Material input */}
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="material"
                label="Material"
                type="text"
                fullWidth
                variant="standard"
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Register
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default ProductSKURegistration;
