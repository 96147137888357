import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
  const [sidebarWidth, setSidebarWidth] = useState(320);
  const [navbarHeight, setNavbarHeight] = useState('0px');
  const [tabbarHeight, setTabbarHeight] = useState('0px');
  const [textInputHeight, setTextInputHeight] = useState('70px');
  const [tenantId, setTenantId] = useState('');
  // 화면 크기가 변경될 때마다 상태를 업데이트
  useEffect(() => {
    const updateDimensions = () => {
      let sidebarWidth;
      const screenWidth = window.innerWidth;

      if (screenWidth <= 320) {
        sidebarWidth = 0; // 모바일 작은 화면
      } else if (screenWidth <= 480) {
        sidebarWidth = 0; // 모바일 대형 화면
      } else if (screenWidth <= 768) {
        sidebarWidth = 0; // 태블릿
      } else if (screenWidth <= 1024) {
        sidebarWidth = 260; // 소형 데스크탑
      } else {
        sidebarWidth = 320; // 대형 데스크탑
      }

      setSidebarWidth(sidebarWidth);
      setNavbarHeight(screenWidth <= 768 ? '0px' : '110px');
      setTabbarHeight(screenWidth <= 768 ? '0px' : '0px');
      setTextInputHeight(screenWidth <= 768 ? '50px' : '70px');
    };

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  // navbarHeight와 tabbarHeight가 변경될 때마다, CSS 변수를 업데이트
  useEffect(() => {
    document.documentElement.style.setProperty('--sidebar-width', sidebarWidth);
    document.documentElement.style.setProperty('--navbar-height', navbarHeight);
    document.documentElement.style.setProperty('--tabbar-height', tabbarHeight);
    document.documentElement.style.setProperty(
      '--text-input-height',
      textInputHeight
    );
  }, [sidebarWidth, navbarHeight, tabbarHeight, textInputHeight]);

  // tenantId를 업데이트하는 함수
  const updateTenantId = (newTenantId) => {
    setTenantId(newTenantId);
  };

  return (
    <GlobalContext.Provider
      value={{
        sidebarWidth,
        navbarHeight,
        tabbarHeight,
        textInputHeight,
        tenantId,
        updateTenantId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
