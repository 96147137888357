import React, { useState } from 'react';
import { Drawer, Box, styled } from '@mui/material';

import { useLocation, Link } from 'react-router-dom';
import bg1 from './assets/utils/images/originals/city.jpg';
import bg2 from './assets/utils/images/originals/citydark.jpg';
import bg3 from './assets/utils/images/originals/citynights.jpg';

// Drawer 스타일을 위한 styled 컴포넌트
const CustomDrawer = styled(Drawer)(({ theme, open, sidebarWidth }) => ({
  width: sidebarWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: sidebarWidth,
    boxSizing: 'border-box',
    background: `url(${bg1})`, // 여기에 bg1 이미지를 배경으로 설정
    backgroundSize: 'cover', // 배경 이미지가 Drawer 전체를 커버하도록 설정
  },
}));

const LoginSideBar = ({ setActiveMenu, sidebarWidth, toggleSlider }) => {
  const location = useLocation();
  return (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          backgroundImage: `url(${bg3})`, // 로그인 상태에 따라 배경 이미지 설정
          backgroundSize: 'cover',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CustomDrawer
          variant="permanent"
          anchor="left"
          sidebarWidth={sidebarWidth}
        ></CustomDrawer>
      </Box>
    </Drawer>
  );
};

export default LoginSideBar;
