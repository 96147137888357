import React from 'react';
import App from './App';
import Main from "./DemoPages/Main";
import useAuthCheckRedux from './useAuthCheckRedux';

const RootComponent = () => {
  useAuthCheckRedux();
  return <App />;
}

export default RootComponent;
