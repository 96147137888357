import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
  TextField,
  Box,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useGlobalContext } from '../useGlobalContext';

const BookingCalendar = () => {
  const { tenantId } = useGlobalContext();
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAvailableTimes = async () => {
      if (!tenantId) {
        setError(
          'Tenant ID is not available. Please ensure you are operating within a valid tenant context.'
        );
        setLoading(false);
        return;
      }

      setLoading(true);
      const selectedDate = selectedDateTime.format('YYYY-MM-DD');

      // Incorporate tenantId in the query to ensure tenant-specific data retrieval
      const q = query(
        collection(db, 'timeslots'),
        where('tenantId', '==', tenantId),
        where('date', '==', selectedDate)
      );

      try {
        const querySnapshot = await getDocs(q);
        // Assume each doc may contain multiple time slots
        const times = querySnapshot.docs.flatMap((doc) => doc.data().times); // Adapt according to your data model
        setAvailableTimes(times.sort()); // Sort times for better UX
      } catch (err) {
        console.error('Error fetching timeslots for tenant:', err);
        setError('Failed to fetch available times. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableTimes();
  }, [tenantId, selectedDateTime.format('YYYY-MM-DD')]);

  const handleDateChange = (newValue) => {
    setSelectedDateTime(
      selectedDateTime
        .year(newValue.year())
        .month(newValue.month())
        .date(newValue.date())
    );
  };

  const handleTimeChange = (newValue) => {
    setSelectedDateTime(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ m: 3 }}>
        <Typography variant="h6">
          Select a Date and Time for Your Appointment
        </Typography>
        <DatePicker
          label="Date"
          value={selectedDateTime}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TimePicker
            label="Time"
            value={selectedDateTime}
            onChange={handleTimeChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            shouldDisableTime={(timeValue, clockType) => {
              if (clockType === 'minutes' || !availableTimes.length)
                return false;
              const timeString = `${
                timeValue < 10 ? `0${timeValue}` : timeValue
              }:00`;
              return !availableTimes.includes(timeString);
            }}
          />
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default BookingCalendar;
