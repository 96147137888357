import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { db, doc, getDoc, setDoc } from './firebaseConfig';  // Firestore 설정 파일 임포트
import UserAddress from './UserAddress';
import './css/detail.css';
import AddressModal from './SignUp/AddressModal';
import { useParams } from 'react-router-dom';

function UserProfile({ onPrev, onNext, setIsStepCompleted}) {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [userData, setUserData] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    // const { userId } = useParams();
    const userId = currentUser.uid;
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userRef = doc(db, 'users', userId);  // Reference 생성
                const userDoc = await getDoc(userRef);    // Document 가져오기
    
                if (userDoc.exists()) {

                    // const userData = {
                    //     address: userDoc.data().address,
                    //     extraAddress: userDoc.data().extraAddress,
                    //     zoneCode: userDoc.data().zoneCode,
                    //     additionalAddresses: userDoc.data().additionalAddresses,
                    //     phoneNumber: userDoc.data().phoneNumber,
    
                    // };

                    setUserData(userDoc.data());
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
    
        fetchUserData();
    }, [userId]);
    
    const handleUpdateAddress = async (updatedUser) => {
        try {
            const userRef = doc(db, 'users', userId);
            await setDoc(userRef, updatedUser, { merge: true });  // Document 업데이트
            setUserData(updatedUser);
            setIsStepCompleted(true);
            alert('주소 정보가 업데이트되었습니다.');
        } catch (error) {
            console.error("Error updating address:", error);
            alert('주소 정보를 업데이트하는 중 오류가 발생했습니다.');
        }
    };
    

    const handleSelectedAddress = (address) => {
        setSelectedAddress(address);
    }

    return (
        <div className='detail'>
            <div className='container'>
                <h1>사용자 프로필</h1>
                <UserAddress
                        user={userData}
                        onUpdate={handleUpdateAddress}
                        onAddressSelect={handleSelectedAddress}
                    />
                {/* {userData ? (
                    <UserAddress
                        user={userData}
                        onUpdate={handleUpdateAddress}
                    />
                ) : (
                    
                )} */}
            </div>
        </div>
    );
}

export default UserProfile;
