import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setCurrentUser } from "./Redux/actions/authAction";
import { setCurrentUser } from './Redux/reducers/authReducer';
import { auth, onAuthStateChanged } from './firebaseConfig'; // 또는 해당 firebase 패키지에 따라

const useAuthCheckRedux = (redirectPath = null) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Simplify the user object to only contain serializable properties
        const serializableUser = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };
        dispatch(setCurrentUser(serializableUser));
      } else {
        dispatch(setCurrentUser(null));
        if (redirectPath) {
          alert('로그인이 필요한 서비스입니다.');
        }
      }
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, [auth, dispatch]);
};

export default useAuthCheckRedux;
