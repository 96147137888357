export const getMediaType = (url) => isImageURL(url) ? 'image' : isVideoURL(url) ? 'video' : 'other';

// Constants for media types
export const MEDIA_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video',
    OTHER: 'other',
};
// URL 타입 확인을 위한 helper 함수
export const isImageURL = (url) => {
    const pathname = new URL(url).pathname;
    return /\.(jpg|jpeg|png|gif)$/i.test(pathname);
}

export const isVideoURL = (url) => {
    const pathname = new URL(url).pathname;
    return /\.(mp4|webm|ogg)$/i.test(pathname);
}