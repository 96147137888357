import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

export const StoreBuilder = ({ open, handleClose, storeId, initialData }) => {
  const { tenantId } = useGlobalContext();
  const [name, setName] = useState('');
  const [sales, setSales] = useState(0);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setSales(initialData.sales || 0);
    } else {
      // Reset fields when creating a new store
      setName('');
      setSales(0);
    }
  }, [initialData, open]); // Reset form when dialog is opened or initialData changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Include tenantId in the store data for multi-tenancy support
    const storeData = { name, sales: Number(sales), tenantId };

    try {
      if (storeId) {
        // Updating an existing store
        await setDoc(doc(db, 'stores', storeId), storeData);
      } else {
        // Adding a new store
        await addDoc(collection(db, 'stores'), storeData);
      }
      handleClose();
    } catch (error) {
      console.error('Error saving store: ', error);
      // You can set an error message state here to display an error to the user if needed
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{storeId ? 'Edit Store' : 'Add Store'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Store Name"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="sales"
          label="Sales"
          type="number"
          fullWidth
          variant="standard"
          value={sales}
          onChange={(e) => setSales(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
