import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  query,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { db } from '../firebaseConfig';
import PalletAddDialog from './PalletAddDialog';
import PalletUpdateDialog from './PalletUpdateDialog';

function PalletList() {
  const [pallets, setPallets] = useState([]);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedPalletId, setSelectedPalletId] = useState('');

  const fetchPallets = async () => {
    const q = query(collection(db, 'pallets'));
    const querySnapshot = await getDocs(q);
    const loadedPallets = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPallets(loadedPallets);
  };
  useEffect(() => {
    fetchPallets();
  }, []);

  const handleDelete = async (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      try {
        await deleteDoc(doc(db, 'pallets', palletId));
        setPallets((prev) => prev.filter((pallet) => pallet.id !== palletId));
        alert('Pallet deleted successfully.');
      } catch (error) {
        console.error('Error removing document: ', error);
        alert('Failed to delete the pallet.');
      }
    }
  };

  const openUpdateDialog = (palletId) => {
    setSelectedPalletId(palletId);
    setIsUpdateDialogOpen(true);
  };

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false);
  };

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    fetchPallets();
    setIsAddDialogOpen(false);
  };

  return (
    <Paper style={{ margin: 16, padding: 16 }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        Pallet List
      </Typography>
      <Button variant="contained" color="primary" onClick={openAddDialog}>
        Add Pallet
      </Button>
      {isAddDialogOpen && (
        <PalletAddDialog open={isAddDialogOpen} onClose={closeAddDialog} />
      )}
      {isUpdateDialogOpen && (
        <PalletUpdateDialog
          open={isUpdateDialogOpen}
          onClose={closeUpdateDialog}
          palletId={selectedPalletId}
        />
      )}
      {pallets.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Length</TableCell>
              <TableCell>Width</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Weight Capacity</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pallets.map((pallet) => (
              <TableRow key={pallet.id}>
                <TableCell>{pallet.length}</TableCell>
                <TableCell>{pallet.width}</TableCell>
                <TableCell>{pallet.height}</TableCell>
                <TableCell>{pallet.weightCapacity}</TableCell>
                <TableCell>{pallet.material}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => openUpdateDialog(pallet.id)}
                    style={{ marginRight: '10px' }}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleDelete(pallet.id)} color="error">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}

export default PalletList;
