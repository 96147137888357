import React, { useState, Suspense, lazy } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { useGlobalContext } from '../useGlobalContext';
// Lazy loading components
const VendorList = lazy(() => import('./VendorList'));
const VendorOrderList = lazy(() => import('./VendorOrderList'));
const OrderManagement = lazy(() => import('./OrderManagement'));
const OrderHistory = lazy(() => import('./OrderHistory'));
const ProductInventoryManager = lazy(() => import('./ProductInventoryManager'));
const OrderPaymentManager = lazy(() => import('./OrderPaymentManager'));
const PerformanceAnalysisAndReporting = lazy(() =>
  import('./PerformanceAnalysisAndReporting')
);
const ContractManagement = lazy(() => import('./ContractManagement'));
const NotificationManager = lazy(() => import('./NotificationManager'));

const VendorDashboard = () => {
  const [tabValue, setTabValue] = useState('1');
  const { tenantId } = useGlobalContext();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabComponents = {
    1: VendorList,
    2: VendorOrderList,
    3: OrderManagement,
    4: OrderHistory,
    5: ProductInventoryManager,
    6: OrderPaymentManager,
    7: PerformanceAnalysisAndReporting,
    8: ContractManagement,
    9: NotificationManager,
  };

  const renderTabComponent = (tabValue) => {
    const TabComponent = tabComponents[tabValue];
    return <TabComponent tenantId={tenantId} />;
  };

  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="business insights tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {Object.entries(tabComponents).map(([key, Component], index) => (
              <Tab key={key} label={getTabLabelByKey(key)} value={key} />
            ))}
          </Tabs>
        </Box>
        <Suspense fallback={<CircularProgress />}>
          {renderTabComponent(tabValue)}
        </Suspense>
      </TabContext>
    </Paper>
  );
};
const getTabLabelByKey = (key) => {
  // 각 키에 따른 탭 라벨을 반환하는 함수, 예: '1' => 'Details'
  const labels = {
    1: '거래처 리스트',
    2: '발주정보',
    3: '발주관리',
    4: '발주상태',
    5: '발주이력',
    6: '지불 추적 및 결산',
    7: '성과 분석 및 보고',
    8: '계약관리',
    9: '중요알림',
  };
  return labels[key] || 'Unknown';
};
export default VendorDashboard;
