import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Typography, Paper } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useGlobalContext } from '../useGlobalContext';

const AnalyticsAndReporting = () => {
  const { tenantId } = useGlobalContext();
  const [reservations, setReservations] = useState([]);
  const [serviceTypesMap, setServiceTypesMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!tenantId) return; // Ensure tenantId is available

      // Fetch service types specific to the tenant
      const serviceTypesRef = query(
        collection(db, 'serviceTypes'),
        where('tenantId', '==', tenantId)
      );
      const serviceTypesSnapshot = await getDocs(serviceTypesRef);
      const serviceTypesData = serviceTypesSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc[doc.id] = data.name; // Assuming 'name' is the field for service name
        return acc;
      }, {});

      setServiceTypesMap(serviceTypesData);

      // Fetch reservations specific to the tenant
      const reservationsRef = query(
        collection(db, 'reservations'),
        where('tenantId', '==', tenantId)
      );
      const reservationsSnapshot = await getDocs(reservationsRef);
      const reservationsData = reservationsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        date: parseFirestoreDate(doc.data().date),
        serviceName: serviceTypesData[doc.data().serviceTypeId], // Map serviceTypeId to serviceName
      }));

      setReservations(reservationsData);
    };

    fetchData();
  }, [tenantId]); // Rerun when tenantId changes

  const parseFirestoreDate = (firestoreDate) => {
    return firestoreDate?.toDate
      ? firestoreDate.toDate().toISOString().split('T')[0]
      : '';
  };

  // Data processing logic for visualization
  const serviceTypeCounts = reservations.reduce((acc, cur) => {
    const serviceName = cur.serviceName || 'Unknown'; // Use 'Unknown' for any missing names
    acc[serviceName] = (acc[serviceName] || 0) + 1;
    return acc;
  }, {});

  const serviceTypeData = Object.keys(serviceTypeCounts).map((key) => ({
    name: key,
    reservations: serviceTypeCounts[key],
  }));

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">예약건수</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={serviceTypeData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="reservations" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default AnalyticsAndReporting;
