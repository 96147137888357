import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig'; // Firebase 설정 및 초기화
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axios from 'axios';

function PhoneNumberVerificationTwilio({ onVerificationResult }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(false);
  // 인증번호 전송
  const sendVerificationCode = async () => {
    // const sendTwilioSMSAuthCode = httpsCallable(
    //   functions,
    //   'sendTwilioSMSAuthCode'
    // );
    // sendTwilioSMSAuthCode({ phoneNumber })
    //   .then((result) => {
    //     console.log(result.data.message);
    //     setTimeLeft(180); // 타이머 시작
    //   })
    //   .catch((error) => {
    //     console.error('Error sending verification code:', error);
    //   });
    setLoading(true);
    try {
      const functionUrl =
        'https://asia-northeast3-warehousemanagementsyste-334f8.cloudfunctions.net/sendTwilioSMSAuthCode';
      const response = await axios.post(
        functionUrl,
        {
          phoneNumber,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setTimeLeft(180); // 타이머 시작
      onVerificationResult(1, true, { success: response.data });
    } catch (error) {
      console.error('검증 중 오류가 발생했습니다:', error);
      onVerificationResult(1, false, { error: error }); // 검증 실패 시, 오류 메시지 전달
    }
    setLoading(false);
  };

  // 인증번호 입력 후 인증하기
  const verifyCode = async () => {
    setLoading(true);
    try {
      const functionUrl =
        'https://asia-northeast3-warehousemanagementsyste-334f8.cloudfunctions.net/sendTwilioSMSAuthCodeConfirm';
      const response = await axios.post(
        functionUrl,
        {
          phoneNumber,
          code: verificationCode,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setTimeLeft(null); // 인증 성공 시 타이머 종료
      onVerificationResult(2, true, { success: response.data });
    } catch (error) {
      console.error('검증 중 오류가 발생했습니다:', error);
      onVerificationResult(2, false, { error: error }); // 검증 실패 시, 오류 메시지 전달
    }
    setLoading(false);
  };

  // 타이머 처리
  useEffect(() => {
    let timer = null;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      alert('인증번호 유효 시간이 만료되었습니다.');
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="h5">휴대폰 번호 인증</Typography>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs>
          <TextField
            label="휴대폰 번호"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="휴대폰 번호를 입력하세요"
            fullWidth
          />
        </Grid>
        <Grid item style={{ width: '130px' }}>
          <Button variant="contained" onClick={sendVerificationCode} fullWidth>
            인증번호 전송
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs>
          <TextField
            label="인증번호"
            variant="outlined"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="인증번호를 입력하세요"
            fullWidth
          />
          {timeLeft > 0 && (
            <Typography variant="body1">
              유효시간 {Math.floor(timeLeft / 60)}:
              {(timeLeft % 60).toString().padStart(2, '0')}
            </Typography>
          )}
        </Grid>
        <Grid item style={{ width: '130px' }}>
          <Button
            variant="contained"
            onClick={verifyCode}
            disabled={!timeLeft || timeLeft <= 0}
            fullWidth
          >
            인증하기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PhoneNumberVerificationTwilio;
