import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Ensure the path is correct
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { useGlobalContext } from '../../useGlobalContext';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';

const StoreReportTemplates = () => {
  const { tenantId } = useGlobalContext();
  const [templateName, setTemplateName] = useState('');
  const [templates, setTemplates] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');

  useEffect(() => {
    // Fetch stores related to the current tenant
    const fetchStores = async () => {
      const q = query(
        collection(db, 'stores'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      setStores(
        querySnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
      );
    };

    fetchStores();
  }, [tenantId]); // Reload when tenantId changes

  // Save report template for a specific store
  const saveTemplate = async () => {
    if (templateName && selectedStore) {
      await addDoc(collection(db, 'stores', selectedStore, 'reportTemplates'), {
        name: templateName,
        tenantId: tenantId, // Include tenantId for better data organization
      });
      setTemplateName('');
      fetchTemplates(); // Refresh templates list after saving
    }
  };

  // Fetch templates for the selected store
  const fetchTemplates = async () => {
    if (!selectedStore) return;
    const q = query(
      collection(db, 'stores', selectedStore, 'reportTemplates'),
      where('tenantId', '==', tenantId)
    );
    const querySnapshot = await getDocs(q);
    const fetchedTemplates = querySnapshot.docs.map((doc) => doc.data().name);
    setTemplates(fetchedTemplates);
  };

  // Reload templates when selectedStore changes
  useEffect(() => {
    fetchTemplates();
  }, [selectedStore, tenantId]);

  return (
    <div>
      <h2>Create Store Report Template</h2>
      <FormControl fullWidth>
        <InputLabel>Store</InputLabel>
        <Select
          value={selectedStore}
          onChange={(e) => setSelectedStore(e.target.value)}
          label="Store"
        >
          {stores.map((store) => (
            <MenuItem key={store.id} value={store.id}>
              {store.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Template Name"
        variant="outlined"
        fullWidth
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={saveTemplate}
        style={{ marginTop: 8 }}
      >
        Save Template
      </Button>
      <div>
        <h3>
          Saved Templates for{' '}
          {stores.find((store) => store.id === selectedStore)?.name}
        </h3>
        <ul>
          {templates.map((template, index) => (
            <li key={index}>{template}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StoreReportTemplates;
