import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { Link } from 'react-router-dom';
import { StoreBuilder } from './StoreBuilder';
import { useGlobalContext } from '../useGlobalContext';

const StoreDashboard = () => {
  const { tenantId } = useGlobalContext();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (!tenantId) return; // Ensure tenantId is available
    const storesRef = query(
      collection(db, 'stores'),
      where('tenantId', '==', tenantId)
    );
    const unsubscribe = onSnapshot(storesRef, (snapshot) => {
      const newStores = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStores(newStores);
    });

    return () => unsubscribe();
  }, [tenantId]);

  const salesData = stores.map((store) => ({
    name: store.name,
    sales: store.sales || 0,
  }));

  // StoreBuilder related logic
  const [openForm, setOpenForm] = useState(false);
  const [editingStore, setEditingStore] = useState(null);

  const handleOpenForm = () => {
    setEditingStore(null); // Reset editing store
    setOpenForm(true);
  };

  const handleEditStore = (store) => {
    setEditingStore(store); // Set current store to edit
    setOpenForm(true);
  };

  const handleDeleteStore = async (storeId) => {
    try {
      await deleteDoc(doc(db, 'stores', storeId));
    } catch (error) {
      console.error('Error deleting store: ', error);
    }
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleOpenForm}>
          Add Store
        </Button>
        {/* Removed Edit and Delete buttons outside the list since they need a specific store context */}
        <StoreBuilder
          open={openForm}
          handleClose={() => setOpenForm(false)}
          storeId={editingStore?.id}
          initialData={editingStore}
        />
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Store Sales Data
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              List of Stores
            </Typography>
            <List>
              {stores.map((store) => (
                <ListItem
                  key={store.id}
                  secondaryAction={
                    <>
                      <Button
                        onClick={() => handleEditStore(store)}
                        variant="contained"
                        color="primary"
                        sx={{ mr: 1 }}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => handleDeleteStore(store.id)}
                        variant="contained"
                        color="error"
                      >
                        Delete
                      </Button>
                      <Button
                        component={Link}
                        to={`/store/${store.id}`}
                        variant="outlined"
                        color="primary"
                        sx={{ ml: 1 }}
                      >
                        View Details
                      </Button>
                    </>
                  }
                >
                  <ListItemText
                    primary={store.name}
                    secondary={`Orders: ${store.sales || 'No data'}`}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StoreDashboard;
