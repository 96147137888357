// useSaveLastLocation.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useSaveLastLocation = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('lastLocation', location.pathname + location.search);
  }, [location]);
};

export default useSaveLastLocation;
