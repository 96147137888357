import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { db } from '../firebaseConfig';
import { getDoc, setDoc, doc } from 'firebase/firestore';

// id: 상품의 고유 식별자 (자동 생성된 Firestore 문서 ID 사용 가능)
// productCode: 상품 코드 (고유해야 함)
// productName: 상품명
// category: 상품이 속한 카테고리 ID
// vendorId: 상품을 공급하는 거래처 ID
// price: 상품의 가격
// stock: 재고 수량
// attributes: 상품의 추가적인 속성을 나타내는 키-값 쌍의 배열
// tenantId: 상품을 소유한 테넌트의 ID (다중 테넌트 환경에서 사용)

const ProductUpdateDialog = ({ productCode, open, onClose }) => {
  const [productInfo, setProductInfo] = useState({
    productCode: '',
    productName: '',
    width: '',
    height: '',
    length: '',
    weight: '',
    unitPerItem: '',
    unitPerPallet: '',
  });

  useEffect(() => {
    const fetchProduct = async () => {
      const docRef = doc(db, 'products', productCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        setProductInfo(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    if (productCode) {
      // 상품 코드가 있을 경우에만 로드
      fetchProduct();
    }
  }, [productCode]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 출고상품 정보 문서 ID로 상품 코드를 사용합니다
      const productRef = doc(db, 'products', productInfo.productCode);
      await setDoc(productRef, {
        ...productInfo,
        // 필요한 경우, 숫자형으로 변환
        width: Number(productInfo.width),
        length: Number(productInfo.length),
        height: Number(productInfo.height),
        weight: Number(productInfo.weight),
        unitPerItem: Number(productInfo.unitPerItem),
        unitPerPallet: Number(productInfo.unitPerPallet),
      });
      alert('상품 정보가 성공적으로 업데이트되었습니다.');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('상품 정보 업데이트에 실패했습니다.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>출고상품 정보 수정</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="상품코드"
                  name="productCode"
                  value={productInfo.productCode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="상품명"
                  name="productName"
                  value={productInfo.productName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="가로(mm)"
                  name="width"
                  type="number"
                  value={productInfo.width}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="세로(mm)"
                  name="length"
                  type="number"
                  value={productInfo.length}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="높이(mm)"
                  name="height"
                  type="number"
                  value={productInfo.height}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="무게(g)"
                  name="weight"
                  type="number"
                  value={productInfo.weight}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="낱개입수(ea)"
                  name="unitPerItem"
                  type="number"
                  value={productInfo.unitPerItem}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="팔레트 입수"
                  name="unitPerPallet"
                  type="number"
                  value={productInfo.unitPerPallet}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button type="submit" fullWidth variant="contained" color="primary">
          수정하기
        </Button>

        <Button onClick={onClose} fullWidth variant="contained" color="primary">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductUpdateDialog;
