import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState : {
    searchKeyword: '',
    searchCategory: '',
    searchCategoryPath: [],
    trigger: 0  // 이 속성은 액션 호출을 트리거하기 위한 것입니다.
  },
  reducers: {
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
      state.trigger += 1; // 동일한 query 값에도 리렌더링을 트리거
    },
    resetSearchKeyword: (state) => {
      state.searchKeyword = '';
      state.trigger += 1; // 필요한 경우에만 트리거 값을 변경합니다.
    },
    setSearchCategory: (state, action) => {
      state.searchCategory = action.payload;
      state.trigger += 1; // 동일한 query 값에도 리렌더링을 트리거
    },
    resetSearchCategory: (state, action) => {
      state.searchCategory = '';
      state.trigger += 1; // 동일한 query 값에도 리렌더링을 트리거
    },
    setSearchCategoryPath: (state, action) => {
      state.searchCategoryPath = action.payload;
      state.trigger += 1; // 동일한 query 값에도 리렌더링을 트리거
    },
    resetSearchCategoryPath: (state, action) => {
      state.searchCategoryPath = [];
      state.trigger += 1; // 동일한 query 값에도 리렌더링을 트리거
    },
    
  }
});

export const { setSearchKeyword,
  resetSearchKeyword, 
  setSearchCategory, 
  resetSearchCategory, 
  setSearchCategoryPath,  
  resetSearchCategoryPath,
} = searchSlice.actions;
export default searchSlice.reducer;