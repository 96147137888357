import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Ensure the path is correct
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from 'firebase/firestore';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useGlobalContext } from '../../useGlobalContext';
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from '@mui/material';

const StoreReportExport = () => {
  const { tenantId } = useGlobalContext();
  const [selectedStore, setSelectedStore] = useState('');
  const [stores, setStores] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    // Fetch stores related to the current tenant
    const fetchStores = async () => {
      const q = query(
        collection(db, 'stores'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      setStores(
        querySnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
      );
    };

    fetchStores();
  }, [tenantId]); // Reload when tenantId changes

  useEffect(() => {
    if (!selectedStore) return;

    const fetchReportData = async () => {
      const docRef = doc(db, 'stores', selectedStore, 'reportData'); // Adjust path for tenantId and selectedStore
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setReportData(docSnap.data().data); // Set report data
      } else {
        console.log('No report data found');
        setReportData([]);
      }
    };

    fetchReportData();
  }, [selectedStore, tenantId]);

  const exportReport = (format) => {
    if (format === 'pdf') {
      const doc = new jsPDF();
      doc.text('Report', 20, 20);
      reportData.forEach((item, index) => {
        doc.text(`${item.name}: ${item.sales}`, 20, 30 + 10 * index);
      });
      doc.save('report.pdf');
    } else if (format === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(reportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      XLSX.writeFile(workbook, 'report.xlsx');
    }
  };

  return (
    <div>
      <h2>Export Store Report</h2>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select a Store</InputLabel>
        <Select
          value={selectedStore}
          onChange={(e) => setSelectedStore(e.target.value)}
          label="Select a Store"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {stores.map((store) => (
            <MenuItem key={store.id} value={store.id}>
              {store.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={() => exportReport('pdf')}
        style={{ marginRight: 8 }}
      >
        Export as PDF
      </Button>
      <Button variant="contained" onClick={() => exportReport('excel')}>
        Export as Excel
      </Button>
    </div>
  );
};

export default StoreReportExport;
