import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

function CartonBoxAddDialog({ open, onClose }) {
  const [newCartonBox, setNewCartonBox] = useState({
    width: '',
    length: '',
    height: '',
    weight: '',
    unitsPerItem: '',
    unitsPerPallet: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCartonBox((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!newCartonBox.width || !newCartonBox.length || !newCartonBox.height) {
      alert('Please fill out all dimensions fields.');
      return;
    }
    try {
      await addDoc(collection(db, 'cartonBoxes'), newCartonBox);
      onClose(); // Close dialog after successful submission
      alert('New carton box added successfully.');
    } catch (error) {
      console.error('Error adding new carton box: ', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-carton-box-dialog-title"
    >
      <DialogTitle id="add-carton-box-dialog-title">
        Add New Carton Box
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Width (mm)"
          type="number"
          name="width"
          value={newCartonBox.width}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Length (mm)"
          type="number"
          name="length"
          value={newCartonBox.length}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Height (mm)"
          type="number"
          name="height"
          value={newCartonBox.height}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Weight (g)"
          type="number"
          name="weight"
          value={newCartonBox.weight}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Units Per Item (ea)"
          type="number"
          name="unitsPerItem"
          value={newCartonBox.unitsPerItem}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Units Per Pallet (ea)"
          type="number"
          name="unitsPerPallet"
          value={newCartonBox.unitsPerPallet}
          onChange={handleChange}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CartonBoxAddDialog;
