import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const InventoryHistory = ({ productId }) => {
  const { tenantId } = useGlobalContext();
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      if (!tenantId || !productId) {
        console.log('Tenant ID or Product ID is undefined or invalid');
        return;
      }

      try {
        const historyRef = collection(db, 'inventoryHistory');
        // Include tenantId in the query to ensure data isolation per tenant
        const q = query(
          historyRef,
          where('productId', '==', productId),
          where('tenantId', '==', tenantId),
          orderBy('timestamp', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const historyData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // Include document ID if needed
          // Adjust the timestamp conversion as per your needs
          timestampFormatted: doc
            .data()
            .timestamp?.toDate()
            .toLocaleDateString('ko-KR'),
        }));
        setHistoryList(historyData);
      } catch (error) {
        console.error('Error fetching inventory history for tenant:', error);
      }
    };

    fetchHistory();
  }, [productId, tenantId]); // Rerun when productId or tenantId changes

  return (
    <Paper sx={{ margin: '20px', overflow: 'hidden' }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        재고 변경 이력
      </Typography>
      <TableContainer>
        <Table aria-label="inventory history table">
          <TableHead>
            <TableRow>
              <TableCell>날짜</TableCell>
              <TableCell align="right">재고 변경량</TableCell>
              <TableCell>변경 사유</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyList.map((history, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {history.timestampFormatted || '날짜 정보 없음'}
                </TableCell>
                <TableCell align="right">{history.newStock}</TableCell>
                <TableCell>{history.reason || '사유 없음'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default InventoryHistory;
