import './css/chatheader.css'

export default function ChatHeader({ isOwner, imageUrl, title, state, onReservationClick,onScheduleClick, onWorkDoneClick, onPhoneCallClick, appointments }) {
    return (
      <div className="product-info">
        <div className="product-info-row1">
          <img src={imageUrl} alt={title} />
          <h5>{title}</h5>
          {appointments !== null &&
            <h5 style={{ marginLeft: '20px' , color: 'blue', fontSize: '20px' }}>약속시간은 {appointments} 입니다.</h5>
          }
        </div>
        <div className="product-info-row2">
          {isOwner&&
            <button onClick={onReservationClick}>예약하기</button>
          }
          <button onClick={onScheduleClick} disabled={state === 2} 
            style={{opacity: state === 2 ? 0.5 : 1}}>약속잡기</button>
          <button onClick={onPhoneCallClick} disabled={state === 2} 
            style={{opacity: state === 2 ? 0.5 : 1}}>전화걸기</button>
          {!isOwner&&
          <button onClick={onWorkDoneClick} disabled={state === 2} 
            style={{opacity: state === 2 ? 0.5 : 1}}>거래완료</button>
          }
        </div>
      </div>
    );
  }
  