import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Suspense,
  lazy,
  Fragment,
} from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  IconButton,
  Avatar,
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Inbox as InboxIcon,
  Mail as MailIcon,
  Apps,
  Menu,
  ContactMail,
  AssignmentInd,
  Home,
} from '@mui/icons-material';
import { auth } from './firebaseConfig';

import { GlobalProvider, useGlobalContext } from './useGlobalContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Login from './Login';
import Join from './Join';
import UserMenu from './UserMenu';

import NavBar from './NavBar';
import AppHeader from './Layout/AppHeader';
import AppFooter from './Layout/AppFooter';
import AppSideBar from './AppSideBar';
import LoginSideBar from './LoginSideBar';
import ThemeOptions from './Layout/ThemeOptions';

import bg1 from './assets/utils/images/originals/city.jpg';
import bg2 from './assets/utils/images/originals/citydark.jpg';
import bg3 from './assets/utils/images/originals/citynights.jpg';

import useSaveLastLocation from './useSaveLastLocation';

import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import AppRoutes from './AppRoutes';
// const Login = lazy(() => import("./DemoPages/Forms"));

function App() {
  let location = useLocation();
  let navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [activeMenu, setActiveMenu] = useState(''); // 초기 타이틀 설정
  const {
    updateTenantId,
    sidebarWidth,
    navbarHeight,
    tabbarHeight,
    textInputHeight,
  } = useGlobalContext();
  const [isMenuVisible, setIsMenuVisible] = useState(false); // 메뉴의 가시성
  const [menuWidth, setMenuWidth] = useState(200); // 메뉴의 너비

  const [open, setOpen] = useState(false);

  useSaveLastLocation();
  const toggleSlider = () => {
    setOpen(!open);
  };

  const [userStatus, setUserStatus] = useState(null);
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState(null);

  const userRoleMap = {
    0: '관리자',
    1: '판매자',
    2: '물류사',
    3: '화주사',
  };

  // 사용자 이름과 userType을 사용하여 문자열 생성
  const userRoleText = `${userName} ${userRoleMap[userType] || '역할 미정'}`;

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const lastLocation = localStorage.getItem('lastLocation');

    if (isLoggedIn && lastLocation) {
      navigate(lastLocation);
    }
  }, [navigate]);
  useEffect(() => {
    // currentUser가 있을 때만 Firestore 조회를 진행
    if (currentUser) {
      // 즉시 실행 함수를 사용하여 비동기 로직 처리
      (async () => {
        const userRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          updateTenantId(docSnap.data().tenantId);
          setUserName(docSnap.data().name);
          setUserType(docSnap.data().userType);
          setUserStatus(docSnap.data().status); // Firestore에서 가져온 사용자 상태를 설정
        } else {
          setUserStatus(null); // 문서가 없는 경우, 'unknown' 상태로 설정
        }
      })();
    } else {
      setUserStatus(null); // currentUser가 없는 경우, userStatus를 null로 설정
      setUserName('');
      setUserType(-1);
    }
  }, [currentUser]);
  // useEffect(() => {
  //   if (!currentUser) {
  //     // 로그인되지 않았으면 로그인 페이지로 이동하고, 로그인 후 돌아올 경로를 저장합니다.
  //     navigate('/login', { state: { from: location.pathname } });
  //   } else {
  //     // if (userStatus) {
  //     // navigate('/');
  //     // }
  //   }
  // }, [currentUser, userStatus, location.pathname]);

  const handleUserProfile = async () => {
    // const userId = currentUser.uid;
    // navigate(`user_profile/${userId}`);
  };

  const handleLogout = async () => {
    const userResponse = window.confirm('정말로 로그아웃하시겠습니까?');
    if (userResponse) {
      try {
        await auth.signOut();
        navigate('/login');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('lastLocation');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    }
  };
  return (
    <I18nextProvider i18n={i18n}>
      {/* <Router> */}
      {/* <NavBar setActiveMenu={setActiveMenu} style={{ position: 'fixed', top: 0, left: 0, zIndex: 1400, width: '100%' }}/> */}
      <Suspense fallback={<div>Loading...</div>}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 1301 }}>
              <img src={logoUrl} alt="Logo" style={{ height: '50px', margin: '10px' }} />
            </div> */}

          {currentUser && userStatus === 'active' ? (
            <>
              <AppBar
                position="fixed"
                sx={{
                  width: `calc(100%)`,
                  ml: `${sidebarWidth}px`,
                  backgroundColor: '#ffffff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <Toolbar>
                  <IconButton onClick={toggleSlider}>
                    <Menu />
                  </IconButton>
                  <Typography>Portfolio</Typography>
                  <Drawer open={open} anchor="left" onClose={toggleSlider}>
                    {/* {sideList()} */}
                    <AppSideBar
                      setActiveMenu={setActiveMenu}
                      currentUser={currentUser}
                      sidebarWidth={sidebarWidth}
                      toggleSlider={toggleSlider}
                    />
                  </Drawer>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{ flexGrow: 1, color: '#000000' }}
                  >
                    {activeMenu}
                  </Typography>

                  <Typography
                    variant="h6"
                    noWrap
                    sx={{ color: '#000000', mr: 2 }} // 오른쪽 여백을 주어 UserMenu와 구분
                  >
                    {userRoleText}
                  </Typography>

                  <UserMenu
                    user={currentUser}
                    handleUserProfile={handleUserProfile}
                    handleLogout={handleLogout}
                  />
                </Toolbar>
              </AppBar>

              <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
              >
                <Toolbar />
                <AppRoutes />
              </Box>
            </>
          ) : (
            // currentUser가 null일 때 렌더링할 컨텐츠
            <Box
              component="main"
              sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
              {/* <Typography variant="h4" sx={{ mt: 5, textAlign: 'center' }}>
                  Warehouse ERP 시스템에 오신걸 환영합니다.
                </Typography> */}
              <AppRoutes />

              <LoginSideBar
                setActiveMenu={setActiveMenu}
                sidebarWidth={sidebarWidth}
                toggleSlider={toggleSlider}
              />

              {/* <AppSideBar
                setActiveMenu={setActiveMenu}
                currentUser={currentUser}
                sidebarWidth={sidebarWidth}
                toggleSlider={toggleSlider}
              /> */}
            </Box>
          )}
        </Box>
      </Suspense>
      {/* </Router> */}
    </I18nextProvider>
  );
}

export default App;
