import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import {
  Button,
  TextField,
  Box,
  Typography,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import ReservationList from './ReservationList';
import dayjs from 'dayjs';
import { useGlobalContext } from '../useGlobalContext';

const AppointmentScheduling = () => {
  const { tenantId } = useGlobalContext();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!tenantId) return;

      const customersRef = collection(db, 'customers');
      const q = query(customersRef, where('tenantId', '==', tenantId));
      const querySnapshot = await getDocs(q);
      const customersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customersData);
    };

    fetchCustomers();
  }, [tenantId]);

  const handleCreateAppointment = async () => {
    if (!selectedCustomer || !tenantId) {
      setSnackbarMessage(
        'Please select a customer and ensure tenant ID is set before scheduling an appointment.'
      );
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    const appointmentsRef = collection(db, 'appointments');
    const startOfDay = selectedDateTime.startOf('day').toDate();
    const endOfDay = selectedDateTime.endOf('day').toDate();

    const q = query(
      appointmentsRef,
      where('dateTime', '>=', startOfDay),
      where('dateTime', '<=', endOfDay),
      where('tenantId', '==', tenantId)
    );
    const querySnapshot = await getDocs(q);

    let isSlotAvailable = true;
    querySnapshot.forEach((doc) => {
      if (
        doc.data().dateTime.toDate().getTime() ===
        selectedDateTime.toDate().getTime()
      ) {
        isSlotAvailable = false;
      }
    });

    if (!isSlotAvailable) {
      setSnackbarMessage('This slot is already booked.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    await addDoc(appointmentsRef, {
      customerId: selectedCustomer.id,
      customerName: selectedCustomer.name,
      dateTime: Timestamp.fromDate(selectedDateTime.toDate()),
      tenantId, // Include tenantId with each appointment for data isolation
    });

    setSnackbarMessage('Appointment scheduled successfully.');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper sx={{ padding: '20px', margin: '20px', overflow: 'hidden' }}>
          <Typography variant="h5" gutterBottom>
            Schedule an Appointment
          </Typography>
          <Box>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) => `${option.name} (${option.id})`}
              renderInput={(params) => (
                <TextField {...params} label="Select Customer" />
              )}
              value={selectedCustomer}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
              }}
              fullWidth
            />
            <DateTimePicker
              label="Select Date & Time"
              value={selectedDateTime}
              onChange={setSelectedDateTime}
              renderInput={(params) => <TextField {...params} />}
              minDateTime={dayjs()}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateAppointment}
              sx={{ mt: 2 }}
            >
              Schedule Appointment
            </Button>
          </Box>
        </Paper>
      </LocalizationProvider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <ReservationList />
    </>
  );
};

export default AppointmentScheduling;
