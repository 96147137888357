import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Login'; // Login 컴포넌트 경로에 맞게 조정
import Join from './Join'; // Join 컴포넌트 경로에 맞게 조정
import VendorDashboard from './Vendor/VendorDashboard'; // VendorDashboard 컴포넌트 경로에 맞게 조정
import ProtectedRoute from './ProtectedRoute'; // ProtectedRoute 컴포넌트 경로에 맞게 조정
import InventoryDashboard from './IMS/InventoryDashboard';
import CustomerDashboard from './CRM/CustomerDashboard';
import ReservationDashboard from './Reservation/ReservationDashboard';
import ProductManagement from './Products/ProductManagement';
import VendorDetailPage from './Vendor/VendorDetailPage';
import logoUrl from './assets/images/logo.png';
import StoreDashboard from './Store/StoreDashboard';
import StoreDetailPage from './Store/StoreDetailPage';
import SalesDashboard from './Sales/SalesDashboard';
import WarehouseDashboard from './WMS/WarehouseDashboard';
import UserProfile from './UserProfile';
import ChatList from './ChatList';
import ChatView from './ChatView';
import PromotionProductRegistration from './WMS/PromotionProductRegistration';
import CalendarExample from './DemoPages/Components/Calendar';
import CarouselExample from './DemoPages/Components/Carousel';
import CarouselBSExample from './DemoPages/Components/Carousel/Examples/Carousel';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/join" element={<Join />} />

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <VendorDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vendor-management"
        element={
          <ProtectedRoute>
            <VendorDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory-management"
        element={
          <ProtectedRoute>
            <InventoryDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/warehouse-management"
        element={
          <ProtectedRoute>
            <WarehouseDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer-management"
        element={
          <ProtectedRoute>
            <CustomerDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reservation-management"
        element={
          <ProtectedRoute>
            <ReservationDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-management"
        element={
          <ProtectedRoute>
            <ProductManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/store-management"
        element={
          <ProtectedRoute>
            <StoreDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales-management"
        element={
          <ProtectedRoute>
            <SalesDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vendor/:vendorId"
        element={
          <ProtectedRoute>
            <VendorDetailPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/store/:storeId"
        element={
          <ProtectedRoute>
            <StoreDetailPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chatlist/:storeId"
        element={
          <ProtectedRoute>
            <ChatList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chatview/:chatRoomId"
        element={
          <ProtectedRoute>
            <ChatView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/user_profile/:userId"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/register-promotion"
        element={<PromotionProductRegistration />}
      />
      {/* Carousel */}
      <Route path={`/carousel`} component={CarouselExample} />

      {/* Calendar */}
      <Route path={`/calendar`} component={CalendarExample} />
    </Routes>
  );
};

export default AppRoutes;
