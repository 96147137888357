// store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import searchKeywordReducer from "./reducers/searchReducer";
import editUserReducer from "./reducers/editUserReducer";
import orderReducer from './reducers/orderReducer';
import productReducer from './reducers/ProductSlice'; // Assuming productSlice is in the same directory
import themeOptionsReducer from './reducers/ThemeOptionsSlice'; // 슬라이스의 위치에 맞게 경로를 조정하세요

const store = configureStore({
  reducer: {
    auth: authReducer,
    search: searchKeywordReducer,
    edit: editUserReducer,
    order: orderReducer,
    products: productReducer,
    themeOptions: themeOptionsReducer,
  }
  ,devTools: process.env.NODE_ENV !== 'production',
});

export default store;
