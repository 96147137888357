import React, { useState, useEffect,Suspense, lazy } from 'react';
import { Box, Tab, Tabs, Typography, Paper, IconButton, CircularProgress } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";

const CustomerDetail = lazy(() => import('./CustomerDetail'));
const ChatList = lazy(() => import('../ChatList'));
const ChatView = lazy(() => import('../ChatView'));
const CustomerEmailIntegration = lazy(() => import('./CustomerEmailIntegration'));
const CustomerForm = lazy(() => import('./CustomerForm'));
const CustomerList = lazy(() => import('./CustomerList'));
const CustomerSearch = lazy(() => import('./CustomerSearch'));
const CustomerServiceHistory = lazy(() => import('./CustomerServiceHistory'));
const CustomerSupportTickets = lazy(() => import('./CustomerSupportTickets'));


const CustomerDashboard = () => {

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const navigate = useNavigate();

  // 고객 선택 시 탭 인터페이스로 이동
  const handleCustomerSelect = (customerId) => {
    setSelectedCustomerId(customerId);
    setTabValue('1'); // 기본적으로 고객 상세 정보 탭을 표시
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBack = () => {
    setSelectedCustomerId(null); // 고객 목록으로 돌아가기
  };

  const tabComponents = {
    '1': ChatView,
    '2': CustomerEmailIntegration,
    '3': CustomerForm,
    // '5': CustomerList, 
    // '6': CustomerSearch 제외
    '4': CustomerServiceHistory,
    '5': CustomerSupportTickets,
    '6': CustomerDetail,
  };
  
  const renderTabComponent = (tabValue) => {
    const TabComponent = tabComponents[tabValue];
    return <TabComponent customerId={selectedCustomerId} />;
  };

  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      {!selectedCustomerId ? (
        <>
          <Suspense fallback={<CircularProgress />}>
            <CustomerSearch onCustomerSelect={handleCustomerSelect} />
            <CustomerList onCustomerSelect={handleCustomerSelect} />            
          </Suspense>
        </>
      ) : (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', position: 'sticky', top: 0, zIndex: 1 }}>
            <IconButton onClick={handleBack} sx={{ marginRight: 2 }}>
              <ArrowBack />
            </IconButton>
            <Tabs value={tabValue} onChange={handleChange} aria-label="customer tabs" variant="scrollable" scrollButtons="auto">
              {Object.keys(tabComponents).map(key => (
                <Tab key={key} label={getTabLabelByKey(key)} value={key} />
              ))}
            </Tabs>
          </Box>
          <Suspense fallback={<CircularProgress />}>
            {renderTabComponent(tabValue)}
          </Suspense>
        </TabContext>
      )}
      <ChatList />
    </Paper>    
  );
};

const getTabLabelByKey = (key) => {
  // 각 키에 따른 탭 라벨을 반환하는 함수, 예: '1' => 'Details'
  const labels = {
    '1': 'ChatView',
    '2': 'Email Integration',
    '3': 'Edit Customer',
    '4': 'Service History',
    '5': 'Support Tickets',
    '6': 'Details',
  };
  return labels[key] || 'Unknown';
};

export default CustomerDashboard;



// function TabPanelWrapper(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// const CustomerDashboard = ({ customerId }) => {
//   const [tabValue, setTabValue] = useState('1');
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // 예시: 데이터 로딩 상태 관리
//     setIsLoading(true);
//     // API 호출을 통한 데이터 로딩 로직 (가정)
//     setTimeout(() => setIsLoading(false), 2000); // 2초 후 로딩 상태 해제
//   }, []);

  
//   return (
//     <Paper sx={{ width: '100%' }}>
//       <TabContext value={tabValue}>
//       <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', flexWrap: 'wrap', position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'background.paper' }}>

//           <IconButton onClick={handleBack} sx={{ marginRight: 2 }}>
//             <ArrowBack />
//           </IconButton>
//           <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
//             <Tab label="Details" value="1" />
//             <Tab label="Communication Log" value="2" />
//             <Tab label="Email Integration" value="3" />
//             <Tab label="Edit Customer" value="4" />
//             <Tab label="Customer List" value="5" />
//             <Tab label="Search Customer" value="6" />
//             <Tab label="Service History" value="7" />
//             <Tab label="Support Tickets" value="8" />
//           </Tabs>
//         </Box>
//         <Suspense fallback={<div>Loading...</div>}>
//           {renderTabComponent(tabValue, customerId)}
//         </Suspense>
//         {/* <TabPanelWrapper value={tabValue} index="1">
//           <CustomerDetail customerId={customerId} />
//         </TabPanelWrapper> */}
//         {/* 다른 TabPanelWrapper 컴포넌트들 */}
//       </TabContext>
//     </Paper>
//   );
// };

// export default CustomerDashboard;
