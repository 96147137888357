import { initializeApp } from 'firebase/app';
import { 
    getFirestore,
    collection, 
    doc, 
    addDoc, 
    updateDoc,
    deleteDoc, 
    setDoc, 
    query, 
    where, 
    getDoc, 
    getDocs, 
    onSnapshot, 
    orderBy, 
    increment,
    arrayUnion, 
    arrayRemove,
    writeBatch,  
    limit,
    FieldValue,
    Timestamp,
    serverTimestamp,
    startAfter,
    runTransaction,
} from 'firebase/firestore';
import { 
    getAuth,
    updateProfile,
    RecaptchaVerifier,
    GoogleAuthProvider, 
    signInWithPopup,
    signInWithCustomToken,
    onAuthStateChanged, 
    signInWithPhoneNumber, 
    signInWithEmailAndPassword, 
    signOut,
    sendEmailVerification,
    createUserWithEmailAndPassword,
    EmailAuthProvider,PhoneAuthProvider,updatePassword,
    signInWithCredential,
    reauthenticateWithCredential,
    linkWithCredential,
    reload,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { 
    getStorage,
    ref, 
    uploadBytesResumable, 
    getDownloadURL,
    uploadBytes
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAM0tFPHeJ0zpbJxtIpTfMGvLJ2XB9fsa0",
  authDomain: "warehousemanagementsyste-334f8.firebaseapp.com",
  projectId: "warehousemanagementsyste-334f8",
  storageBucket: "warehousemanagementsyste-334f8.appspot.com",
  messagingSenderId: "801622830529",
  appId: "1:801622830529:web:ae9375044a5a2afa559340",
  measurementId: "G-E0Q3BV8DN0"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
const getKakaoToken = httpsCallable(functions, 'getCustomToken');
export {
    firebaseApp,
    db, 
    collection, 
    doc, 
    addDoc, 
    updateDoc, 
    setDoc,
    deleteDoc, 
    query, 
    where, 
    getDoc, 
    getDocs, 
    onSnapshot, 
    orderBy, 
    limit,
    startAfter,
    runTransaction,
    auth, 
    RecaptchaVerifier,
    GoogleAuthProvider, 
    signInWithPopup,
    signInWithCustomToken,
    onAuthStateChanged, 
    signInWithPhoneNumber, 
    signInWithEmailAndPassword,
    signInWithCredential,
    signOut,
    EmailAuthProvider, 
    PhoneAuthProvider,
    linkWithCredential,
    updatePassword,
    reauthenticateWithCredential,
    analytics,
    storage,
    ref, 
    uploadBytesResumable, 
    getDownloadURL,
    uploadBytes,
    functions,
    updateProfile,
    increment,
    arrayUnion, 
    arrayRemove,
    writeBatch,
    sendEmailVerification,
    createUserWithEmailAndPassword,
    reload,
    FieldValue,
    Timestamp,
    serverTimestamp,
    getKakaoToken,
  };