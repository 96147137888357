import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const InventorySorter = ({ sortBy, setSortBy }) => {
  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, marginBottom: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="inventory-sort-label">정렬 기준</InputLabel>
        <Select
          labelId="inventory-sort-label"
          id="inventory-sort"
          value={sortBy}
          label="정렬 기준"
          onChange={handleChange}
        >
          <MenuItem value="name">이름</MenuItem>
          <MenuItem value="stockAsc">재고 수량: 오름차순</MenuItem>
          <MenuItem value="stockDesc">재고 수량: 내림차순</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default InventorySorter;
