// dataUtils.js
export const fetchHistoricalData = async (storeId, startDate, endDate) => {
    // Simulate fetching data for the selected store within the date range from Firestore
    const data = [
      { date: "2023-01-01", sales: 200 },
      { date: "2023-01-02", sales: 250 },
      // Add more simulated data here
    ];
  
    // Filter the data based on startDate and endDate
    return data.filter(d => {
      const date = new Date(d.date);
      return date >= startDate && date <= endDate;
    });
  };
  