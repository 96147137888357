import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

//CustomerManagement 컴포넌트는 고객의 예약 이력, 선호도, 연락처 정보 등을 관리하고,
// 이를 바탕으로 개인화된 서비스를 제공하기 위한 데이터베이스 기능을 포함합니다.
// React와 Material UI를 사용하여 구현하고, Firestore를 데이터베이스로 활용하여 고객 정보를 저장하고 관리합니다.
const CustomerManagement = () => {
  const { tenantId } = useGlobalContext();
  const [customers, setCustomers] = useState([]);
  const [newCustomer, setNewCustomer] = useState({
    name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!tenantId) return; // Ensure tenantId is available

      // Adjust the query to fetch only customers for the current tenant
      const customersRef = collection(db, 'customers');
      const q = query(customersRef, where('tenantId', '==', tenantId));
      const querySnapshot = await getDocs(q);
      setCustomers(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchCustomers();
  }, [tenantId]); // Re-fetch when tenantId changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const handleAddCustomer = async () => {
    if (!tenantId) return; // Ensure tenantId is available

    await addDoc(collection(db, 'customers'), {
      ...newCustomer,
      tenantId, // Include tenantId when adding a new customer
    });
    setNewCustomer({ name: '', email: '', phone: '' }); // Reset form
    // Optionally, update customer list after adding new customer
    // fetchCustomers(); // Uncomment if you wish to immediately reflect the new customer in the list
  };

  // Implement functions for editing and deleting customers, ensuring they consider tenantId

  return (
    <Box>
      <Typography variant="h6">고객 관리</Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Name"
          name="name"
          value={newCustomer.name}
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          name="email"
          value={newCustomer.email}
          onChange={handleInputChange}
        />
        <TextField
          label="Phone"
          name="phone"
          value={newCustomer.phone}
          onChange={handleInputChange}
        />
        <Button onClick={handleAddCustomer}>고객 추가</Button>
      </Box>
      {/* Display customer list and implement UI for editing and deleting customer information */}
    </Box>
  );
};

export default CustomerManagement;
