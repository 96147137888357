import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from '../AppLogo';
import SearchBox from './Components/SearchBox';
import UserBox from './Components/UserBox';

const Header = () => {
  const {
    enableHeaderShadow,
    closedSmallerSidebar,
    headerBackgroundColor,
    enableMobileMenuSmall,
  } = useSelector(state => state.themeOptions);

  const headerClasses = `app-header ${headerBackgroundColor} ${enableHeaderShadow ? 'header-shadow' : ''}`;
  const headerContentClasses = `app-header__content ${enableMobileMenuSmall ? 'header-mobile-open' : ''}`;

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={headerClasses}
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <div>
            <HeaderLogo />
            <div className={headerContentClasses}>
              <div className="app-header-left">
                <SearchBox />
              </div>
              <div className="app-header-right">
                <UserBox />
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
};

export default Header;
