import { useState,useRef } from 'react';
import MessageBox from './MessageBox';

function useMessageBox() {
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [type, setType] = useState('ok'); // default type
  // const [resolve, setResolve] = useState(null);
  const resolveRef = useRef(null); // useRef를 사용하여 resolve를 저장

  const MessageBoxInform = (infoTitle, infoContent) => {
    return new Promise((res) => {
      setType('ok');
      setTitle(infoTitle);
      setContent(infoContent);
      setIsVisible(true);
      resolveRef.current = res;
    });
  };

  const MessageBoxConfirm = (confirmTitle, confirmContent) => {
    return new Promise((res) => {
      setType('yesNo');
      setTitle(confirmTitle);
      setContent(confirmContent);
      setIsVisible(true);
      // setResolve(res); // Store the resolver function.
      resolveRef.current = res; // resolver를 여기에 저장
    });

  };


  const handleOk = () => {
    
    if (resolveRef.current) {
      resolveRef.current(true);
      resolveRef.current = null;
    }

    setIsVisible(false);
  };

  const handleYes = () => {
    
    if (resolveRef.current) {
      resolveRef.current(true);
      resolveRef.current = null;
    }

    setIsVisible(false);
  };

  const handleNo = () => {
    
    if (resolveRef.current) {
      resolveRef.current(false);
      resolveRef.current = null;
    }

    setIsVisible(false);
  };
  const handleClose = () => {
    
    if (resolveRef.current) {
      resolveRef.current(true);
      resolveRef.current = null;
    }
    setIsVisible(false);
  };
  const MessageBoxRender = () => {
    if (!isVisible) return null;

    return (
      <MessageBox
        title={title}
        content={content}
        onOk={handleOk}
        onYes={handleYes}
        onNo={handleNo}
        onClose={handleClose}
        type={type}
      />
    );
  };

  return [MessageBoxInform, MessageBoxConfirm, MessageBoxRender];
}

export default useMessageBox;
