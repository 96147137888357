// src/ProductMapping.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const ProductMapping = () => {
  const { tenantId } = useGlobalContext();
  const [ecommerceProducts, setEcommerceProducts] = useState([]);
  const [wmsProducts, setWmsProducts] = useState([]);
  const [selectedEcommerceProduct, setSelectedEcommerceProduct] = useState('');
  const [selectedWmsProduct, setSelectedWmsProduct] = useState('');
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // 이커머스 상품 리스트를 가져오는 함수
  const fetchEcommerceProducts = async () => {
    const storeId = 'lh2tmp3QPDdYadEmbi9kzrYUoPz2'; // 적절한 storeId 값으로 대체해주세요.
    const functionUrl = `https://asia-northeast3-apple-market-af8c6.cloudfunctions.net/getProductsInStore?storeId=${storeId}`;

    try {
      setLoading(true);
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ storeId }), // storeId를 JSON 객체로 전송
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setEcommerceProducts(data); // 이커머스 상품 리스트 상태 업데이트
    } catch (error) {
      setError(error.message);
      console.error('Error fetching ecommerce products:', error);
    } finally {
      setLoading(false);
    }
  };
  // WMS 상품 리스트를 가져오는 함수
  const fetchWmsProducts = async () => {
    const productsRef = collection(db, 'products');
    const q = query(productsRef, where('tenantId', '==', tenantId));

    try {
      const querySnapshot = await getDocs(q); // 'await' 키워드를 사용하여 비동기적으로 문서 스냅샷 가져오기
      const products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWmsProducts(products); // 상품 목록 상태 업데이트
    } catch (error) {
      console.error('Error fetching WMS products:', error);
    }
  };
  // WMS 상품 리스트를 가져오는 함수
  const handleMapProducts = () => {
    if (!selectedEcommerceProduct || !selectedWmsProduct) {
      alert('두 상품 모두 선택해야 합니다.');
      return;
    }

    // 새 맵핑 정보 생성
    const newMapping = {
      ecommerceProductId: selectedEcommerceProduct,
      wmsProductId: selectedWmsProduct,
    };

    // 중복 맵핑 방지
    const isAlreadyMapped = mappings.some(
      (mapping) =>
        mapping.ecommerceProductId === selectedEcommerceProduct ||
        mapping.wmsProductId === selectedWmsProduct
    );

    if (isAlreadyMapped) {
      alert('이미 맵핑된 상품입니다.');
      return;
    }

    // 맵핑 정보 상태 업데이트
    setMappings([...mappings, newMapping]);

    // 선택 초기화
    setSelectedEcommerceProduct('');
    setSelectedWmsProduct('');
  };

  useEffect(() => {
    fetchEcommerceProducts();
    fetchWmsProducts();
  }, []);

  return (
    <Paper style={{ margin: 16, padding: 16 }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        이커머스 상품 연동 리스트
      </Typography>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}

        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="ecommerce-product-select-label">
              E-commerce Product
            </InputLabel>
            <Select
              labelId="ecommerce-product-select-label"
              id="ecommerce-product-select"
              value={selectedEcommerceProduct}
              label="E-commerce Product"
              onChange={(e) => setSelectedEcommerceProduct(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {ecommerceProducts.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="wms-product-select-label">WMS Product</InputLabel>
            <Select
              labelId="wms-product-select-label"
              id="wms-product-select"
              value={selectedWmsProduct}
              label="WMS Product"
              onChange={(e) => setSelectedWmsProduct(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {wmsProducts.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Button variant="contained" onClick={handleMapProducts}>
          이커머스 상품 연결
        </Button>

        <ul>
          {mappings.map((mapping, index) => (
            <li key={index}>
              E-commerce Product ID: {mapping.ecommerceProductId}, WMS Product
              ID: {mapping.wmsProductId}
            </li>
          ))}
        </ul>
      </Box>
    </Paper>
  );
};

export default ProductMapping;
