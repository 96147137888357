import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { query, where, collection, onSnapshot } from 'firebase/firestore';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Link } from 'react-router-dom';
import CustomerAcquisitionFunnel from './DynamicSalesReportGenerator/StoreReportTemplates';
import ExportOptions from './DynamicSalesReportGenerator/StoreReportExport';
import HistoricalDataComparison from './DynamicSalesReportGenerator/StoreSalesComparison';
import { useGlobalContext } from '../useGlobalContext';

const SalesDashboard = () => {
  const { tenantId } = useGlobalContext();
  const [sales, setSales] = useState([]);

  useEffect(() => {
    // Adjust the Firestore query to include tenantId
    const salesQuery = query(
      collection(db, 'sales'),
      where('tenantId', '==', tenantId)
    );

    const unsubscribe = onSnapshot(salesQuery, (snapshot) => {
      const newSales = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSales(newSales);
    });

    return () => unsubscribe();
  }, [tenantId]); // Re-run this effect if tenantId changes

  const salesData = sales.map((sale) => ({
    name: sale.name,
    sales: sale.sales || 0,
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Sales Data Visualization
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={salesData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <ExportOptions data={salesData} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Sales and Performance Analytics
            </Typography>
            <CustomerAcquisitionFunnel data={salesData} />
            <HistoricalDataComparison data={salesData} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              List of Stores
            </Typography>
            <List>
              {sales.map((sale) => (
                <ListItem
                  button
                  component={Link}
                  to={`/sales/${sale.id}`}
                  key={sale.id}
                >
                  <ListItemText
                    primary={sale.name}
                    secondary={`Sales: ${sale.sales || 'No data available'}`}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SalesDashboard;
