import React from 'react';

export default function ChatContent({ url, isImage, isVideo, onFileClick }) {
    return (
      <div onClick={() => onFileClick(url)}>
        {isImage ? (
          <img src={url} alt="Uploaded content" width="300" />
        ) : isVideo ? (
          <video controls width="300">
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <a href={url} target="_blank" rel="noopener noreferrer">Download File</a>
        )}
      </div>
    );
  }
  