import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useGlobalContext } from '../useGlobalContext';

const ProductLotCodeRegistration = ({ onClose, onRegistered }) => {
  const [manufactureDate, setManufactureDate] = useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const { tenantId } = useGlobalContext();

  const generateLotCode = () => {
    const dateCode = manufactureDate.replace(/-/g, '');
    return `LOT${dateCode}-${batchNumber}`;
  };

  const handleSubmit = async () => {
    const lotCode = generateLotCode();
    try {
      await addDoc(collection(db, 'productLotCodes'), {
        code: lotCode,
        tenantId: tenantId,
      });
      console.log('Lot Code Registered:', lotCode);
      onRegistered && onRegistered(lotCode); // 부모 컴포넌트에 로트 코드 전달
      onClose(); // Close the dialog upon submission
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Register Product Lot Code</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="manufactureDate"
              label="Manufacture Date"
              type="date"
              fullWidth
              variant="standard"
              value={manufactureDate}
              onChange={(e) => setManufactureDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="batchNumber"
              label="Batch Number"
              type="text"
              fullWidth
              variant="standard"
              value={batchNumber}
              onChange={(e) => setBatchNumber(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Register</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductLotCodeRegistration;
