// ProductManagement.js
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import ProductRegistration from './ProductRegistration';
import ProductList from './ProductList';
import CategoryRegistration from './CategoryRegistration';
import ProductSKURegistration from './ProductSKURegistration';
import ProductCodeRegistration from './ProductCodeRegistration';
import { useGlobalContext } from '../useGlobalContext';
import ProductUpdateDialog from './ProductUpdateDialog';
import CartonBoxUpdate from './CartonBoxUpdateDialog';
import CartonBoxList from './CartonBoxList';
import ProductMapping from './ProductMapping';
import PalletList from './PalletList';

// const ProductManagement = () => {
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const { tenantId } = useGlobalContext();
//   const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
//   const [openProductRegistrationDialog, setOpenProductRegistrationDialog] =
//     useState(false);

//   const handleOpenProductRegistrationDialog = () => {
//     setOpenProductRegistrationDialog(true);
//   };

//   const handleCloseProductRegistrationDialog = () => {
//     setOpenProductRegistrationDialog(false);
//   };

//   // 상품 정보 업데이트 핸들러
//   const handleProductInfoUpdate = (updatedInfo) => {
//     setSelectedProduct(updatedInfo);
//     // 필요한 경우, 추가적인 로직 수행
//   };

//   // 카톤박스 정보 업데이트 핸들러
//   const handleCartonBoxInfoUpdate = (updatedInfo) => {
//     // setCartonBoxInfo(updatedInfo);
//     // 필요한 경우, 추가적인 로직 수행
//   };
//   const handleSelectProduct = (product) => {
//     setSelectedProduct(product);
//     // 필요하다면, 카톤박스 정보도 여기에서 설정할 수 있습니다.
//   };
//   const handleOpenUpdateDialog = (productCode) => {
//     setIsUpdateDialogOpen(true);
//   };

//   const handleCloseUpdateDialog = () => {
//     setIsUpdateDialogOpen(false);
//   };
//   return (
//     <div>
//       <Box display="flex" justifyContent="flex-start" mb={2}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleOpenProductRegistrationDialog}
//           mr={2} // 오른쪽 마진 추가
//         >
//           출고 상품 등록
//         </Button>
//         {/* Material UI v5 이상에서 sx prop을 사용하여 스타일 적용 */}
//       </Box>
//       <Dialog
//         open={openProductRegistrationDialog}
//         onClose={handleCloseProductRegistrationDialog}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>출고 상품 등록</DialogTitle>
//         <DialogContent>
//           <ProductRegistration tenantId={tenantId} />
//         </DialogContent>
//       </Dialog>

//       <ProductList onSelect={handleSelectProduct} />
//       {selectedProduct && (
//         <ProductUpdateDialog
//           productCode={selectedProduct.productId}
//           onUpdate={handleProductInfoUpdate}
//           open={isUpdateDialogOpen}
//           onClose={handleCloseUpdateDialog}
//         />
//       )}
//       {/* 선택된 상품에 대한 카톤박스 정보가 있다면, CartonBoxUpdate에도 전달 */}
//       {selectedProduct && (
//         <CartonBoxUpdate
//           cartonBoxId={selectedProduct.cartonBoxId}
//           onUpdate={handleCartonBoxInfoUpdate}
//         />
//       )}
//       <PalletList />
//       <CartonBoxList />
//       <ProductMapping />
//     </div>
//   );
// };

// export default ProductManagement;
const ProductManagement = () => {
  const { tenantId } = useGlobalContext();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dialogOpen, setDialogOpen] = useState({
    register: false,
    update: false,
  });

  const handleDialogOpen = (dialog) =>
    setDialogOpen((prev) => ({ ...prev, [dialog]: true }));
  const handleDialogClose = (dialog) =>
    setDialogOpen((prev) => ({ ...prev, [dialog]: false }));

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    handleDialogOpen('update');
  };

  const handleUpdateProduct = (updatedInfo) => {
    setSelectedProduct(updatedInfo); // Implement further logic or API calls if necessary
  };

  return (
    <Paper style={{ margin: 16, padding: 16 }}>
      <Typography variant="h6" sx={{ margin: '20px' }}>
        Product List
      </Typography>
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen('register')}
        >
          Register New Product
        </Button>
      </Box>

      <Dialog
        open={dialogOpen.register}
        onClose={() => handleDialogClose('register')}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Register New Product</DialogTitle>
        <DialogContent>
          <ProductRegistration tenantId={tenantId} />
        </DialogContent>
      </Dialog>

      <ProductList onSelect={handleSelectProduct} />

      {selectedProduct && (
        <ProductUpdateDialog
          productCode={selectedProduct.productId}
          onUpdate={handleUpdateProduct}
          open={dialogOpen.update}
          onClose={() => handleDialogClose('update')}
        />
      )}

      <CartonBoxList />
      <PalletList />
      <ProductMapping />
    </Paper>
  );
};

export default ProductManagement;
