import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

function PalletAddDialog({ open, onClose }) {
  const [newPallet, setNewPallet] = useState({
    length: '',
    width: '',
    height: '',
    weightCapacity: '',
    material: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPallet((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdd = async () => {
    if (
      newPallet.length &&
      newPallet.width &&
      newPallet.height &&
      newPallet.weightCapacity &&
      newPallet.material
    ) {
      await addDoc(collection(db, 'pallets'), newPallet);
      onClose(); // Close dialog after addition
    } else {
      alert('Please fill all fields.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Pallet</DialogTitle>
      <DialogContent>
        <TextField
          label="Length"
          name="length"
          value={newPallet.length}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Width"
          name="width"
          value={newPallet.width}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Height"
          name="height"
          value={newPallet.height}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Weight Capacity"
          name="weightCapacity"
          value={newPallet.weightCapacity}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Material"
          name="material"
          value={newPallet.material}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}
export default PalletAddDialog;
