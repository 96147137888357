import React, { useState, useEffect, useRef } from 'react';

import './css/UserMenu.css';

function UserMenu({ user, handleUserProfile, handleLogout }) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
 
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      window.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="profile-container" ref={menuRef}>
      <img 
        className="profile-image"
        src={user.photoURL || 'https://via.placeholder.com/150'} 
        alt="User Profile" 
        onClick={toggleMenu}
      />

      {showMenu && (
        <div className="profile-popup-menu">
          <div className="profile-popup-item" onClick={handleUserProfile}>
            {user.email}
          </div>
          
          <div className="profile-popup-item logout" onClick={handleLogout}>
            로그아웃
          </div>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
