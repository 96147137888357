import React from 'react';
import { TextField, Grid } from '@mui/material';

const ProductSizeXYZ = ({ dimensions, onDimensionChange }) => {
  // This function now directly takes the dimension name and the event
  const handleDimensionChange = (dimensionName) => (event) => {
    const { value } = event.target; // Get the value from the event object
    onDimensionChange(dimensionName, value); // Pass the dimension name and value to the handler
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Width (cm)"
          type="number"
          variant="outlined"
          value={dimensions.x || ''} // Ensure the value is not undefined
          onChange={handleDimensionChange('x')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Height (cm)"
          type="number"
          variant="outlined"
          value={dimensions.y || ''} // Ensure the value is not undefined
          onChange={handleDimensionChange('y')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Depth (cm)"
          type="number"
          variant="outlined"
          value={dimensions.z || ''} // Ensure the value is not undefined
          onChange={handleDimensionChange('z')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Weight (g)"
          name="weight"
          type="number"
          value={dimensions.w || ''} // Ensure the value is not undefined
          onChange={handleDimensionChange('w')}
        />
      </Grid>
    </Grid>
  );
};

export default ProductSizeXYZ;
