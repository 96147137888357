import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import BarcodeGenerator from '../WMS/BarcodeGenerator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useGlobalContext } from '../useGlobalContext';
import ProductSKURegistration from './ProductSKURegistration';
import ProductCodeRegistration from './ProductCodeRegistration';
import ProductLotCodeRegistration from './ProductLotCodeRegistration';
import CategoryRegistration from './CategoryRegistration';
import ProductSizeXYZ from './ProductSizeXYZ';

const ProductRegistration = ({ tenantId }) => {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [products, setProducts] = useState([]);
  const [cartonBoxes, setCartonBoxes] = useState([]);
  const [pallets, setPallets] = useState([]);

  const [selectedVendorId, setSelectedVendorId] = useState('');
  const [selectedVendorName, setSelectedVendorName] = useState('');
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [selectedProductLotCode, setSelectedProductLotCode] = useState('');
  const [selectedProductSKU, setSelectedProductSKU] = useState('');
  const [productCodes, setProductCodes] = useState([]);
  const [productSKUs, setProductSKUs] = useState([]);
  const [isProductCodeDialogOpen, setIsProductCodeDialogOpen] = useState(false);
  const [isProductSKUDialogOpen, setIsProductSKUDialogOpen] = useState(false);
  const [isProductLotCodeDialogOpen, setIsProductLotCodeDialogOpen] =
    useState(false);
  const [openCategoryRegistrationDialog, setOpenCategoryRegistrationDialog] =
    useState(false);
  // const [barcode, setBarcode] = useState('');

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [product, setProduct] = useState({
    vendorId: '',
    productCode: '',
    dimensions: {
      x: '',
      y: '',
      z: '',
      w: '',
    },
    categoryId: '',
    barcode: '',
    lotcode: '',
    serialNumber: '',
    attributes: [{ key: '', value: '' }],
  });
  const [dimensions, setDimensions] = useState({
    x: '',
    y: '',
    z: '',
    w: '',
  });
  // 거래처 정보를 가져오는 함수
  const fetchVendors = async () => {
    const vendorsCollectionRef = collection(db, 'vendors');
    const q = query(vendorsCollectionRef, where('tenantId', '==', tenantId));
    const querySnapshot = await getDocs(q);
    setVendors(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  // 상품 정보를 가져오는 함수
  const fetchProducts = async () => {
    const productsCollectionRef = collection(db, 'products');
    const q = query(productsCollectionRef, where('tenantId', '==', tenantId));
    const querySnapshot = await getDocs(q);
    setProducts(
      querySnapshot.docs.map((doc) => ({ code: doc.id, ...doc.data() }))
    );
  };

  const fetchProductCodes = async () => {
    const productsCollectionRef = collection(db, 'productCodes');
    const q = query(productsCollectionRef, where('tenantId', '==', tenantId));
    const querySnapshot = await getDocs(q);
    setProductCodes(
      querySnapshot.docs.map((doc) => ({ code: doc.id, ...doc.data() }))
    );
  };
  const fetchCategories = async () => {
    const categoriesCollectionRef = collection(db, 'categories');
    const q = query(categoriesCollectionRef, where('tenantId', '==', tenantId));
    const querySnapshot = await getDocs(q);
    setCategories(
      querySnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
    );
  };

  const fetchProductSKUs = async () => {
    const productSKUsCollectionRef = collection(db, 'productSKUs');
    const q = query(
      productSKUsCollectionRef,
      where('tenantId', '==', tenantId)
    );
    const querySnapshot = await getDocs(q);
    setProductSKUs(
      querySnapshot.docs.map((doc) => ({ code: doc.id, ...doc.data() }))
    );
  };

  const fetchCartonBoxesAndPallets = async () => {
    const cartonBoxesSnapshot = await getDocs(collection(db, 'cartonBoxes'));
    const palletsSnapshot = await getDocs(collection(db, 'pallets'));
    setCartonBoxes(
      cartonBoxesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
    setPallets(
      palletsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  useEffect(() => {
    if (tenantId) {
      fetchCartonBoxesAndPallets();
    }
  }, [tenantId]);

  useEffect(() => {
    // 초기 데이터 로딩 함수들...
    setLoading(true); // 데이터 로딩 시작
    const fetchData = async () => {
      // 모든 fetch 함수를 여기에 병렬로 실행
      try {
        await Promise.all([
          fetchVendors(),
          fetchProducts(),
          fetchProductCodes(),
          fetchProductSKUs(),
          fetchCategories(),
        ]);
      } catch (error) {
        console.error('Error loading data:', error);
        // 오류 처리...
      } finally {
        setLoading(false); // 데이터 로딩 완료
      }
    };

    if (tenantId) {
      fetchData();
    }
  }, [tenantId]);

  // 거래처 ID 선택 시 실행되는 함수
  const handleVendorChange = (event) => {
    const vendorId = event.target.value;
    setProduct((prev) => ({ ...prev, vendorId: vendorId }));

    const selectedVendor = vendors.find((vendor) => vendor.id === vendorId);
    setSelectedVendorName(selectedVendor ? selectedVendor.name : '');
  };

  // 상품 코드 선택 시 실행되는 함수
  const handleProductCodeChange = (event) => {
    const productCode = event.target.value;
    setSelectedProductCode(productCode);

    setProduct((prevProduct) => ({
      ...prevProduct,
      productCode: productCode,
      barcode: `${productCode}-${selectedProductSKU}`,
    }));
    // const productDetails = products.find((p) => p.productCode === productCode);
    // if (productDetails) {
    //   setProduct({
    //     ...product,
    //     productCode: productCode,
    //     productName: productDetails.name,
    //     category: productDetails.category,
    //   });
    // }
  };

  const handleProductSKUChange = (event) => {
    const productSKU = event.target.value;
    setSelectedProductSKU(productSKU);

    setProduct((prevProduct) => ({
      ...prevProduct,
      sku: productSKU,
      barcode: `${selectedProductCode}-${productSKU}`,
    }));
    // const productDetails = products.find((p) => p.productSKU === productSKU);
    // if (productDetails) {
    //   setProduct({
    //     ...product,
    //     productSKU: productSKU,
    //     productName: productDetails.name,
    //     category: productDetails.category,
    //   });
    // }
  };
  const handleProductnameChange = (event) => {
    const { value } = event.target;
    setProduct((prev) => ({ ...prev, productName: value }));
  };
  const handleBarcodeChange = (e) => {
    const { value } = e.target;
    setProduct((prev) => ({ ...prev, barcode: value }));
  };
  const handleLotcodeChange = (e) => {
    const { value } = e.target;
    setProduct((prev) => ({ ...prev, lotcode: value }));
  };
  const handleSerialNumberChange = (e) => {
    const { value } = e.target;
    setProduct((prev) => ({ ...prev, serialNumber: value }));
  };

  const handleBarcodeGenerated = (generatedBarcode) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      barcode: generatedBarcode,
    }));
  };

  const handleAttributeChange = (index, e) => {
    const newAttributes = [...product.attributes];
    newAttributes[index][e.target.name] = e.target.value;
    setProduct({ ...product, attributes: newAttributes });
  };

  const addAttribute = () => {
    setProduct({
      ...product,
      attributes: [...product.attributes, { key: '', value: '' }],
    });
  };

  const removeAttribute = (index) => {
    const newAttributes = [...product.attributes];
    newAttributes.splice(index, 1);
    setProduct({ ...product, attributes: newAttributes });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tenantId) {
      alert('Please fill in all required fields.');
      return;
    }
    setLoading(true);
    try {
      const newProduct = { ...product, category: selectedCategory, tenantId };
      await addDoc(collection(db, 'products'), newProduct);
      alert('Product registered successfully!');
      // 초기 상태를 재설정하여 모든 필드를 비웁니다.
      setProduct({
        vendorId: '',
        productCode: '',
        dimensions: {
          x: '',
          y: '',
          z: '',
          w: '',
        },
        categoryId: '',
        barcode: '',
        lotcode: '',
        serialNumber: '',
        attributes: [{ key: '', value: '' }],
      });
      setSelectedCategory('');
    } catch (error) {
      console.error('Error adding product: ', error);
      alert('Failed to register product.');
    } finally {
      setLoading(false); // 등록 완료 후 로딩 상태 비활성화
    }
  };

  const handleOpenProductCodeDialog = () => {
    setIsProductCodeDialogOpen(true);
  };
  const handleCloseProductCodeDialog = () => {
    setIsProductCodeDialogOpen(false);
  };
  const onProductCodeRegistered = (newCode) => {
    setSelectedProductCode(newCode);

    setProduct((prevProduct) => ({
      ...prevProduct,
      productCode: newCode,
      barcode: `${newCode}-${selectedProductSKU}`,
    }));
  };
  const handleOpenProductSKUDialog = () => {
    setIsProductSKUDialogOpen(true);
  };
  const handleCloseProductSKUDialog = () => {
    setIsProductSKUDialogOpen(false);
  };
  // SKU 등록 완료 시 호출되는 함수
  const onSKURegistered = (newSKU) => {
    setSelectedProductSKU(newSKU);

    setProduct((prevProduct) => ({
      ...prevProduct,
      sku: newSKU,
      barcode: `${selectedProductCode}-${newSKU}`,
    }));
  };
  const handleOpenProductLotCodeDialog = () => {
    setIsProductLotCodeDialogOpen(true);
  };
  const handleCloseProductLotCodeDialog = () => {
    setIsProductLotCodeDialogOpen(false);
  };

  const onProductLotCodeRegistered = (newCode) => {
    setSelectedProductLotCode(newCode);
    setProduct((prevProduct) => ({
      ...prevProduct,
      lotcode: newCode,
    }));
  };
  const handleOpenCategoryRegistrationDialog = () => {
    setOpenCategoryRegistrationDialog(true);
  };

  const handleCloseCategoryRegistrationDialog = () => {
    setOpenCategoryRegistrationDialog(false);
  };
  const handleCategoryRegistered = (newCategory) => {
    setSelectedCategory(newCategory); // 새 카테고리를 선택된 카테고리로 설정
    setProduct((prevProduct) => ({
      ...prevProduct,
      categoryId: newCategory.id,
    }));
    setOpenCategoryRegistrationDialog(false); // 다이얼로그 닫기
  };
  const handleDimensionChange = (name, value) => {
    setDimensions((prev) => {
      const newDimensions = { ...prev, [name]: value };
      if (name === 'x' || name === 'y' || name === 'z' || name === 'w') {
        matchCartonBoxAndPallet(newDimensions);
      }
      return newDimensions;
    });
  };
  const matchCartonBoxAndPallet = (dimensions) => {
    const matchedCartonBox = cartonBoxes.find(
      (box) =>
        box.length >= dimensions.y &&
        box.width >= dimensions.x &&
        box.height >= dimensions.z
    );
    const matchedPallet = pallets.find(
      (pallet) => pallet.weightCapacity >= dimensions.weight
    );

    setProduct((prev) => ({
      ...prev,
      cartonBoxId: matchedCartonBox ? matchedCartonBox.id : '',
      palletId: matchedPallet ? matchedPallet.id : '',
    }));
  };

  return (
    <Paper style={{ padding: 20, maxWidth: 700, margin: '20px auto' }}>
      {/* <Typography variant="h6"></Typography> */}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="상품명"
          name="name"
          value={product.productName}
          onChange={handleProductnameChange}
          style={{ marginTop: 20 }}
        />
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>거래처 ID</InputLabel>
          <Select
            value={product.vendorId}
            onChange={handleVendorChange}
            label="거래처 ID"
          >
            {vendors.map((vendor) => (
              <MenuItem key={vendor.id} value={vendor.id}>
                {vendor.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedVendorName && (
          <Typography variant="body1" style={{ marginTop: 20 }}>
            거래처 이름: {selectedVendorName}
          </Typography>
        )}
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>상품 코드</InputLabel>
          <Select
            value={selectedProductCode}
            onChange={handleProductCodeChange}
            label="상품 코드"
          >
            {productCodes.map((productCode) => (
              <MenuItem key={productCode.id} value={productCode.code}>
                {productCode.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenProductCodeDialog}
          sx={{ marginTop: 2, marginBottom: 2, marginRight: 2 }}
        >
          상품코드 등록
        </Button>

        {isProductCodeDialogOpen && (
          <ProductCodeRegistration
            onClose={handleCloseProductCodeDialog}
            onRegistered={onProductCodeRegistered}
          />
        )}
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          선택된 상품 코드: {selectedProductCode}
        </Typography>

        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>SKU</InputLabel>
          <Select
            value={selectedProductSKU}
            onChange={handleProductSKUChange}
            label="SKU"
          >
            {productSKUs.map((productSKU) => (
              <MenuItem key={productSKU.id} value={productSKU.code}>
                {productSKU.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenProductSKUDialog}
          sx={{ marginTop: 2, marginBottom: 2, marginRight: 2 }}
        >
          상품SKU 등록
        </Button>

        {isProductSKUDialogOpen && (
          <ProductSKURegistration
            onClose={handleCloseProductSKUDialog}
            onRegistered={onSKURegistered}
          />
        )}
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          선택된 SKU: {selectedProductSKU}
        </Typography>

        <TextField
          fullWidth
          label="바코드"
          name="barcode"
          value={product.barcode}
          onChange={handleBarcodeChange} // barcode 변경 핸들러
          style={{ marginTop: 20 }}
        />
        <BarcodeGenerator
          inputValue={product.barcode}
          onBarcodeGenerated={handleBarcodeGenerated}
        />
        <Typography variant="subtitle1">
          생성된 바코드: {product.barcode}
        </Typography>

        <TextField
          fullWidth
          label="LOT Code"
          name="lotcode"
          value={product.lotcode}
          onChange={handleLotcodeChange} // barcode 변경 핸들러
          style={{ marginTop: 20 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenProductLotCodeDialog}
          sx={{ mt: 2 }}
        >
          로트 코드 등록
        </Button>
        {isProductLotCodeDialogOpen && (
          <ProductLotCodeRegistration
            onClose={handleCloseProductLotCodeDialog}
            onRegistered={onProductLotCodeRegistered}
          />
        )}
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          선택된 상품 Lot 코드: {selectedProductLotCode}
        </Typography>
        <TextField
          fullWidth
          label="Serial Number"
          name="serialNumber"
          value={product.serialNumber}
          onChange={handleSerialNumberChange} // barcode 변경 핸들러
          style={{ marginTop: 20 }}
        />
        {/* <TextField fullWidth label="카테고리" name="category" value={product.category} InputProps={{ readOnly: true }} style={{ marginTop: 20 }} /> */}
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label="Category"
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCategoryRegistrationDialog}
          sx={{ mt: 2 }} // sx prop을 사용하여 왼쪽 마진 추가
        >
          카테고리 등록
        </Button>
        {openCategoryRegistrationDialog && (
          <CategoryRegistration
            onClose={handleCloseCategoryRegistrationDialog}
            onCategoryRegistered={handleCategoryRegistered}
          />
        )}

        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          선택된 상품 카테고리:{' '}
          {selectedCategory ? selectedCategory.name : '선택되지 않음'}
        </Typography>

        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <ProductSizeXYZ
            dimensions={dimensions}
            onDimensionChange={handleDimensionChange}
          />
        </Grid>

        {product.attributes.map((attribute, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="속성명"
                name="key"
                value={attribute.key}
                onChange={(e) => handleAttributeChange(index, e)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="값"
                name="value"
                value={attribute.value}
                onChange={(e) => handleAttributeChange(index, e)}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <IconButton
                onClick={() => removeAttribute(index)}
                style={{ marginBottom: '8px' }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <IconButton onClick={addAttribute}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : '등록'}
        </Button>
      </form>
    </Paper>
  );
};

export default ProductRegistration;
