// import React, { useState, useRef, useEffect } from 'react';
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   query,
//   where,
//   orderBy,
//   doc,
//   setDoc,
//   updateProfile,
//   getAuth,
//   GoogleAuthProvider,
//   signInWithPopup,
//   onAuthStateChanged,
//   signInWithEmailAndPassword,
//   signOut,
//   serverTimestamp
// } from '../firebaseConfig';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton , useMediaQuery, useTheme,Typography,Container} from '@mui/material';
// import { useNavigate } from "react-router-dom";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import CloseIcon from '@mui/icons-material/Close';
// import KakaoLogin from "react-kakao-login";
// import kakao_login from '../assets/images/kakao_login.png';
// import naver_login from '../assets/images/naver_login.png';
// import google_login_icon from '../assets/images/google_login_icon.png';
// import kakao_login_icon from '../assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
// import naver_login_icon from '../assets/images/naver_login_icon.png';
// import './css/login.css';

// function Login() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [open, setOpen] = useState(true);
//   const [error, setError] = useState('');
//   const googleProvider = new GoogleAuthProvider();
//   const navigate = useNavigate();

//   const theme = useTheme();
//   // useMediaQuery 훅을 사용하여 전체 화면 모드를 반응형으로 설정합니다.
//   // theme.breakpoints.down('sm')은 화면 너비가 'sm' 이하일 때 true를 반환합니다.
//   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleLogin = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       updateLastLogin(auth.currentUser.uid);
//       navigate("/");
//     } catch (error) {
//       console.error("Error logging in", error);
//       setError("로그인실패:" + error);
//     }
//   };
//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       navigate("/");
//     } catch (error) {
//       console.error("Error logging out", error);
//       setError("로그아웃 실패:" + error);
//     }
//   };

//   const handleGoogleLogin = async () => {

//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       if (result.user) {
//         const email = result.user.email;
//         const name = result.user.displayName;

//         console.log("이메일:", email);
//         console.log("이름:", name);
//         setError('구글계정 :' + email + ' 로 로그인 되었습니다.')
//         //// collection(db, 'users').doc(result.user.uid).set({ email, name });
//         const userDoc = doc(db, 'users', result.user.uid);
//         await setDoc(userDoc, { email, name });
//         updateLastLogin(result.user.uid);
//         // 사용자 프로필 업데이트
//         await updateProfile(result.user, { displayName: name }).then(() => {
//           console.log("이름 저장 성공");
//           navigate("/");
//         });

//       }
//     } catch (error) {
//       console.error("구글 로그인 오류:", error.message);
//       setError("구글 로그인 오류:" + error.message);
//     };
//   }

// const handleKakaoLogin = () => {
//   const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`
//   window.location.href = kakaoURL
// }

// const handleNaverLogin = () => {

//   const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
//   sessionStorage.setItem('naverOAuthState', state);
//   const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//   window.location.href = naverURL;
// }

//   const SocialKakao = () => {

//     const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`
//     const handleKakaoLogin = () => {
//       window.location.href = kakaoURL
//     }
//     return (
//       <>
//         <button onClick={handleKakaoLogin} variant="contained" color="secondary" fullWidth style={{ boxShadow: '0px 2px 5px rgba(0,0,0,0.1)' }}>카카오 로그인</button>
//       </>
//     )
//   }

//   function updateLastLogin(userId) {
//     const userDocRef = doc(db, 'users', userId);

//     setDoc(userDocRef, { lastLogin: serverTimestamp() }, { merge: true })
//       .then(() => {
//         console.log('Last login time updated');
//       })
//       .catch((error) => {
//         console.error('Error updating last login time:', error);
//       });
//   }
//   // 다이얼로그를 닫을 때 상태를 변경하지 않도록 수정
//   const handleClose = () => {
//     navigate('/');
//     setOpen(false);
//   };
//   const handleFocus = () => {

//   };

// return (
//   <Container maxWidth="sm" style={{ marginTop: '100px' }}>
//     <Typography variant="h4" component="h1" gutterBottom>
//       로그인
//       <IconButton style={{ position: 'absolute', right: '8px', top: '8px' }} onClick={handleClose}>
//         <CloseIcon />
//       </IconButton>
//     </Typography>
//     <TextField
//       variant="outlined"
//       margin="normal"
//       fullWidth
//       label="Email"
//       type="email"
//       value={email}
//       onChange={e => setEmail(e.target.value)}
//     />
//     <TextField
//       variant="outlined"
//       margin="normal"
//       fullWidth
//       label="Password"
//       type={showPassword ? 'text' : 'password'}
//       value={password}
//       onChange={e => setPassword(e.target.value)}
//       InputProps={{
//         endAdornment: (
//           <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
//             {showPassword ? <Visibility /> : <VisibilityOff />}
//           </IconButton>
//         ),
//       }}
//     />
//     {error && <Typography color="error">{error}</Typography>}
//     <Button
//       onClick={handleLogin}
//       variant="contained"
//       color="primary"
//       fullWidth
//       style={{ marginTop: '20px' }}
//     >
//       로그인
//     </Button>

// <span>
//     <Button onClick={handleGoogleLogin} variant="contained" color="secondary" style={{
//     width: '70px',
//     height: '60px',
//     backgroundImage: `url(${google_login_icon})`,
//     backgroundSize: 'cover',       // scales the image to cover the button
//     backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//     backgroundPosition: 'center',  // centers the image within the button
//     margin: '5px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
//   }}></Button>
// <Button onClick={handleKakaoLogin} variant="contained" color="secondary" style={{
//   width: '70px',
//   height: '60px',
//   backgroundImage: `url(${kakao_login_icon})`,
//   backgroundSize: 'cover',       // scales the image to cover the button
//   backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//   backgroundPosition: 'center',  // centers the image within the button
//   margin: '5px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
// }}></Button>
// <Button onClick={handleNaverLogin} variant="contained" color="secondary" style={{
//   width: '70px',
//   height: '60px',
//   backgroundImage: `url(${naver_login_icon})`,
//   backgroundSize: 'cover',       // scales the image to cover the button
//   backgroundRepeat: 'no-repeat', // ensures the image doesn't repeat
//   backgroundPosition: 'center',  // centers the image within the button
//   margin: '5px', boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
// }}></Button>
// </span>

//   </Container>
// );
// }

// export default Login;

import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  db,
  auth,
  getDoc,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  serverTimestamp,
  doc,
  setDoc,
} from './firebaseConfig';
import {
  Box,
  Link,
  Container,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Grid,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import validateEmail from './Utils/validateEmail'; // 이메일 유효성 검사 함수, 구현 필요
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useGlobalContext } from './useGlobalContext';

// CSS 및 이미지
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import google_login_icon from './assets/images/google_login_icon.png';
import kakao_login_icon from './assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
import naver_login_icon from './assets/images/naver_login_icon.png';

function Login() {
  const navigate = useNavigate();
  const { updateTenantId, sidebarWidth } = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [tenantId, setTenantId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#556cd6',
  //     },
  //     secondary: {
  //       main: '#19857b',
  //     },
  //   },
  // });
  // 로그인 처리
  async function fetchTenantId(userId) {
    const userDocRef = doc(db, 'users', userId); // 사용자 문서 참조 생성

    try {
      const docSnap = await getDoc(userDocRef); // 문서 스냅샷 가져오기

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        return docSnap.data().tenantId; // tenantId 반환
      } else {
        // 문서가 존재하지 않을 때
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting document:', error);
      return null;
    }
  }

  const checkTenantAccess = async (uid, tenantId) => {
    const userDocRef = doc(db, 'users', uid);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userTenants = docSnap.data().tenants || [];
        if (!userTenants.includes(tenantId)) {
          // 사용자가 이 테넌트에 액세스할 권한이 없으므로 로그아웃 시킵니다.
          await auth.signOut();
          throw new Error(
            'Access denied to this tenant. User has been logged out.'
          );
        }
      } else {
        // 사용자 문서가 존재하지 않습니다.
        await auth.signOut();
        throw new Error(
          'User document does not exist. User has been logged out.'
        );
      }
    } catch (error) {
      // 여기서 catch 블록은 주로 Firestore 작업에서 발생할 수 있는 예외를 처리합니다.
      await auth.signOut();
      throw error; // 상위로 예외를 전파하여 로그인 프로세스를 중단시킵니다.
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!tenantId) {
      setError('유효한 Tenant ID를 입력해주세요.');
      return;
    }
    if (!email) {
      setError('아이디를 입력해주세요.');
      return;
    }
    if (!validateEmail(email)) {
      setError('유효한 이메일 주소를 입력해주세요.');
      return;
    }
    if (!password) {
      setError('암호를 입력해주세요.');
      return;
    }
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await checkTenantAccess(auth.currentUser.uid, tenantId);
      await updateTenantAndLastLogin(auth.currentUser.uid);

      navigate('/');
    } catch (error) {
      let errorMessage = ''; // 에러 메시지 초기화
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage = '잘못된 이메일 형식입니다.';
          break;
        case 'auth/user-disabled':
          errorMessage = '해당 사용자 계정이 비활성화되었습니다.';
          break;
        case 'auth/user-not-found':
          errorMessage =
            '사용자를 찾을 수 없습니다. 이메일 주소를 확인해주세요.';
          break;
        case 'auth/wrong-password':
          errorMessage = '비밀번호가 정확하지 않습니다.';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            '로그인 시도가 너무 많습니다. 잠시 후 다시 시도해주세요.';
          break;
        case 'auth/network-request-failed':
          errorMessage = '네트워크 연결 상태를 확인하고 다시 시도해주세요.';
          break;
        case 'auth/invalid-credential':
          errorMessage =
            '인증 정보가 유효하지 않습니다. 입력 정보를 확인해주세요.';
          break;
        default:
          errorMessage =
            '로그인 과정에서 오류가 발생했습니다. 다시 시도해주세요.';
      }
      setError('로그인 실패: ' + errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Google 로그인
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      await updateTenantAndLastLogin(auth.currentUser.uid);

      navigate('/');
    } catch (error) {
      setError('Google 로그인 실패: ' + error.message);
    }
  };
  const handleKakaoLogin = () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    window.location.href = kakaoURL;
  };

  const handleNaverLogin = () => {
    const state =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem('naverOAuthState', state);
    const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
    window.location.href = naverURL;
  };
  // 사용자의 마지막 로그인 시간 업데이트
  const updateTenantAndLastLogin = async (userId) => {
    //const tenantId = await fetchTenantId(auth.currentUser.uid);
    updateTenantId(tenantId);

    const userDocRef = doc(db, 'users', userId);
    await setDoc(userDocRef, { lastLogin: serverTimestamp() }, { merge: true });
  };

  return (
    // Box 컴포넌트를 사용하여 flex container를 생성합니다.
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex', // Flexbox 사용
          flexDirection: 'column', // 자식 요소들을 세로로 배치
          justifyContent: 'center', // 자식 요소들을 수직 방향으로 중앙에 배치
          alignItems: 'center', // 자식 요소들을 수평 방향으로 중앙에 배치
          height: '100vh', // 부모 컨테이너의 높이를 뷰포트의 100%로 설정
          ml: `${sidebarWidth}px`, // sidebarWidth 만큼 왼쪽 마진을 추가
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{ mt: 50, transform: 'translateY(-50%)' }}
        >
          <Typography variant="h4" gutterBottom>
            로그인
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={handleLogin} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Tenant ID"
              placeholder="Enter your Tenant ID"
              value={tenantId}
              onChange={(e) => setTenantId(e.target.value)}
              required
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="이메일"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="비밀번호"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />

            {loading ? (
              <CircularProgress
                size={24}
                sx={{ display: 'block', mx: 'auto', my: 2 }}
              />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                로그인
              </Button>
            )}
          </form>

          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                패스워드를 분실했나요?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/join" variant="body2">
                {'회원가입'}
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <IconButton onClick={handleGoogleLogin}>
                <img
                  src={google_login_icon}
                  alt="Google login"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
              <IconButton onClick={handleKakaoLogin}>
                <img
                  src={kakao_login_icon}
                  alt="Kakao login"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
              <IconButton onClick={handleNaverLogin}>
                <img
                  src={naver_login_icon}
                  alt="Naver login"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            </Grid>
            {/* 카카오 및 네이버 로그인 버튼 추가 */}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;

// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//   },
// });

// export default function SignIn() {
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography>
//           <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//             />
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Sign In
//             </Button>
// <Grid container>
//   <Grid item xs>
//     <Link href="#" variant="body2">
//       Forgot password?
//     </Link>
//   </Grid>
//   <Grid item>
//     <Link href="#" variant="body2">
//       {"Don't have an account? Sign Up"}
//     </Link>
//   </Grid>
// </Grid>
//           </Box>
//         </Box>
//         <Copyright sx={{ mt: 8, mb: 4 }} />
//       </Container>
//     </ThemeProvider>
//   );
// }

//
// import React, { useState, useRef, useEffect } from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import IconButton from '@mui/material/IconButton';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { useNavigate } from "react-router-dom";
// import {
//   firebaseApp,
//   db,
//   auth,
//   analytics,
//   storage,
//   functions,
//   collection,
//   query,
//   where,
//   orderBy,
//   doc,
//   setDoc,
//   updateProfile,
//   getAuth,
//   GoogleAuthProvider,
//   signInWithPopup,
//   onAuthStateChanged,
//   signInWithEmailAndPassword,
//   signOut,
//   serverTimestamp
// } from '../firebaseConfig';
// // Firebase imports 및 기타 필요한 imports 추가
// // 예: import { auth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from '../firebaseConfig';

// // 이미지 임포트
// import google_login_icon from '../assets/images/google_login_icon.png';
// import kakao_login_icon from '../assets/images/kakao/kakaotalk_sharing_btn/kakaotalk_sharing_btn_medium.png';
// import naver_login_icon from '../assets/images/naver_login_icon.png';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//   },
// });

// export default function SignIn() {
//     const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [open, setOpen] = useState(true);
//   const [error, setError] = useState('');
//   const googleProvider = new GoogleAuthProvider();

//   const navigate = useNavigate();
//   // Firebase 및 기타 필요한 상태 추가

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//     // 로그인 로직 추가
//   };

//   // handleGoogleLogin, handleKakaoLogin, handleNaverLogin 함수 구현
//     const handleLogin = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       updateLastLogin(auth.currentUser.uid);
//       navigate("/");
//     } catch (error) {
//       console.error("Error logging in", error);
//       setError("로그인실패:" + error);
//     }
//   };
//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       navigate("/");
//     } catch (error) {
//       console.error("Error logging out", error);
//       setError("로그아웃 실패:" + error);
//     }
//   };

//   const handleGoogleLogin = async () => {

//     try {
//       const result = await signInWithPopup(auth, googleProvider);
//       if (result.user) {
//         const email = result.user.email;
//         const name = result.user.displayName;

//         console.log("이메일:", email);
//         console.log("이름:", name);
//         setError('구글계정 :' + email + ' 로 로그인 되었습니다.')
//         //// collection(db, 'users').doc(result.user.uid).set({ email, name });
//         const userDoc = doc(db, 'users', result.user.uid);
//         await setDoc(userDoc, { email, name });
//         updateLastLogin(result.user.uid);
//         // 사용자 프로필 업데이트
//         await updateProfile(result.user, { displayName: name }).then(() => {
//           console.log("이름 저장 성공");
//           navigate("/");
//         });

//       }
//     } catch (error) {
//       console.error("구글 로그인 오류:", error.message);
//       setError("구글 로그인 오류:" + error.message);
//     };
//   }

//   const handleKakaoLogin = () => {
//     const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`
//     window.location.href = kakaoURL
//   }

//   const handleNaverLogin = () => {

//     const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
//     sessionStorage.setItem('naverOAuthState', state);
//     const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}`;
//     window.location.href = naverURL;
//   }
//   function updateLastLogin(userId) {
//     const userDocRef = doc(db, 'users', userId);

//     setDoc(userDocRef, { lastLogin: serverTimestamp() }, { merge: true })
//       .then(() => {
//         console.log('Last login time updated');
//       })
//       .catch((error) => {
//         console.error('Error updating last login time:', error);
//       });
//   }
//   // 카카오 및 네이버 로그인 함수는 이곳에 구현될 예정
//   // 하지만 실제 인증 처리는 백엔드와 추가 설정이 필요합니다.

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography>
//           <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type={showPassword ? 'text' : 'password'}
//               id="password"
//               autoComplete="current-password"
//               InputProps={{
//                 endAdornment: (
//                   <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
//                     {showPassword ? <VisibilityOff /> : <Visibility />}
//                   </IconButton>
//                 ),
//               }}
//             />
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Sign In
//             </Button>
//             <Grid container spacing={2} justifyContent="center">
//               <Grid item>
//                 <IconButton onClick={() => {/* handleGoogleLogin 함수 호출 */}}>
//                   <img src={google_login_icon} alt="Google login" style={{ width: 30, height: 30 }} />
//                 </IconButton>
//                 <IconButton onClick={() => {/* handleKakaoLogin 함수 호출 */}}>
//                   <img src={kakao_login_icon} alt="Kakao login" style={{ width: 30, height: 30 }} />
//                 </IconButton>
//                 <IconButton onClick={() => {/* handleNaverLogin 함수 호출 */}}>
//                   <img src={naver_login_icon} alt="Naver login" style={{ width: 30, height: 30 }} />
//                 </IconButton>
//               </Grid>
//             </Grid>
//             <Grid container>
//               <Grid item xs>
//                 <Link href="#" variant="body2">
//                   Forgot password?
//                 </Link>
//               </Grid>
//               <Grid item>
//                 <Link href="#" variant="body2">
//                   {"Don't have an account? Sign Up"}
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </Container>
//     </ThemeProvider>
//   );
// }
