// import React from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
// import { Typography, Paper, Box } from '@mui/material';

// const InventoryAnalytics = () => {
//   // 샘플 재고 데이터
//   const stockData = [
//     {name: '상품 A', stock: 400},
//     {name: '상품 B', stock: 300},
//     {name: '상품 C', stock: 300},
//     {name: '상품 D', stock: 200},
//   ];

//   // 샘플 재고 회전율 데이터
// const turnoverData = [
//   {month: '1월', turnover: 20},
//   {month: '2월', turnover: 30},
//   {month: '3월', turnover: 15},
//   {month: '4월', turnover: 25},
// ];

//   return (
//     <Paper sx={{ padding: 2 }}>
//       <Typography variant="h6" gutterBottom>재고 분석</Typography>
//       <Box sx={{ width: '100%', height: 300 }}>
//         <ResponsiveContainer width="100%" height="100%">
//           <BarChart data={stockData} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="name" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             <Bar dataKey="stock" fill="#8884d8" />
//           </BarChart>
//         </ResponsiveContainer>
//       </Box>
//       <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>재고 회전율</Typography>
//       <Box sx={{ width: '100%', height: 300 }}>
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart data={turnoverData} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
//             <XAxis dataKey="month" />
//             <YAxis />
//             <Tooltip />
//             <Line type="monotone" dataKey="turnover" stroke="#8884d8" />
//           </LineChart>
//         </ResponsiveContainer>
//       </Box>
//     </Paper>
//   );
// };

// export default InventoryAnalytics;

import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig'; // Ensure this points to your Firebase config file
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { Typography, Paper, Box } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import { useGlobalContext } from '../useGlobalContext';
// Custom Tooltip
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // payload[0].payload로 현재 활성화된 데이터 포인트의 전체 데이터에 접근합니다.
    const currentData = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p className="label">{`Month: ${label}`}</p>
        {payload.map((entry, index) => (
          <p
            key={index}
            style={{ color: entry.color }}
          >{`${entry.name}: ${entry.value}`}</p>
        ))}
        {currentData.vendorId && <p>{`거래처ID: ${currentData.vendorId}`}</p>}
        {currentData.productId && <p>{`상품ID: ${currentData.productId}`}</p>}
        {currentData.eventPeriod && (
          <p>{`Sale Period: ${currentData.eventPeriod}`}</p>
        )}
        {currentData.eventId && <p>{`Event ID: ${currentData.eventId}`}</p>}
      </div>
    );
  }

  return null;
};

const InventoryAnalytics = ({ products, selectedVendor }) => {
  const { tenantId } = useGlobalContext();
  const [stockData, setStockData] = useState([]);
  const [turnoverData, setTurnoverData] = useState([]);

  useEffect(() => {
    const fetchStockData = async () => {
      const querySnapshot = await getDocs(collection(db, 'products')); // Adjust 'inventory' to your collection name
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        name: doc.data().productName, // Adjust field names based on your database
        stock: doc.data().stock,
      }));
      setStockData(data);
    };

    fetchStockData();
  }, []);

  const addTurnOverData = async (product) => {
    saveProductTurnoverData(product.id, {
      month: 'January',
      turnover: 10,
      price: 20.0,
      quantitySold: 50,
      eventPeriod: '2024-01-01 to 2024-01-31',
      eventId: 'spring_sale_2024', // Example event ID
      vendorId: selectedVendor.id,
      productId: product.id,
    });
  };
  useEffect(() => {
    const fetchStockAndTurnoverData = async () => {
      if (!tenantId) return; // Ensure tenantId is present

      // Adjust this to fetch turnover data, ensuring queries are tenant-specific
      const productsRef = query(
        collection(db, 'products'),
        where('tenantId', '==', tenantId)
      );
      const productsSnapshot = await getDocs(productsRef);
      let allTurnoverData = [];

      for (const productDoc of productsSnapshot.docs) {
        const productId = productDoc.id;
        const turnoverDataRef = query(
          collection(db, `products/${productId}/turnoverData`)
        );
        const turnoverDataSnapshot = await getDocs(turnoverDataRef);

        turnoverDataSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          allTurnoverData.push({
            ...data,
            productId,
            productName: productDoc.data().name, // Assuming you have a 'name' field on your products
          });
        });
      }

      setTurnoverData(allTurnoverData);
    };

    fetchStockAndTurnoverData();
  }, [tenantId]);
  // // eventId 파라미터를 추가하여, 특정 이벤트에 속하는 데이터만 선택적으로 가져올 수 있도록 합니다.
  // const fetchAllTurnoverData = async (eventId = null) => {
  //   const productsRef = collection(db, 'products');
  //   const productsSnapshot = await getDocs(productsRef);
  //   let allTurnoverData = [];

  //   for (const productDoc of productsSnapshot.docs) {
  //     const productId = productDoc.id;

  //     let turnoverDataRef;
  //     if (eventId) {
  //       // Query for documents within a specific product's collection, filtered by eventId
  //       turnoverDataRef = query(
  //         collection(db, `products/${productId}/turnoverData`),
  //         where('eventId', '==', eventId)
  //       );
  //     } else {
  //       // Query for all documents within a specific product's collection if no eventId is specified
  //       turnoverDataRef = query(
  //         collection(db, `products/${productId}/turnoverData`)
  //       );
  //     }

  //     const turnoverDataSnapshot = await getDocs(turnoverDataRef);

  //     turnoverDataSnapshot.docs.forEach((doc) => {
  //       const data = doc.data();
  //       allTurnoverData.push({
  //         productId: productId,
  //         month: data.month,
  //         turnover: data.turnover,
  //         // 여기에 추가적으로 필요한 데이터 필드를 포함시킬 수 있습니다.
  //         price: data.price,
  //         quantitySold: data.quantitySold,
  //         salePeriod: data.salePeriod,
  //         eventId: data.eventId,
  //         vendorId: data.vendorId,
  //       });
  //     });
  //   }

  //   setTurnoverData(allTurnoverData);
  // };

  const filteredProducts = React.useMemo(() => {
    return products.filter(
      (product) => selectedVendor === '' || product.vendorId === selectedVendor
    );
  }, [products, selectedVendor]);

  // Function to save detailed turnover data for a specific product with event tracking
  const saveProductTurnoverData = async (productId, turnoverData) => {
    try {
      const docRef = await addDoc(
        collection(db, `products/${productId}/turnoverData`),
        {
          month: turnoverData.month, // e.g., 'January'
          turnover: turnoverData.turnover, // e.g., 10
          price: turnoverData.price, // Optional, e.g., 20.00
          quantitySold: turnoverData.quantitySold, // Optional, e.g., 50
          eventPeriod: turnoverData.eventPeriod, // Optional, e.g., '2024-01-01 to 2024-01-31'
          eventId: turnoverData.eventId, // NEW: Identifies the event associated with this data, e.g., 'spring_sale_2024'
          vendorId: turnoverData?.vendorId,
          productId: productId,
        }
      );

      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        재고 분석
      </Typography>
      <Box sx={{ width: '100%', height: 300, mb: 4 }}>
        {/* <ResponsiveContainer width="100%" height="100%">
          <BarChart data={stockData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="stock" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer> */}

        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={filteredProducts}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="stock" fill="#8884d8" name="Stock Level" />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Typography variant="h6" gutterBottom>
        재고 회전율
      </Typography>
      <Box sx={{ width: '100%', height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={turnoverData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="productId" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="turnover" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default InventoryAnalytics;
