import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { useGlobalContext } from './useGlobalContext';

const ProtectedRoute = ({ children }) => {
  const { sidebarWidth } = useGlobalContext();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  // 초기에는 로딩 상태를 true로 설정합니다.
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const REDIRECT_DELAY = 3000;

  useEffect(() => {
    // 로그인 상태가 확인되지 않은 경우
    if (!currentUser) {
      setOpenSnackbar(true); // 로그인 안내 Snackbar 표시
      // 로그인 페이지로 리다이렉트 전 로딩 상태를 유지합니다.
      const timer = setTimeout(() => {
        navigate('/login');
      }, REDIRECT_DELAY); // 3초 후 로그인 페이지로 이동

      // useEffect cleanup에서 타이머를 정리합니다.
      return () => clearTimeout(timer);
    } else {
      // 로그인 상태가 확인되면 로딩 상태를 false로 설정하여 로딩 인디케이터를 숨깁니다.
      setIsLoading(false);
    }
  }, [currentUser, navigate]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // 로딩 중일 때 CircularProgress를 표시합니다.
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 뷰포트 높이를 100%로 설정하여 전체 화면을 채웁니다.
          marginLeft: sidebarWidth,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // 로그인 상태가 확인되지 않았으나, 로그인 페이지로의 리다이렉트가 진행되는 동안에는 아무것도 표시하지 않습니다.
  if (!currentUser) {
    return null;
  }

  // 로그인 상태에서는 자식 컴포넌트를 정상적으로 렌더링합니다.
  return (
    <>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={REDIRECT_DELAY}
        onClose={handleCloseSnackbar}
        message="로그인이 필요합니다."
      />
    </>
  );
};

export default ProtectedRoute;

// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';

// const ProtectedRoute = ({ children }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (!currentUser) {
//       setLoading(false);
//       showDialog();
//     } else {
//       setLoading(false);
//     }
//   }, [currentUser, navigate]);

//   const showDialog = () => {
//     const redirectTimeout = setTimeout(() => navigate('/login'), 3000); // 5초 후 로그인 페이지로 리다이렉트

//     const dialog = (
//       <Dialog
//         open={true}
//         onClose={() => {
//           clearTimeout(redirectTimeout);
//           navigate('/login');
//         }}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'로그인 필요'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             보호된 페이지에 접근하려면 로그인이 필요합니다. 잠시 후 로그인
//             페이지로 이동합니다.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => {
//               clearTimeout(redirectTimeout);
//               navigate('/login');
//             }}
//             color="primary"
//             autoFocus
//           >
//             바로가기
//           </Button>
//         </DialogActions>
//       </Dialog>
//     );

//     return dialog;
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   return currentUser ? children : showDialog();
// };

// export default ProtectedRoute;

// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';

// const ProtectedRoute = ({ children }) => {
//   const currentUser = useSelector((state) => state.auth.currentUser);
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     // 사용자 인증 상태 확인 로직
//     if (!currentUser) {
//       setIsLoading(false);
//     } else {
//       setIsLoading(false);
//     }
//   }, [currentUser, navigate]);

//   // 로딩 상태 UI
//   if (isLoading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   // 비로그인 상태 UI
//   if (!currentUser) {
//     return (
//       <Box textAlign="center" mt={5}>
//         <Typography variant="h5" gutterBottom>
//           로그인이 필요한 페이지입니다.
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           이 페이지를 보려면 로그인 해주세요.
//         </Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => navigate('/login')}
//         >
//           로그인 페이지로 이동
//         </Button>
//       </Box>
//     );
//   }

//   // 사용자가 로그인한 경우 자식 컴포넌트 렌더링
//   return children;
// };

// export default ProtectedRoute;
