import { createSlice } from '@reduxjs/toolkit';
import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg';

const initialState = {
    backgroundColor: '',
    headerBackgroundColor: '',
    enableMobileMenuSmall: false,
    enableBackgroundImage: false,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedSidebar: true,
    enableFixedFooter: true,
    colorScheme: 'white',
    backgroundImage: sideBar6,
    backgroundImageOpacity: 'opacity-06',
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true,
};

export const themeOptionsSlice = createSlice({
    name: 'themeOptions',
    initialState,
    reducers: {
        setEnableBackgroundImage: (state, action) => {
            state.enableBackgroundImage = action.payload;
        },
        setEnableFixedHeader: (state, action) => {
            state.enableFixedHeader = action.payload;
        },
        setEnableHeaderShadow: (state, action) => {
            state.enableHeaderShadow = action.payload;
        },
        setEnableSidebarShadow: (state, action) => {
            state.enableSidebarShadow = action.payload;
        },
        setEnableFixedSidebar: (state, action) => {
            state.enableFixedSidebar = action.payload;
        },
        setEnableClosedSidebar: (state, action) => {
            state.enableClosedSidebar = action.payload;
        },
        setEnableMobileMenu: (state, action) => {
            state.enableMobileMenu = action.payload;
        },
        setEnableMobileMenuSmall: (state, action) => {
            state.enableMobileMenuSmall = action.payload;
        },
        setEnableFixedFooter: (state, action) => {
            state.enableFixedFooter = action.payload;
        },
        setEnablePageTitleIcon: (state, action) => {
            state.enablePageTitleIcon = action.payload;
        },
        setEnablePageTitleSubheading: (state, action) => {
            state.enablePageTitleSubheading = action.payload;
        },
        setEnablePageTabsAlt: (state, action) => {
            state.enablePageTabsAlt = action.payload;
        },
        setBackgroundColor: (state, action) => {
            state.backgroundColor = action.payload;
        },
        setHeaderBackgroundColor: (state, action) => {
            state.headerBackgroundColor = action.payload;
        },
        setColorScheme: (state, action) => {
            state.colorScheme = action.payload;
        },
        setBackgroundImage: (state, action) => {
            state.backgroundImage = action.payload;
        },
        setBackgroundImageOpacity: (state, action) => {
            state.backgroundImageOpacity = action.payload;
        },
    },
});

export const {
    setEnableBackgroundImage,
    setEnableFixedHeader,
    setEnableHeaderShadow,
    setEnableSidebarShadow,
    setEnableFixedSidebar,
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
    setEnableFixedFooter,
    setEnablePageTitleIcon,
    setEnablePageTitleSubheading,
    setEnablePageTabsAlt,
    setBackgroundColor,
    setHeaderBackgroundColor,
    setColorScheme,
    setBackgroundImage,
    setBackgroundImageOpacity,


   
} = themeOptionsSlice.actions;

export default themeOptionsSlice.reducer;
