import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { 
    db,
    doc, 
    query, 
    where, 
    getDoc, 
    getDocs, 
    onSnapshot, 
    orderBy, 
    limit,
    collection,
} from './firebaseConfig';
import { CircularProgress, Box, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Alert } from '@mui/material';

import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/ko';
import { Spinner } from 'react-bootstrap';

function ChatList({ tenantId }) {
    const [chatRooms, setChatRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
        const fetchChatroomData = async (documentSnapshot) => {
            const chatroom = { id: documentSnapshot.id, ...documentSnapshot.data() };

            if (currentUser.uid) {
                const ownerUserRef = doc(db, 'users', chatroom.ownerUserId); // Assuming there is an ownerUserId field
                const ownerUserSnapshot = await getDoc(ownerUserRef);
                if (ownerUserSnapshot.exists()) {
                    const ownerUserData = ownerUserSnapshot.data();
                    const ownerProfileImage = ownerUserData?.photoURL;
                    if (ownerProfileImage) {
                        chatroom.ownerProfileImage = ownerProfileImage;
                    }
                }
            }

            const lastMessageQuery = query(
                collection(db, 'chatroom', documentSnapshot.id, 'messages'),
                orderBy('date', 'desc'),
                limit(1)
            );
            const lastMessageSnapshot = await getDocs(lastMessageQuery);

            if (!lastMessageSnapshot.empty) {
                const lastMessageData = lastMessageSnapshot.docs[0].data();
                chatroom.lastMessage = lastMessageData.content;
                chatroom.lastMessageDate = lastMessageData.date.toDate();
            }

            return chatroom;
        };

        const chatroomQuery = query(
            collection(db, 'chatroom'),
            where('tenantId', '==', tenantId), // Add tenantId in the query
            where('who', 'array-contains', currentUser.uid)
        );

        const unsubscribe = onSnapshot(chatroomQuery, async (snapshot) => {
            try {
                const chatroomDataPromises = snapshot.docs.map(fetchChatroomData);
                const completedChatrooms = await Promise.all(chatroomDataPromises);
                setChatRooms(completedChatrooms);
            } catch (e) {
                console.error("An error occurred:", e);
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        });

        return () => unsubscribe();

    }, [currentUser, tenantId]); // Add tenantId as a dependency

    const handleChatRoomClick = (productId, chatRoomId) => {
        navigate(`/chatview/${chatRoomId}/${tenantId}`);
    };

    return (
        <div className="chatlist">
            {/* UI Rendering Logic */}
        </div>
    );
}

export default ChatList;
