import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../css/messagebox.css';  // CSS 스타일

function MessageBox({ title, content, onYes, onNo, onOk, type, onClose }) {
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const modalStyle = {
        width: '300px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    };

    const buttonStyle = {
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    };

    const handleContent = () => {
        if (!content) return null;
        return content.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };
    
    return ReactDOM.createPortal(
        <div style={overlayStyle}>
            <div style={modalStyle}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ flex: '1' }}>{title}</h2>
                    <button onClick={onClose} style={{ background: 'none', border: 'none', fontSize: '1.5em', cursor: 'pointer' }}>X</button>
                </div>
    
                <p>{handleContent()}</p>
                {type === 'yesNo' ? (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        <button style={buttonStyle} onClick={onYes}>확인</button>
                        <button style={buttonStyle} onClick={onNo}>취소</button>
                    </div>
                ) : (
                    <button style={buttonStyle} onClick={onOk}>확인</button>
                )}
            </div>
        </div>,
        document.body
    );
    
}

export default MessageBox;
