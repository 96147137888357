import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

// id: 카톤박스의 고유 식별자 (자동 생성된 Firestore 문서 ID 사용 가능)
// width: 카톤박스의 가로 길이 (단위: mm)
// length: 카톤박스의 세로 길이 (단위: mm)
// height: 카톤박스의 높이 (단위: mm)
// weight: 카톤박스의 무게 (단위: g)
// unitsPerItem: 카톤박스에 담길 수 있는 낱개 상품의 수 (단위: ea)
// unitsPerPallet: 팔레트당 담길 수 있는 카톤박스의 수 (단위: ea)
// tenantId: 카톤박스를 소유한 테넌트의 ID (다중 테넌트 환경에서 사용)
const CartonBoxUpdateDialog = ({ open, onClose, cartonBoxId }) => {
  const [cartonBoxInfo, setCartonBoxInfo] = useState({
    width: '',
    length: '',
    height: '',
    weight: '',
    unitsPerItem: '',
    unitsPerPallet: '',
  });

  useEffect(() => {
    if (open) {
      const loadCartonBoxInfo = async () => {
        const docRef = doc(db, 'cartonBoxes', cartonBoxId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCartonBoxInfo(docSnap.data());
        } else {
          console.log('No such document!');
        }
      };
      loadCartonBoxInfo();
    }
  }, [cartonBoxId, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCartonBoxInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, 'cartonBoxes', cartonBoxId);
      await updateDoc(docRef, { ...cartonBoxInfo });
      alert('카톤박스 정보가 성공적으로 업데이트되었습니다.');
      onClose(); // Close dialog after update
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">카톤박스 규격 정보 수정</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Container component="main" maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="가로(mm)"
                  name="width"
                  type="number"
                  value={cartonBoxInfo.width}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="세로(mm)"
                  name="length"
                  type="number"
                  value={cartonBoxInfo.length}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="높이(mm)"
                  name="height"
                  type="number"
                  value={cartonBoxInfo.height}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="무게(g)"
                  name="weight"
                  type="number"
                  value={cartonBoxInfo.weight}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="낱개입수(ea)"
                  name="unitsPerItem"
                  type="number"
                  value={cartonBoxInfo.unitsPerItem}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="팔레트 입수"
                  name="unitsPerPallet"
                  type="number"
                  value={cartonBoxInfo.unitsPerPallet}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button type="submit" color="primary">
            수정하기
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CartonBoxUpdateDialog;
