import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useGlobalContext } from '../useGlobalContext';

const CustomerReservationForm = () => {
  const { tenantId } = useGlobalContext();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [reservationDate, setReservationDate] = useState(dayjs());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceTypes = async () => {
      if (!tenantId) return; // Ensure tenantId is available

      try {
        const q = query(
          collection(db, 'serviceTypes'),
          where('tenantId', '==', tenantId)
        );
        const querySnapshot = await getDocs(q);
        const services = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServiceTypes(services);
      } catch (error) {
        setSnackbarMessage('Failed to fetch service types.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceTypes();
  }, [tenantId]);

  const handleServiceChange = (event) => {
    const selected = serviceTypes.find(
      (service) => service.id === event.target.value
    );
    setServiceTypeId(selected.id);
    setServiceDescription(selected.description || '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!tenantId || !validateReservationDate()) {
      setIsSubmitting(false); // Ensure this is reset if tenantId is missing or date validation fails
      return;
    }
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'reservations'), {
        tenantId, // Include tenantId when creating a new reservation
        serviceTypeId,
        date: Timestamp.fromDate(reservationDate.toDate()),
        description: serviceDescription,
      });
      setSnackbarMessage('Reservation scheduled successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to make a reservation.');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
    setIsSubmitting(false);
  };

  const validateReservationDate = () => {
    if (dayjs(reservationDate).isBefore(dayjs())) {
      setSnackbarMessage('Reservation date must be in the future.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{ m: 3 }}
      >
        <TextField
          select
          label="Service Type"
          value={serviceTypeId}
          onChange={handleServiceChange}
          fullWidth
          margin="normal"
          disabled={loading}
        >
          {loading ? (
            <MenuItem value="">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={24} />
              </Box>
            </MenuItem>
          ) : (
            serviceTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))
          )}
        </TextField>
        {serviceDescription && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            {serviceDescription}
          </Typography>
        )}
        <DateTimePicker
          label="Reservation Date and Time"
          value={reservationDate}
          onChange={setReservationDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
          minDateTime={dayjs()}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={isSubmitting || loading}
        >
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            'Schedule Reservation'
          )}
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default CustomerReservationForm;
