import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';
import { auth } from '../firebaseConfig';

function BusinessInfoVerification({ onVerificationResult }) {
  const [businessNumber, setBusinessNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [verificationResult, setVerificationResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const verifyBusinessInfo = async () => {
    setLoading(true);
    setVerificationResult(null); // 이전 검증 결과 초기화
    try {
      const functionUrl =
        'https://asia-northeast3-warehousemanagementsyste-334f8.cloudfunctions.net/verifyBusinessInfo';

      // const client = await auth.getIdTokenClient(functionUrl);

      // const response = await client.request({
      //   url: functionUrl,
      //   method: 'POST',
      //   data: {
      //     b_no: businessNumber,
      //     b_name: businessName,
      //   },
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      // const response = await axios.post(functionUrl, {
      //   b_no: businessNumber,
      //   b_name: businessName,
      // });
      const response = await axios.post(
        functionUrl,
        {
          b_no: businessNumber,
          b_name: businessName,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setVerificationResult(response.data);
      onVerificationResult(true, response.data); // 검증 성공 시, 부모 컴포넌트로 결과 전달
    } catch (error) {
      console.error('검증 중 오류가 발생했습니다:', error);
      setVerificationResult({ error: error });
      onVerificationResult(false, { error: error }); // 검증 실패 시, 오류 메시지 전달
    }
    setLoading(false);
  };

  return (
    <Box>
      <Typography variant="h6">사업자 정보 입력</Typography>
      <TextField
        label="사업자 번호"
        variant="outlined"
        value={businessNumber}
        onChange={(e) => setBusinessNumber(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="사업자 이름"
        variant="outlined"
        value={businessName}
        onChange={(e) => setBusinessName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={verifyBusinessInfo}
        disabled={loading}
      >
        확인하기
      </Button>
      {/* {verificationResult && (
        <Box mt={2}>
          <Typography>
            확인 결과: {JSON.stringify(verificationResult)}
          </Typography>
        </Box>
      )} */}
    </Box>
  );
}

export default BusinessInfoVerification;
