import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, Paper } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';

import AnalyticsAndReporting from './AnalyticsAndReporting';
import AppointmentScheduling from './AppointmentScheduling';
// import AutomatedConfirmationAndReminders from './AutomatedConfirmationAndReminders';
import BookingCalendar from './BookingCalendar';
import CustomerManagement from './CustomerManagement';
import CustomerReservationForm from './CustomerReservationForm';
import {CustomizationAndScalability,CustomizationProvider} from './CustomizationAndScalability';

import PaymentIntegration from './PaymentIntegration';
import ResourceAllocation from './ResourceAllocation';

const ReservationDashboard = () => {
  const [tabValue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            <Tab label="분석 및 보고" value="1" />
            <Tab label="예약 스케줄링" value="2" />
            <Tab label="예약 캘린더" value="3" />
            <Tab label="고객 관리" value="4" />
            <Tab label="고객 예약 양식" value="5" />
            <Tab label="맞춤 설정 및 확장성" value="6" />
            <Tab label="결제 통합" value="7" />
            <Tab label="자원 할당" value="8" />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <AnalyticsAndReporting />
        </TabPanel>
        <TabPanel value="2">
          <AppointmentScheduling />
        </TabPanel>
        
        <TabPanel value="3">
          <BookingCalendar />
        </TabPanel>
        <TabPanel value="4">
          <CustomerManagement />
        </TabPanel>
        <TabPanel value="5">
          <CustomerReservationForm />
        </TabPanel>
        <TabPanel value="6">
          <CustomizationProvider>
              <CustomizationAndScalability />
          </CustomizationProvider>
        </TabPanel>
        <TabPanel value="7">
          <PaymentIntegration />
        </TabPanel>
        <TabPanel value="8">
          <ResourceAllocation />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default ReservationDashboard;
