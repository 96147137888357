// ModalStyles.js

const ModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1000',
        height: '70vh',
        overflow: 'auto',
        padding: '15px 25px',
        borderRadius: '10px',
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        width: '60%',
        maxWidth: '100%',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    }
};

export default ModalStyles;
