import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { useGlobalContext } from '../useGlobalContext';

const ReservationList = () => {
  const { tenantId } = useGlobalContext();
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      if (!tenantId) return; // Ensure tenantId is available

      // Adjust the query to fetch only reservations for the current tenant
      const q = query(
        collection(db, 'appointments'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(q);
      const reservationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        // Convert Firestore Timestamp to JavaScript Date object for rendering
        dateTime:
          doc.data().dateTime?.toDate().toLocaleString() ?? 'Date not set',
      }));
      setReservations(reservationsData);
    };

    fetchReservations();
  }, [tenantId]); // Re-fetch when tenantId changes

  const handleCancelReservation = async (reservationId) => {
    // Ensure reservation cancellation within the current tenant context
    await deleteDoc(doc(db, 'appointments', reservationId));
    setReservations(
      reservations.filter((reservation) => reservation.id !== reservationId)
    );
    alert('Reservation has been cancelled successfully.');
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6">Reservation List</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell align="right">Date & Time</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map((reservation) => (
                <TableRow
                  key={reservation.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {reservation.customerName}
                  </TableCell>
                  <TableCell align="right">{reservation.dateTime}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="error"
                      onClick={() => handleCancelReservation(reservation.id)}
                    >
                      Cancel
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default ReservationList;
