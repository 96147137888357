// import React, { useEffect, useState } from 'react';
// import { db,functions } from '../firebaseConfig';
// import { doc,addDoc, updateDoc, deleteDoc, collection, onSnapshot } from 'firebase/firestore';
// import { CircularProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// import { useWarehouse } from './useWarehouse';
// import AdjustStockDialog from './AdjustStockDialog';

// const WarehouseDashboard = () => {

//   const [warehouses, setWarehouses] = useState([]);
//   const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [editingWarehouse, setEditingWarehouse] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [adjustStockDialogOpen, setAdjustStockDialogOpen] = useState(false);
//   const [selectedProductId, setSelectedProductId] = useState(null);
//   const { receiveProduct, shipProduct, queryProductLocation } = useWarehouse();

//   useEffect(() => {
//     const unsubscribe = onSnapshot(collection(db, 'warehouses'), (snapshot) => {
//       const warehousesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setWarehouses(warehousesData);
//     });
//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     setLoading(true);
//     const colRef = collection(db, 'warehouse');

//     const unsubscribe = onSnapshot(colRef, (snapshot) => {
//       const productsData = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setProducts(productsData);
//       setLoading(false);
//     }, (err) => {
//       setError(err);
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);
//   // 선택된 창고의 제품만 필터링
//   const filteredProducts = products.filter(product => product.warehouseId === selectedWarehouseId);

//   // 창고 선택 핸들러
//   const handleSelectWarehouse = (warehouseId) => {
//       setSelectedWarehouseId(warehouseId);
//   };
//   // 창고 추가 또는 수정 처리
//   const handleUpdate = async () => {
//     if (editingWarehouse?.id) {
//       // 수정
//       await updateDoc(doc(db, 'warehouses', editingWarehouse.id), editingWarehouse);
//     } else {
//       // 추가
//       await addDoc(collection(db, 'warehouses'), editingWarehouse);
//     }
//     setEditingWarehouse(null);
//     setOpen(false);
//   };

//   // 창고 삭제 처리
//   const handleDelete = async (id) => {
//     await deleteDoc(doc(db, 'warehouses', id));
//   };

//   // Cloud Function 호출
//   const updateProductStock = async (productId, newQuantity) => {
//     const updateStockFunc = functions.httpsCallable('updateProductStock');
//     try {
//       await updateStockFunc({ productId, newQuantity });
//       console.log("Stock updated successfully");
//     } catch (error) {
//       console.error("Error updating stock:", error);
//     }
//   };

//   const handleOpenAdjustStockDialog = (productId) => {
//     setSelectedProductId(productId);
//     setAdjustStockDialogOpen(true);
//   };

//   const handleConfirmAdjustStock = (newQuantity) => {
//     if (selectedProductId) {
//       updateProductStock(selectedProductId, newQuantity);
//     }
//     setAdjustStockDialogOpen(false);
//   };

//   const handleReceiveProduct = () => {
//     // 예제: receiveProduct('prod123', 'New Product', 50, 'A2-B1');
//     // 실제 앱에서는 사용자 입력을 받아 처리하도록 구현
//   };

//   const handleShipProduct = (productId) => {
//     // 예제: shipProduct(productId, 1);
//     // 실제 앱에서는 사용자 입력을 받아 처리하도록 구현
//   };

//   if (loading) return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><CircularProgress /></div>;
//   if (error) return <div>Error loading data: {error.message}</div>;
//   return (
//     <div>
//         <Button variant="outlined" onClick={() => { setEditingWarehouse({ name: '', location: '', capacity: '' }); setOpen(true); }}>Add Warehouse</Button>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell>Location</TableCell>
//               <TableCell>Capacity</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {warehouses.map((warehouse) => (
//               <TableRow key={warehouse.id}>
//                 <TableCell>{warehouse.name}</TableCell>
//                 <TableCell>{warehouse.location}</TableCell>
//                 <TableCell>{warehouse.capacity}</TableCell>
//                 <TableCell>
//                     <Button key={warehouse.id} onClick={() => handleSelectWarehouse(warehouse.id)}>
//                         See
//                     </Button>
//                   <Button onClick={() => { setEditingWarehouse(warehouse); setOpen(true); }}>Edit</Button>
//                   <Button onClick={() => handleDelete(warehouse.id)}>Delete</Button>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       {/* 선택된 창고의 제품 목록 표시 */}
//       <TableContainer component={Paper}>
//             {/* Table Head 생략 */}
//             <TableBody>
//                 {filteredProducts.map((product) => (
//                     <TableRow key={product.id}>
//                         {/* 제품 정보 및 조작 버튼 */}
//                     </TableRow>
//                 ))}
//             </TableBody>
//         </TableContainer>

//       <Dialog open={open} onClose={() => setOpen(false)}>
//         <DialogTitle>{editingWarehouse?.id ? 'Edit' : 'Add'} Warehouse</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Name"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={editingWarehouse?.name}
//             onChange={(e) => setEditingWarehouse({ ...editingWarehouse, name: e.target.value })}
//           />
//           <TextField
//             margin="dense"
//             label="Location"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={editingWarehouse?.location}
//             onChange={(e) => setEditingWarehouse({ ...editingWarehouse, location: e.target.value })}
//           />
//           <TextField
//             margin="dense"
//             label="Capacity"
//             type="number"
//             fullWidth
//             variant="outlined"
//             value={editingWarehouse?.capacity}
//             onChange={(e) => setEditingWarehouse({ ...editingWarehouse, capacity: e.target.value })}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)}>Cancel</Button>
//           <Button onClick={handleUpdate}>Update</Button>
//         </DialogActions>
//       </Dialog>

//         <TableContainer component={Paper}>
//         <Table aria-label="simple table">
//             <TableHead>
//             <TableRow>
//                 <TableCell>Product Name</TableCell>
//                 <TableCell align="right">Quantity</TableCell>
//                 <TableCell align="right">Location</TableCell>
//                 <TableCell align="right">Actions</TableCell>
//             </TableRow>
//             </TableHead>
//             <TableBody>
//             {products.map((product) => (
//                 <TableRow key={product.id}>
//                 <TableCell component="th" scope="row">{product.name}</TableCell>
//                 <TableCell align="right">{product.quantity}</TableCell>
//                 <TableCell align="right">{product.location}</TableCell>
//                 <TableCell align="right">
//                     <Button onClick={() => handleShipProduct(product.id)} color="primary">Ship</Button>
//                     {/* 추가적인 액션 버튼 구현 가능 */}
//                 </TableCell>
//                 </TableRow>
//             ))}
//             </TableBody>
//         </Table>
//         </TableContainer>
//         <AdjustStockDialog
//         open={adjustStockDialogOpen}
//         onClose={() => setAdjustStockDialogOpen(false)}
//         onConfirm={handleConfirmAdjustStock}
//     />
//   </div>
//   );
// };

// export default WarehouseDashboard;

import React, { useState, Suspense, lazy } from 'react';
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  IconButton,
} from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
// Lazily loaded components
const WarehouseList = lazy(() => import('./WarehouseList'));
const AdjustStockDialog = lazy(() => import('./AdjustStockDialog'));
const Receiving = lazy(() => import('./Receiving'));
const Inspection = lazy(() => import('./Inspection'));
const Storage = lazy(() => import('./ProductLocationManager'));
const Sales = lazy(() => import('./Sales'));
const Ordering = lazy(() => import('./Ordering'));
const Return = lazy(() => import('./Return'));
const Exchange = lazy(() => import('./Exchange'));
const Cancel = lazy(() => import('./Cancel'));
const ShippingOrder = lazy(() => import('./ShippingOrder'));
const Shipping = lazy(() => import('./Shipping'));
const InventoryDeduction = lazy(() => import('./InventoryDeduction'));
const Statistics = lazy(() => import('./Statistics'));
const CostAndProfitManagement = lazy(() => import('./CostAndProfitManagement'));
const WarehouseLayouts = lazy(() => import('./WarehouseLayout3D'));
const PromotionProductList = lazy(() => import('./PromotionProductList'));

const WarehouseDashboard = () => {
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const navigate = useNavigate();

  const handleWarehouseSelect = (customerId) => {
    setSelectedWarehouseId(customerId);
    setTabValue('1'); // 기본적으로 고객 상세 정보 탭을 표시
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleBack = () => {
    setSelectedWarehouseId(null); // Navigate back to the warehouse list
  };

  const tabComponents = {
    1: WarehouseLayouts,
    2: Receiving,
    3: Inspection,
    4: Storage,
    5: Sales,
    6: Return,
    7: Exchange,
    8: Cancel,
    9: Ordering,
    10: ShippingOrder,
    11: Shipping,
    12: InventoryDeduction,
    13: Statistics,
    14: CostAndProfitManagement,
    15: PromotionProductList,
  };

  const renderTabComponent = (tabValue) => {
    const TabComponent = tabComponents[tabValue];
    return <TabComponent warehouseId={selectedWarehouseId} />;
  };
  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      {!selectedWarehouseId ? (
        <Suspense fallback={<CircularProgress />}>
          <WarehouseList onSelectWarehouse={handleWarehouseSelect} />
        </Suspense>
      ) : (
        <>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              <IconButton onClick={handleBack} sx={{ marginRight: 2 }}>
                <ArrowBack />
              </IconButton>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="customer tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                {Object.keys(tabComponents).map((key) => (
                  <Tab key={key} label={getTabLabelByKey(key)} value={key} />
                ))}
              </Tabs>
            </Box>
            <Suspense fallback={<CircularProgress />}>
              {renderTabComponent(tabValue)}
            </Suspense>
          </TabContext>
        </>
      )}

      <Suspense fallback={<div>Loading Dialog...</div>}>
        <AdjustStockDialog
          open={false} // You'll likely manage this state to control dialog visibility
          onClose={() => {}} // Provide actual handlers
          onConfirm={() => {}} // Provide actual handlers
        />
      </Suspense>
    </Paper>
  );
};
const getTabLabelByKey = (key) => {
  // 각 키에 따른 탭 라벨을 반환하는 함수, 예: '1' => 'Details'
  const labels = {
    1: '레이아웃',
    2: '입고',
    3: '검수',
    4: '창고저장',
    5: '판매처리',
    6: '반품',
    7: '교환',
    8: '구매취소',
    9: '발주',
    10: '출고지시',
    11: '출고처리',
    12: '재고차감',
    13: '통계',
    14: '원가 및 수익률 관리',
    15: '프로모션 관리',
  };
  return labels[key] || 'Unknown';
};
export default WarehouseDashboard;
