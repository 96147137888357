import React, { useState, useEffect } from 'react';
import DatePicker from './DatePicker'; // Adjust import path as needed
import { fetchHistoricalData } from '../../Utils/dataUtils'; // Adjust import path as needed
import { db } from '../../firebaseConfig'; // Adjust the import path as needed
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useGlobalContext } from '../../useGlobalContext';

const StoreSalesComparison = () => {
  const { tenantId } = useGlobalContext();
  const [selectedStore, setSelectedStore] = useState('');
  const [stores, setStores] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [historicalData, setHistoricalData] = useState([]);

  useEffect(() => {
    const fetchStores = async () => {
      // Fetch stores specific to the tenant
      const storesQuery = query(
        collection(db, 'stores'),
        where('tenantId', '==', tenantId)
      );
      const querySnapshot = await getDocs(storesQuery);
      setStores(
        querySnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
      );
    };

    fetchStores();
  }, [tenantId]); // Rerun when tenantId changes

  const compareData = async () => {
    if (!selectedStore) return;
    const data = await fetchHistoricalData(
      selectedStore,
      startDate,
      endDate,
      tenantId
    ); // Ensure fetchHistoricalData supports tenantId if necessary
    setHistoricalData(data);
  };

  return (
    <div>
      <h2>Store Sales Comparison</h2>
      <select
        value={selectedStore}
        onChange={(e) => setSelectedStore(e.target.value)}
      >
        <option value="">Select a Store</option>
        {stores.map((store) => (
          <option key={store.id} value={store.id}>
            {store.name}
          </option>
        ))}
      </select>
      <DatePicker value={startDate} onChange={setStartDate} />
      <DatePicker value={endDate} onChange={setEndDate} />
      <button onClick={compareData}>Compare</button>
      {/* Display the store sales comparison results */}
    </div>
  );
};

export default StoreSalesComparison;
