// DatePicker.js
import React from 'react';

const DatePicker = ({ value, onChange }) => {
  return (
    <input 
      type="date" 
      value={value.toISOString().split('T')[0]} // Format the date to YYYY-MM-DD
      onChange={e => onChange(new Date(e.target.value))} // Convert back to Date object on change
    />
  );
};

export default DatePicker;
